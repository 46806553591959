import {
  Row,
  Col,
  Card,
  Table,
  Typography,
  Input,
  Button,
  Space,
  Upload,
  Avatar,
  Badge,
  Tooltip,
  List,
  theme,
  DatePicker,
  Select
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { MailOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import BreadcrumbList from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import dayjs from 'dayjs';
import { alertService } from "../../../Shared/Utils/ToasterUtils";
import { ServiceUtils } from "../../../Shared/Utils/ServiceUtils";
import { getDecryptSession } from "../../../Shared/Utils/CommonUtils";

import "./Conference.scss";
export default function Conference({ ...props }) {
  let {conference_id } = useParams();
  // const { addNew } = useParams();
  const navigate = useNavigate();
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [loader, setLoader] = useState(true);
  const [inputs, setInputs] = useState();
  const [fileList, setFileList] = useState();
  const { token } = theme.useToken();

  const contentStyle = {
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    height: "calc(100vh - 230px)",
    overflow: "auto"
  };

  // console.log(useParams());

  useEffect(()=> {
    // console.log(conference_id);
    // console.log(typeof(conference_id));
    if(conference_id ==="addConference"){
      setInputs();
      setLoader(false);
    }
    else{
      getConferenceDetails();
    }
  },[])

    const getConferenceDetails = () => {
      let payload={
        conference_id : conference_id
      }
      ServiceUtils.postRequest('getConference', payload).then(
        (response) => {
            if (response.status === 200 && response?.data.status) {
              // console.log("succdes");
              // console.log(response?.data?.data?.conference_details);
              setInputs(response?.data?.data?.conference_details);
              setFileList(response?.data?.data?.conference_details?.conference_image)
              setLoader(false);
            }
            else if(response.status === 401 ){
              navigate('/logout');
            }
            
        },
        (error) => {
            alertService.error('Error while fetching Objective.')
        }
      );
    }

  const handleSelectOnChange = (val, label, key) => {
    if (key === "attendee_list") {
      for (let i = 0; i < label.length; i++) {
        // console.log(label[i]);
        label[i]["address_data"] = "";
      }
    }
    // console.log(label);
    // console.log("val-",val,"label-", label, "key-",key);
    const name = key;
    const value = val;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleCalender = (event, val, key) => {
    const name = key;
    const value = val;
    setInputs((values) => ({ ...values, [name]: value }))
 }
 const multiAttchOnChange = (event) => {
    // console.log(event);
    // console.log(event);
    const name = "conference_image";
    const value = event?.fileList;
    setInputs((values) => ({ ...values, [name]: value }));
}

const beforeUpload = (fil, fileList) => {
    // Access file content here and do something with it
    let imageInputJson = [];
    if (fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        let file = fileList[i];
        for (const fileName of Object.values(fileList)) {
          const reader = new FileReader();
          let fileType = fileList[i].type;
          reader.onload = (frEvent) => {
            const imgContent = frEvent.target['result'];
            if (imgContent.length > 0 ) {
              let imgData = {};
              imgData['file_name'] = fileList[i].name;
              imgData['data'] = imgContent.split(',')[1];
              imageInputJson.push(imgData); 
              if(imageInputJson[0].data){
                fileList[i]["data"]=imageInputJson[0]?.data;
              } 
            }
          };
          file= fileName;
          reader.readAsDataURL(file);
        }
      }
    }
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 500);
  };

  const SaveData = () => {
    // console.log(inputs);
    if(validateInputs()){
      handleSubmit();
    }
  }

  const validateInputs = () => {
    let flag = false;
    if (inputs) {
      let count = 0;
      for (const [key, value] of Object.entries(inputs)) { 
        if (typeof value === "object" && value?.length && key === "conference_image" && value[0]?.data) {
          console.log(value[0]?.data);
          count = count + 1;
        } 
        else if ((typeof value === "string" || typeof value === "number") && value) {
          count = count + 1;
        }
        // console.log(`Key: ${key}, Value: ${value}`);
      }
      // console.log(count);
      if (count === 5) {
        flag = true;
      } 
      else {
        alertService.error("Please fill all required fields");
      }
    } else {
      alertService.error("Please fill all required fields");
    }
    return flag;
  };

  const handleSubmit = () => {
    const session = localStorage.getItem("arcutis-user");
    const userDetails = getDecryptSession(session);
    console.log(userDetails);
    let payload = {
      "type": conference_id === "addConference" ? "add" : "edit",
      "conference_id": conference_id === "addConference" ? null : conference_id,
      "conference_details": inputs,
      "user_id": userDetails?.user_id ? userDetails?.user_id : null
    }
    console.log(payload)
    ServiceUtils.postRequest('addConference', payload).then(
        (response) => {
            if (response.status === 200 && response?.data.status) {
              // console.log("succdes");
              localStorage.setItem('history', '/home');
              navigate("/home")
            }
            else if(response.status === 401 ){
              navigate('/logout');
            }
        },
        (error) => {
            alertService.error('Error while fetching Objective.')
        }
    );
};

const navigateHome = () => {
    navigate("/home");
    localStorage.setItem('history', '/home')
}

  return (
    <>
      <div className="conference-root" data-test="profile-container">
        <Card
          style={{ width: "100%", height: "fit-content" }}
          bodyStyle={{ padding: "10px" }}
        >
          {conference_id === "addConference" ? (
            <BreadcrumbList active={[{ label: "Add Conference", link: "" }]} />
          ) : (
            <BreadcrumbList
              active={[{ label: "Edit Conference Details", link: "" }]}
            />
          )}
        </Card>
        {!loader &&
        <div className="list-container">
          <Card className="engagement-card">
            {!isEditEnabled ? (
              <>
                <div style={contentStyle} >
                  <div style={{ padding: "5px" }}>
                    <div className="first-step">
                      <Row style={{ padding: "5px" }}>
                        <Col
                          className="form-group "
                          xs={{ flex: "100%" }}
                          sm={{ flex: "100%" }}
                          md={{ flex: "50%" }}
                          lg={{ flex: "25%" }}
                          xl={{ flex: "25%" }}
                        >
                          <div style={{ display: "grid" }}>
                            <div style={{ display: "flex" }}>
                              <label class="form-label text-ellipsis-one-line">
                                Conference Name
                              </label>
                              <span className="text-mandatory">*</span>
                            </div>
                            {isEditEnabled ? (
                              <label class="text-only text-ellipsis-one-line">
                                Some Value
                              </label>
                            ) : (
                              <Input
                                value={inputs?.conference_name}
                                name="conference_name"
                                style={{ width: "90%" }}
                                onChange={handleChange}
                                placeholder="Enter Conference Name"
                              />
                            )}
                          </div>
                        </Col>
                        <Col
                          className="form-group "
                          xs={{ flex: "100%" }}
                          sm={{ flex: "100%" }}
                          md={{ flex: "50%" }}
                          lg={{ flex: "25%" }}
                          xl={{ flex: "25%" }}
                        >
                          <div style={{ display: "grid" }}>
                            <div style={{ display: "flex" }}>
                              <label class="form-label text-ellipsis-one-line">
                                Conference Description
                              </label>
                              <span className="text-mandatory">*</span>
                            </div>
                            {isEditEnabled ? (
                              <label class="text-only text-ellipsis-one-line">
                                Some Value
                              </label>
                            ) : (
                              <Input
                                value={inputs?.conference_description}
                                name="conference_description"
                                style={{ width: "90%" }}
                                onChange={handleChange}
                                placeholder="Enter Conference Description"
                              />
                            )}
                          </div>
                        </Col>
                        {/* <Col
                          className="form-group "
                          xs={{ flex: "100%" }}
                          sm={{ flex: "100%" }}
                          md={{ flex: "50%" }}
                          lg={{ flex: "25%" }}
                          xl={{ flex: "25%" }}
                        >
                          <div style={{ display: "grid" }}>
                            <div style={{ display: "flex" }}>
                              <label class="form-label text-ellipsis-one-line">
                                Meeting Venue
                              </label>
                              <span className="text-mandatory">*</span>
                            </div>
                            {isEditEnabled ? (
                              <label class="text-only text-ellipsis-one-line">
                                Some Value
                              </label>
                            ) : (
                              <Input
                                value={inputs?.meeting_venue}
                                name="meeting_venue"
                                style={{ width: "90%" }}
                                onChange={handleChange}
                                placeholder="Enter Meeting Venue"
                              />
                            )}
                          </div>
                        </Col>
                        <Col
                          className="form-group "
                          xs={{ flex: "100%" }}
                          sm={{ flex: "100%" }}
                          md={{ flex: "50%" }}
                          lg={{ flex: "25%" }}
                          xl={{ flex: "25%" }}
                        >
                          <div style={{ display: "grid" }}>
                            <div style={{ display: "flex" }}>
                              <label class="form-label text-ellipsis-one-line">
                                Meeting Host
                              </label>
                              <span className="text-mandatory">*</span>
                            </div>
                            {isEditEnabled ? (
                              <label class="text-only text-ellipsis-one-line">
                                Some Value
                              </label>
                            ) : (
                              <Input
                                value={inputs?.meeting_host}
                                name="meeting_host"
                                style={{ width: "90%" }}
                                onChange={handleChange}
                                placeholder="Enter Meeting Host"
                              />
                            )}
                          </div>
                        </Col> */}
                        <Col
                          className="form-group "
                          xs={{ flex: "100%" }}
                          sm={{ flex: "100%" }}
                          md={{ flex: "50%" }}
                          lg={{ flex: "25%" }}
                          xl={{ flex: "25%" }}
                        >
                          <div style={{ display: "grid" }}>
                            <div style={{ display: "flex" }}>
                              <label class="form-label text-ellipsis-one-line">
                                Start Date
                              </label>
                              <span className="text-mandatory">*</span>
                            </div>

                            {isEditEnabled ? (
                              <label class="text-only text-ellipsis-one-line">
                                Some Value
                              </label>
                            ) : (
                              <DatePicker
                                defaultValue={
                                  inputs?.start_date &&
                                  dayjs(inputs?.start_date)
                                }
                                value={
                                  inputs?.start_date &&
                                  dayjs(inputs?.start_date)
                                }
                                // defaultValue={inputs?.start_date}
                                name="start_date"
                                style={{ width: "90%" }}
                                showTime
                                format="YYYY-MM-DD HH:mm"
                                placeholder="Select Start Date & Time"
                                onChange={(date, dateString) => {
                                  handleCalender(
                                    date,
                                    dateString,
                                    "start_date"
                                  );
                                }}
                                // onOk={onOk}
                              />
                            )}
                          </div>
                        </Col>
                        <Col
                          className="form-group "
                          xs={{ flex: "100%" }}
                          sm={{ flex: "100%" }}
                          md={{ flex: "50%" }}
                          lg={{ flex: "25%" }}
                          xl={{ flex: "25%" }}
                        >
                          <div style={{ display: "grid" }}>
                            <div style={{ display: "flex" }}>
                              <label class="form-label text-ellipsis-one-line">
                                End Date
                              </label>
                              <span className="text-mandatory">*</span>
                            </div>
                            {isEditEnabled ? (
                              <label class="text-only text-ellipsis-one-line">
                                Some Value
                              </label>
                            ) : (
                              <DatePicker
                                defaultValue={
                                  inputs?.end_date && dayjs(inputs?.end_date)
                                }
                                value={
                                  inputs?.end_date && dayjs(inputs?.end_date)
                                }
                                name="end_date"
                                style={{ width: "90%" }}
                                showTime
                                format="YYYY-MM-DD HH:mm"
                                placeholder="Select End Date & Time"
                                // onChange={onChange}
                                onChange={(date, dateString) => {
                                  handleCalender(date, dateString, "end_date");
                                }}
                                // onOk={onOk}
                              />
                            )}
                          </div>
                        </Col>
                        <Col
                          className="form-group "
                          xs={{ flex: "100%" }}
                          sm={{ flex: "100%" }}
                          md={{ flex: "50%" }}
                          lg={{ flex: "25%" }}
                          xl={{ flex: "25%" }}
                        >
                          <div style={{ display: "grid",width: "90%" }}>
                            <div style={{ display: "flex" }}>
                              <label class="form-label text-ellipsis-one-line">
                                Picture
                              </label>
                              <span className="text-mandatory">*</span>
                            </div>
                            {isEditEnabled ? (
                              <label class="text-only text-ellipsis-one-line">
                                Some Value
                              </label>
                            ) : (
                              <Upload
                                style={{ marginTop: "5px",width: "90%" }}
                                name="conference_image"
                                className="upload-man"
                                onChange={(value)=>{multiAttchOnChange(value)}}
                                // defaultValue={inputs?.conference_image}
                                // value={inputs?.picture}
                                beforeUpload={beforeUpload}
                                customRequest={dummyRequest}
                                accept="image/png"
                                maxCount={1}
                                multiple={false}
                                defaultFileList={inputs?.conference_image}
                              //   defaultFileList={ conference_id !== "addConference" && [
                              //     {
                              //       "uid": 1,
                              //       "name": "Conference Image " + conference_id,
                              //       // "type": "png",
                              //       // "data": "i,l",
                              //       // "status": "done"
                              //     }
                              //   ]
                              // }
                              
                                // onOk={onOk}
                              >
                                <Button icon={<UploadOutlined />}>Click to uploads</Button>
                              </Upload>
                            )}
                          </div>
                        </Col>
                        {/* <Col
                          className="form-group "
                          xs={{ flex: "100%" }}
                          sm={{ flex: "100%" }}
                          md={{ flex: "50%" }}
                          lg={{ flex: "25%" }}
                          xl={{ flex: "25%" }}
                        >
                          <div style={{ display: "grid" }}>
                            <div style={{ display: "flex" }}>
                              <label class="form-label text-ellipsis-one-line">
                                Conference
                              </label>
                              <span className="text-mandatory">*</span>
                            </div>
                            {!isEditEnabled ? (
                              <label class="text-only text-ellipsis-one-line">
                                Some Value
                              </label>
                            ) : (
                              <Select
                                name="conference_id"
                                defaultValue={inputs?.conference_id}
                                value={inputs?.conference_id}
                                // mode="multiple"
                                allowClear
                                style={{
                                  width: "90%",
                                }}
                                showSearch
                                placeholder="Please select"
                                onChange={(value, label) => {
                                  handleSelectOnChange(
                                    value,
                                    label,
                                    "conference_id"
                                  );
                                }}
                                options={addInputFieldData?.conference}
                                // filterOption={filter}
                                maxTagCount="responsive"
                              />
                            )}
                          </div>
                        </Col> */}
                        
                      </Row>
                      {/* <div className="submit-block" style={{ padding: '15px' }}>
                                            <div style={{ paddingRight: '10px' }}>
                                                <Button style={{ background: '#E0B41C' }} type="primary" onClick={onSave}>Save</Button>
                                            </div>
                                            <Button style={{ color: '#E0B41C' }} onClick={cancelAddEdit} >Cancel</Button>
                                        </div> */}
                    </div>
                    {/* <div className="first-step">
                      <Table
                        rowClassName={() => "editable-row"}
                        bordered
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                      />
                    </div> */}
                  </div>
                </div>
                <div style={{ marginTop: 24 }}>
                  <div className="submit-block">
                    <div style={{ paddingRight: "10px" }}>
                      <Button
                        className="edit-meet-button"
                        type="primary"
                        onClick={() =>
                          SaveData()
                          // console.log("test")
                        }
                      >
                        Save
                      </Button>
                    </div>
                    <Button
                      onClick={()=>{
                        navigateHome()
                        // console.log("test")
                      }
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </>
            ) : 
            (
              <>{!isEditEnabled &&!loader && 
                <>
                </>
              }
            </>
            )}
          </Card>
        </div>
        }
      </div>
    </>
  );
}
