import React, { useRef, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Select,
  Row,
  Col,
  Typography,
  Tabs,
  Table,
  Tag,
  Space,
  Tooltip,
  Input,
  Dropdown,
  Layout,
  Menu,
  Divider,
  Card,
  Collapse,
  Checkbox,
  DatePicker,
  // Icon
} from "antd";
import AntBuilder from "../../../Shared/Components/Ant-DynamicFormBuilder/Ant-DynamicFormBuilder";
import { useSharedState } from "../../../Shared/Services/WindowSizeService";
import { alertService } from "../../../Shared/Utils/ToasterUtils";
import { ServiceUtils } from "../../../Shared/Utils/ServiceUtils";
import BreadcrumbList from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import { ExportOutlined, SearchOutlined,LaptopOutlined, NotificationOutlined, UserOutlined,ExclamationCircleFilled,CloseCircleOutlined, CaretRightOutlined, FilterOutlined, CloseOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import "./Survey.scss";
import dayjs from "dayjs";
import { map, includes, sortBy, uniqBy, each, result, get } from "lodash";
import AppIcon from '../../../Assets/icons/app-icon.svg'

import { filter } from "rxjs";

import Highlighter from 'react-highlight-words';
const Search = Input.Search;
const { confirm } = Modal;

const { Header, Content, Footer, Sider } = Layout;
const { RangePicker } = DatePicker;
export default function Survey(props) {
  const windowSize$ = useSharedState();
  const navigate = useNavigate();
  const { Title, Text } = Typography;
  const userDetails = props?.userDetails;
  const [currentTab,setCurrentTab] = useState("activeSurveys");
  const [surveyFlag, setSurveyFlag] = useState(false);
  const [formConfig, setFormConfig] = useState();
  const [data, setData] = useState();
  const [currentSurvey, setCurrentSurvey] = useState();
  const [currentSurveyResponseId, setCurrentSurveyResponseId] = useState();

  const [searchFilterTerm, setSearchFilterTerm] = useState();

  const [filterOptionsActiveData,setFilterOptionsActiveData]= useState({});
  const [filterSubmittedActiveData, setFilterSubmittedActiveData] = useState({});
  const [filterSelectedData, setFilterSelectedData] = useState({});
  const [filterSubSelectedData, setFilterSubSelectedData] = useState({});
  const [filterActivedefaultData, setFilterActiveDefaultData] = useState({});
  const [filterSubmitteddefaultData, setFilterSubmittedDefaultData] = useState({});
  // const [filterActiveDefaultOptions, setFilterActiveDefaultOptions] = useState();
  // const [filterSubmittedDefaultOptions, setFilterSubmittedDefaultOptions] = useState();
  const [showFilterFlag, setShowFilterFlag] = useState(true);
  // const [isallFieldsAddressed, setAllfeildsAddressed] = useState(false);
  // const [atleastOneChange, setAtleastOneChange] = useState(false);
  const [submittedBy, setSubmittedBy] = useState();
  const [submittedOn, setSubmittedOn] = useState();
  const [surveyOptions, setSurveyOptions] = useState([
    {
      value: "medical",
      label: "Medical",
    },
    {
      value: "meeting",
      label: "Meeting",
    },
    {
      value: "followUp",
      label: "Follow up",
    },
  ]);
  const [selectedSurvey, setSelectedSurvey] = useState();

  const [activeSurveyList, setActiveSurveyList] = useState({
    tableData: {
      headerContent: [],
      bodyContent: [],
    },
  });
  const [filterActiveState,setfilterActiveState] = useState(
    {
      filteredInfo: null,
      sortedInfo: null,
      data : activeSurveyList?.tableData?.bodyContent,
      filtered: false,
      searchTableText: ""
    }
  )

  const [filterSubmittedState,setfilterSubmittedState] = useState(
    {
      filteredInfo: null,
      sortedInfo: null,
      data : activeSurveyList?.tableData?.bodyContent,
      filtered: false,
      searchTableText: ""
    }
  )

  const [submittedResponsesList, setSubmittedResponsesList] = useState({
    tableData: {
      headerContent: [],
      bodyContent: [],
    },
  });

  const [defaultFormData, setDefaultFormData] = useState();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [surveyName,setSurveyName] = useState('');
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (setSelectedKeys, selectedKeys, confirm, clearFilters, close) => {(
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${"surveyName"}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, "surveyName")}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, "surveyName")}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn("surveyName");
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
  )};

  
  // const onChangeFilters = (data, key) => {
  //   let reqObject = JSON.parse(JSON.stringify(filterSelectedData))
  //   reqObject={...reqObject, [key]: data}
  //   setFilterSelectedData(reqObject);
  //   console.log(reqObject);
  // }

  const getActiveFilterStructure = (data,reqObj) => {
    // let datattt=[
    //   {
    //     "key":"showAll",
    //     "label":"Response",
    //     "type":"checkBox",
    //     "options":
    //       [
    //         {
    //             "value": "Show all responses",
    //             "label": "1.0"
    //         }
    //     ],
    //   },
    //   {
    //     "key":"SurveyType",
    //     "label":"Survey Type",
    //     "type":"checkBox",
    //     "options":
    //       [
    //         {
    //             "value": "medicalQA",
    //             "label": "Medical QA"
    //         },
    //         {
    //             "value": "diagnoticReview",
    //             "label": "Diagnotic Review"
    //         },
    //         {
    //             "value": "referralCoordination",
    //             "label": "Referral Coordination"
    //         },
    //         {
    //             "value": "follow-upFeedback",
    //             "label": "Follow-up Feedback"
    //         },
    //         {
    //             "value": "initialAssesment",
    //             "label": "Initial Assesment"
    //         }
    //     ],
    //   },
    //   {
    //     "key":"version",
    //     "label":"Version",
    //     "type":"checkBox",
    //     "options":
    //       [
    //         {
    //             "value": "v1.0",
    //             "label": "1.0"
    //         },
    //         {
    //             "value": "v2.0",
    //             "label": "2.0"
    //         },
    //         {
    //             "value": "v3.0",
    //             "label": "3.0"
    //         }
    //     ],
    //   },
    //   {
    //     "key":"publishedDateRange",
    //     "label":"Published Date Range",
    //     "type":"date-picker",
    //   }
    // ]
    let datattt = data;
    let reqObject = reqObj;
    // let reqObject = 
    //  {
    //   "searchText":"",
    //   "SurveyType":[],
    //   "version":[],
    //   "publishedDateRange":""
    // }
    const onChangeFilters = (data, key) => {
      reqObject={...reqObject, [key]: data}
      // console.log(reqObject);
      setFilterSelectedData(reqObject);
    }

    datattt?.map((item)=>{
      if(item.type==="checkBox") {
        console.log(item);
        const getData = () => {

            return(
              [
                <Checkbox.Group onChange={(e)=>{onChangeFilters(e,item?.key)}}>
                    <Space direction="column">
                      <Row gutter={16}>
                        {item?.options?.map((option) => (
                          <Col span={24}>
                            <Checkbox  value={option?.value} style={{marginBottom:'3px'}}>{option?.label}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                  </Space>
                </Checkbox.Group>
              ]
            )
        }
        item["children"] =getData();
      }
      else if(item.type==="date-picker"){
        const getCAlData = () => {
          return(
            <div style={{paddingBottom:'5px'}}>
              <RangePicker 
                // showTime={{
                //   format: 'HH:mm',
                // }}
                // onChange={(e)=>{console.log(e)}}
                placeholder={["Start", "End"]}
                format="YYYY-MM-DD HH:mm"
                onChange={(value, dateString) => {onChangeFilters(dateString,item?.key)}}
                // onOk={onOk}
              />
            </div>
          )
        }
        item["children"] =getCAlData();
      }
    })
    // console.log(datattt);
    setFilterOptionsActiveData(datattt);
  }

  const getActiveSurveys = () => {
    ServiceUtils.getRequest("listOfSurveys").then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          // console.log(response.data?.data?.filters)

          // let temp = {
          //   bodyContent: [
          //     {
          //       key: 1,
          //       surveyName: "KOL Score Cards for TLL",
          //       surveytype: "Medical",
          //       surveyKey: "medical",
          //       version: "1.0",
          //       description: "Evaluation and Insights from TLL Team",
          //       publishedOn: "20/6/2024 02:12 AM",
          //       responsesSubmitted: ["2 responses"],
          //       actions: "Open Survey",
          //     },
          //     {
          //       key: 2,
          //       surveyName: "KOL Score Cards for TLL",
          //       surveytype: "Medical",
          //       surveyKey: "referral",
          //       version: "1.0",
          //       description: "Evaluation and Insights from TLL Team",
          //       publishedOn: "20/6/2024 02:12 AM",
          //       responsesSubmitted: ["5 responses"],
          //       actions: "Open Survey",
          //     },
          //   ],
          //   headerContent: [
          //     {
          //       title: "SL No",
          //       dataIndex: "key",
          //       width: "6%",
          //     },
          //     {
          //       dataIndex: "surveyName",
          //       key: "surveyName",
          //       title: "Survey Name",
          //     },
          //     {
          //       dataIndex: "surveytype",
          //       key: "surveyType",
          //       title: "Survey Type",
          //     },
          //     {
          //       dataIndex: "version",
          //       key: "vesrion",
          //       title: "Version",
          //     },
          //     {
          //       dataIndex: "description",
          //       key: "description",
          //       title: "Description",
          //     },
          //     {
          //       dataIndex: "publishedOn",
          //       key: "publishedOn",
          //       title: "Published On",
          //     },
          //     {
          //       dataIndex: "responsesSubmitted",
          //       key: "responsesSubmitted",
          //       title: "Responses Submitted",
          //     },
          //     {
          //       dataIndex: "actions",
          //       key: "actions",
          //       title: "Actions",
          //     },
          //   ],
          // };

          let temp = JSON.parse(JSON.stringify(response?.data?.data?.tableData));

          temp?.headerContent?.map((el) => {
            if (el.key === "surveyName") {
              el.render = (_, record) => (
                <Tooltip title={record?.surveyName}>
                <Space size="middle">
                    {record?.surveyName}
                </Space>
                </Tooltip>

              );
            } else if (el.key === "description") {
              el.render = (_, record) => (
                <Tooltip title={record?.description}>
                <Space size="middle">
                  {/* <a 
                            onClick={() => onReportSelect(record)}
                            >{record?.description}</a> */}
                  {record?.description}
                </Space>
                </Tooltip>
              );
            } else if (el.key === "responsesSubmitted") {
              el.render = (_, tags) => (
                <Tag color="#fbe38b" key={tags}>
                  <span style={{ color: "black", fontWeight: "500" }}>
                    {tags.responsesSubmitted}
                  </span>
                </Tag>
              );
            }
            if (el.key === "actions") {
              el.render = (_, record) => (
                <Space size="middle">
                  <a onClick={() => addNewSurvey(record?.surveyName, record?.surveyId, record?.surveyTemplateId)}>
                    <ExportOutlined /> &nbsp; {record?.actions}
                  </a>
                </Space>
              );
            }
          });
          setActiveSurveyList(temp);
          filterActiveState.data = temp?.bodyContent;
          setfilterActiveState(filterActiveState);

          let filterres= JSON.parse(JSON.stringify(response.data?.data?.filters?.filterData))
          let filterPay = JSON.parse(JSON.stringify(response.data?.data?.filters?.filterKeys))
          // setFilterActiveDefaultOptions(filterres);
          setFilterActiveDefaultData(filterPay);
          getActiveFilterStructure(filterres, filterPay)
          if(searchFilterTerm){
            setSearchFilterTerm()
          }
          if(filterSubSelectedData){
            setFilterSubSelectedData({})
          }
          // if(filterSubmittedActiveData){
          //   setFilterSubmittedActiveData()
          // }
          // if(submittedResponsesList){
          //   setSubmittedResponsesList({
          //     tableData: {
          //       headerContent: [],
          //       bodyContent: [],
          //     },
          //   })
          // }

        } else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error("Error while fetching data.");
      }
    );
  };

  const getSubmittedFilterStructure = (data, reqObj) => {
    let datattt = data;
    let reqObject = reqObj;
    // let datattt=[
    //   {
    //     "key":"response",
    //     "label":"Response",
    //     "type":"checkBox",
    //     "options":
    //       [
    //         {
    //             "value": "Show all responses",
    //             "label": "Show all responses"
    //         }
    //     ],
    //   },
    //   {
    //     "key":"SurveyType",
    //     "label":"Survey Type",
    //     "type":"checkBox",
    //     "options":
    //       [
    //         {
    //             "value": "medicalQA",
    //             "label": "Medical QA"
    //         },
    //         {
    //             "value": "diagnoticReview",
    //             "label": "Diagnotic Review"
    //         },
    //         {
    //             "value": "referralCoordination",
    //             "label": "Referral Coordination"
    //         },
    //         {
    //             "value": "follow-upFeedback",
    //             "label": "Follow-up Feedback"
    //         },
    //         {
    //             "value": "initialAssesment",
    //             "label": "Initial Assesment"
    //         }
    //     ],
  
    //   },
    //   {
    //     "key":"conference",
    //     "label":"Conference",
    //     "type":"checkBox",
    //     "options":
    //       [
    //         {
    //             "value": "AAD",
    //             "label": "AAD"
    //         },
    //         {
    //             "value": "SDPA",
    //             "label": "SDPA"
    //         }
    //     ],
    //   },
    //   {
    //     "key":"publishedDateRange",
    //     "label":"Published Date Range",
    //     "type":"date-picker",
    //   }
    // ]
    // let reqObject = 
    //  {
    //   "searchText":"",
    //   "SurveyType":[],
    //   "version":[],
    //   "publishedDateRange":""
    // }
    const onChangeFilters = (data, key) => {
      reqObject={...reqObject, [key]: data}
      // console.log(reqObject);
      setFilterSubSelectedData(reqObject);
    }

    datattt?.map((item)=>{
      if(item.type==="checkBox") {
        console.log(item);
        const getData = () => {

            return(
              [
                <Checkbox.Group onChange={(e)=>{onChangeFilters(e,item?.key)}}>
                    <Space direction="column">
                      <Row gutter={16}>
                        {item?.options?.map((option) => (
                          <Col span={24}>
                            <Checkbox  value={option?.value} style={{marginBottom:'3px'}}>{option?.label}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                  </Space>
                </Checkbox.Group>
              ]
            )
        }
        item["children"] =getData();
      }
      else if(item.type==="date-picker"){
        const getCAlData = () => {
          return(
            <div style={{paddingBottom:'5px'}}>
              <RangePicker 
                // showTime={{
                //   format: 'HH:mm',
                // }}
                // onChange={(e)=>{console.log(e)}}
                format="YYYY-MM-DD HH:mm"
                onChange={(value, dateString) => {onChangeFilters(dateString,item?.key)}}
                // onOk={onOk}
              />
            </div>
          )
        }
        item["children"] =getCAlData();
      }
    })
    // console.log(datattt);
    setFilterSubmittedActiveData(datattt);
  }

  const getSubmittedResponses = () => {
    ServiceUtils.getRequest("submittedResponses").then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          // console.log(response?.data?.data)

          // let temp = {
          //   bodyContent: [
          //     {
          //       key: 1,
          //       surveyName: "Medical Survey QA",
          //       surveyType: "Medical QA",
          //       version: "1.0",
          //       description: "Gathering detailed medical history",
          //       HCPName: "Emma Johnson",
          //       quarter: "Q1 24",
          //       conference: "AAD",
          //       publishedOn: "20/6/2024 02:12 AM",
          //       actions: "Open Survey",
          //     },
          //     {
          //       key: 2,
          //       surveyName: "Post Visit Feedback",
          //       surveyType: "Refferal",
          //       version: "1.0",
          //       description: "Collecting raw data",
          //       HCPName: "Emma Johnson",
          //       quarter: "Q1 24",
          //       conference: "AAD",
          //       publishedOn: "20/6/2024 02:12 AM",
          //       actions: "Open Survey",
          //     },
          //     {
          //       key: 3,
          //       surveyName: "Treatment Effect",
          //       surveyType: "Diagnotic",
          //       version: "1.0",
          //       description: "Evaluating",
          //       HCPName: "Emma Johnson",
          //       quarter: "Q1 24",
          //       conference: "AAD",
          //       publishedOn: "20/6/2024 02:12 AM",
          //       actions: "Open Survey",
          //     },
          //     {
          //       key: 4,
          //       surveyName: "Lab results",
          //       surveyType: "Treatment review",
          //       version: "1.0",
          //       description: "Reviewing",
          //       HCPName: "Emma Johnson",
          //       quarter: "Q1 24",
          //       conference: "AAD",
          //       publishedOn: "20/6/2024 02:12 AM",
          //       actions: "Open Survey",
          //     },
          //     {
          //       key: 5,
          //       surveyName: "Speacialist",
          //       surveyType: "Followup",
          //       version: "1.0",
          //       description: "Assessing and discussing ",
          //       HCPName: "Emma Johnson",
          //       quarter: "Q1 24",
          //       conference: "AAD",
          //       publishedOn: "20/6/2024 02:12 AM",
          //       actions: "Open Survey",
          //     },
          //     {
          //       key: 6,
          //       surveyName: "Health Maintenance",
          //       surveyType: "Initial",
          //       version: "1.0",
          //       description: "discussing at",
          //       HCPName: "Emma Johnson",
          //       quarter: "Q1 24",
          //       conference: "AAD",
          //       publishedOn: "20/6/2024 02:12 AM",
          //       actions: "Open Survey",
          //     }
          //   ],
          //   headerContent: [
          //     {
          //       dataIndex: "surveyName",
          //       key: "surveyName",
          //       title: "Survey Name",
          //       ellipsis: {
          //         showTitle: false,
          //       },
          //       filters: [
          //         {
          //           text: 'Medical',
          //           value: 'Medical',
          //         },
          //         {
          //           text: 'Post',
          //           value: 'Post',
          //         },
          //       ],
          //     },
          //     {
          //       dataIndex: "surveyType",
          //       key: "surveyType",
          //       title: "Survey Type",
          //       ellipsis: {
          //         showTitle: false,
          //       },
          //       filters: [
          //         {
          //           text:'Medical QA',
          //           value: 'Medical QA',
          //         },
          //         {
          //           text:'Refferal',
          //           value: 'Refferal',
          //         },
          //         {
          //           text:'Followup',
          //           value:"Followup"
          //         },
          //         {
          //           text:"Diagnotic",
          //           value:"Diagnotic"
          //         }
          //       ],
          //     },
          //     {
          //       dataIndex: "version",
          //       key: "vesrion",
          //       title: "Version",
          //       ellipsis: {
          //         showTitle: false,
          //       }
          //     },
          //     {
          //       dataIndex: "description",
          //       key: "description",
          //       title: "Description",
          //       ellipsis: {
          //         showTitle: false,
          //       },
          //       filters:[
          //         {
          //           text:"Evaluating",
          //           value:"Evaluating",
          //         },
          //         {
          //           text:"Assessing",
          //           value:"Assessing",
          //         },
          //         {
          //           text:"Reviewing",
          //           value:"Reviewing",
          //         },
          //       ],
          //       filterSearch:true,
          //     },
          //     {
          //       dataIndex: "HCPName",
          //       key: "HCPName",
          //       title: "HCP Name",
          //       ellipsis: {
          //         showTitle: false,
          //       }
          //     },
          //     {
          //       dataIndex: "quarter",
          //       key: "quarter",
          //       title: "Quarter",
          //       ellipsis: {
          //         showTitle: false,
          //       }
          //     },
          //     {
          //       dataIndex: "conference",
          //       key: "conference",
          //       title: "Conference",
          //       ellipsis: {
          //         showTitle: false,
          //       }
          //     },
          //     {
          //       dataIndex: "publishedOn",
          //       key: "publishedOn",
          //       title: "Published On",
          //       ellipsis: {
          //         showTitle: false,
          //       }
          //     },
          //     {
          //       dataIndex: "actions",
          //       key: "actions",
          //       title: "Actions",
          //       ellipsis: {
          //         showTitle: false,
          //       }
          //     },
          //   ],
          // };

          let temp = JSON.parse(JSON.stringify(response?.data?.data?.tableData));

          temp?.headerContent?.map((el) => {
            if (el.key === "surveyName") {
              // el.title=()=>{return (
              //   <div>
              //     <Space>
              //       {el?.key}
              //       <Dropdown
              //         dropdownRender={(menu) => (
              //           <div >
                          
              //             <Space
              //               style={{
              //                 padding: 8,
              //               }}
              //             >
              //               <Button type="primary">Click me!</Button>
              //             </Space>
              //           </div>
              //         )}
              //         trigger={['click']}
              //       >
              //           <Space>
              //             <SearchOutlined />
              //           </Space>
              //       </Dropdown>
              //     </Space>
              //   </div>
              // )};
              el.render = (_, record) => (
                <Space size="middle">
                  <Tooltip title={record?.surveyName}>
                      {record?.surveyName} 
                  </Tooltip>
                </Space>
              );
              // el.onFilter= (value, record) => record.surveyName.indexOf(value) === 0;
              // el=getColumnSearchProps('surveyName');
              // el.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
              //   <div
              //     style={{
              //       padding: 8,
              //     }}
              //     onKeyDown={(e) => e.stopPropagation()}
              //   >
              //     <Input
              //       ref={searchInput}
              //       placeholder={`Search ${"surveyName"}`}
              //       value={selectedKeys[0]}
              //       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              //       onPressEnter={() => handleSearch(selectedKeys, confirm, "surveyName")}
              //       style={{
              //         marginBottom: 8,
              //         display: 'block',
              //       }}
              //     />
              //     <Space>
              //       <Button
              //         type="primary"
              //         onClick={() => handleSearch(selectedKeys, confirm, "surveyName")}
              //         icon={<SearchOutlined />}
              //         size="small"
              //         style={{
              //           width: 90,
              //         }}
              //       >
              //         Search
              //       </Button>
              //       <Button
              //         onClick={() => clearFilters && handleReset(clearFilters)}
              //         size="small"
              //         style={{
              //           width: 90,
              //         }}
              //       >
              //         Reset
              //       </Button>
              //       <Button
              //         type="link"
              //         size="small"
              //         onClick={() => {
              //           confirm({
              //             closeDropdown: false,
              //           });
              //           setSearchText(selectedKeys[0]);
              //           setSearchedColumn("surveyName");
              //         }}
              //       >
              //         Filter
              //       </Button>
              //       <Button
              //         type="link"
              //         size="small"
              //         onClick={() => {
              //           close();
              //         }}
              //       >
              //         close
              //       </Button>
              //     </Space>
              //   </div>
              // )
            } 
            else if(el.key === "HCPName"){
              el.render = (_, record) => (
                <Space size="middle">
                  <Tooltip title={record?.HCPName}>
                      {record?.HCPName}
                  </Tooltip>
                </Space>
              );
              // el.onFilter= (value, record) => record.HCPName.indexOf(value) === 0;
            }
            else if (el.key === "description") {
              el.render = (_, record) => (
                <Space size="middle">
                  <Tooltip title={record?.description}>
                    {record?.description}
                  </Tooltip>
                </Space>
              );
              // el.onFilter= (value, record) => record.description.indexOf(value) === 0;
            } else if (el.key === "responsesSubmitted") {
              el.render = (_, tags) => (
                <Tag color="#fbe38b" key={tags}>
                  <span style={{ color: "black", fontWeight: "500" }}>
                    {tags.responsesSubmitted}
                  </span>
                </Tag>
              );
            }
            //  else {
            //   el.render = (_, record) => (
            //     <Space size="middle">
            //       <Tooltip title={record?.description}>
            //         {record?.description}
            //       </Tooltip>
            //     </Space>
            //   );
            // }
            if (el.key === "actions") {
              el.render = (_, record) => (
                <Space size="middle">
                  <a 
                  onClick={() => openSubmittedActions(record?.surveyName, record?.surveyId, record?.surveyTemplateId, record?.surveyResponseId)}
                    >
                    <ExportOutlined /> &nbsp; Open Survey
                  </a>
                </Space>
              );
            }
          });
          setSubmittedResponsesList(temp);
          // filterSubmittedState.data=temp?.bodyContent;
          // setfilterSubmittedState(setfilterSubmittedState)
          let filterres= JSON.parse(JSON.stringify(response.data?.data?.filters?.filterData))
          let filterPay = JSON.parse(JSON.stringify(response.data?.data?.filters?.filterKeys))
          // setFilterSubmittedDefaultOptions(filterres)
          setFilterSubmittedDefaultData(filterPay)
          getSubmittedFilterStructure(filterres, filterPay)
          if(searchFilterTerm){
            setSearchFilterTerm()
          }
          if(filterSelectedData){
            setFilterSelectedData({})
          }
          // if(filterOptionsActiveData){
          //   setFilterOptionsActiveData()
          // }
          // if(activeSurveyList){
          //   setActiveSurveyList({
          //     tableData: {
          //       headerContent: [],
          //       bodyContent: [],
          //     },
          //   });
          // }
        } else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error("Error while fetching data.");
      }
    );
  };

  const renderActiveSurveysTab = () => {
    return (
      <div
      className={classNames("", {
        "survey-container":
          windowSize$ === "lg" ||
          windowSize$ === "xl" ||
          windowSize$ === "xxl",
        "survey-container-small":
          windowSize$ === "xs" ||
          windowSize$ === "sm" ||
          windowSize$ === "md",
      })}>
        <Table
          columns={activeSurveyList.headerContent}
          dataSource={activeSurveyList.bodyContent }
          bordered
          pagination={false}
          scroll={{
            y: "calc(100vh - 270px)",
          }}
        />
      </div>
    );
  };

  const renderSubmittedResponses = () => {
    return (
      <div
        className={classNames("", {
          "survey-container":
            windowSize$ === "lg" ||
            windowSize$ === "xl" ||
            windowSize$ === "xxl",
          "survey-container-small":
            windowSize$ === "xs" ||
            windowSize$ === "sm" ||
            windowSize$ === "md",
        })}
      >
        <Table
          columns={submittedResponsesList.headerContent}
          dataSource={submittedResponsesList?.bodyContent}
          bordered
          pagination={false}
          scroll={{
            y: "calc(100vh - 270px)",
          }}
        />
      </div>
    );
  };

  const items = [
    {
      key: "activeSurveys",
      label: "Active Surveys",
      children: renderActiveSurveysTab(),
    },
    {
      key: "submittedResponses",
      label: "Submitted Responses",
      children: renderSubmittedResponses(),
    },
  ];

  useEffect(() => {
    getActiveSurveys();
    // getSubmittedResponses();
  }, []);



  const callForm = (survey, surveyID,surveyTemplateId,surveyResponseId) => {
    // if(survey==="medical"){}
    let payload= { 
      "surveyID":surveyID,
      "surveyTemplateId" : surveyTemplateId,
      "surveyResponseId": surveyResponseId ? surveyResponseId : null,
    }
    ServiceUtils.postRequest("surveyTemplate", payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          if(response?.data?.defaultData){
            let Data = JSON.parse(JSON.stringify(response?.data?.defaultData))
            setDefaultFormData(Data)
            setData(Data);
          } 
          if(response?.data?.submittedBy){
            setSubmittedBy(response?.data?.submittedBy)
          }
          else{
            setSubmittedBy()
          }
          if(response?.data?.submittedOn){
            setSubmittedOn(response?.data?.submittedOn)
          }
          if(response?.data?.surveyName){
            setSurveyName(response?.data?.surveyName)
          }
          else{
            setSubmittedOn()
          }
          setFormConfig(JSON.parse(JSON.stringify(response?.data?.data)));
          // let Data= JSON.parse(JSON.stringify({
          //   "quarter":  "2024-11-17T08:56:41.997Z",
          //   "kolId": 408,
          //   "conferenceId": 2,
          //   "meetingId": 1455,
          //   "ConferenceType": "ConferenceType1",
          //   "presentation": "PresentationType1",
          //   "presentationCheckBox": [
          //       "MSLCovering"
          //   ],
          //   "conferenceType": "ConferenceType1",
          //   "other": "test",
          //   "comments": "test com",
          //   "QuarterDate": "2024-07-17T08:56:41.997Z"  
          // }))
          // setDefaultFormData(Data)
          // setData(Data);
          setCurrentSurvey(surveyID);
          if(surveyResponseId){
            setCurrentSurveyResponseId(surveyResponseId)
          }
          setSurveyFlag(true);
          setSelectedSurvey(survey);
          // setDefaultFormData(Data);
        } else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error("Error while fetching data.");
      }
    );
    
  };

  const onCancel = () => {};

  const onSubmit = () => {
    setSurveyFlag(false);
  };

  const dynamicService = (key,fieldData,service,dependenceID) => {
    // console.log(service);
    // console.log(key, dependenceID)
    let payload={
      surveyId: currentSurvey,
      key:key,
      id:Number(dependenceID?.kolId) ? dependenceID : null
    }
    ServiceUtils.postRequest(service,payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          // console.log(response.data)
          let foundDependency = false;
          let foundDependencyKey = "";
          // console.log(fieldData[0], key);
          if(fieldData[0]?.dependencyData?.dependenceKey===key){
            const modifiedEmployees = formConfig[0]?.map(obj => {
              let options = JSON.parse(JSON.stringify(response?.data?.data));
              if(fieldData?.length && fieldData[0]?.dependencyData?.dependenceKey && fieldData[0]?.dependencyData?.dependenceKey===obj?.key){
                // console.log(fieldData[0]?.dependencyData?.dependenceKey,key)
                if (fieldData[0]?.dependencyData?.dependenceKey===obj?.key) {
                    foundDependency= true;
                    foundDependencyKey=fieldData[0].dependencyData?.dependenceKey;
                    // testing
                    
                    return { ...obj, "options": options };
                }
                else{
                  return { ...obj };
                }
              }
              else{
                return { ...obj };
              }
            }
            );
            if(foundDependency && foundDependencyKey){
              setFormConfig([modifiedEmployees])
            }
          }
          else if( fieldData[0]?.onFocusGetData && fieldData[0]?.key===key){
            const modifiedEmployees = formConfig[0]?.map(obj => {
              let options1=JSON.parse(JSON.stringify(response?.data?.data))
              if(fieldData[0]?.onFocusGetData && key && fieldData[0]?.key===obj?.key){
                // console.log(fieldData[0],key)
                if (fieldData[0]?.key===key) {
                  foundDependency= true;
                  foundDependencyKey=fieldData[0].onFocusGetData;
                  // testing
                  
                  return { ...obj, "options": options1 };
                }
                else{
                  return { ...obj };
                }
              }
              else{
                return { ...obj };
              }
            }
            );
            if(foundDependency && foundDependencyKey){
              setFormConfig([modifiedEmployees])
            }
          }

          
        } else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error("Error while fetching data.");
      }
    );
  }


  const handle = (action) => {
    // let tempData = JSON.parse(JSON.stringify(action?.payload?.allData))
    if(action?.payload?.allData){
      setData(action?.payload?.allData);
    }
    let foundDependency = false;
    let foundDependencyKey = "";
    let foundDependencyService="";
    let foundDependencyPayloadID="";
    const modifiedEmployees = formConfig[0]?.map(obj => {
      if(action?.type === "dragAndDrop"){
        let tempData = {...data};
        // console.log(tempData,action?.payload?.draggedItemData?.options,action?.payload?.draggedItemData?.key)
        let options = insertAt(action?.payload?.draggedItemData?.options,action?.payload?.draggedItemData?.element,action?.payload?.index)
        tempData[action?.payload?.draggedItemData?.key] = options;
        let tempConfig = JSON.parse(JSON.stringify(formConfig))
        let updatedConfig = updateFormConfig(action?.payload?.draggedItemData?.key,'options',options,tempConfig)
        setData({...tempData});
        setFormConfig(JSON.parse(JSON.stringify(updatedConfig)))
      }
      // type:"newOptionInputChange",field,value:e?.target?.value
      // if(action?.type === 'newOptionInputChange'){
      //   let tempData = {...data};
      //   let tempConfig = JSON.parse(JSON.stringify(formConfig))
      //   let updatedConfig = updateFormConfig(action?.field?.key,'newOptionName',action?.value,tempConfig)
      //   console.log(action,'action',tempData,updatedConfig)
      //   // setData({...tempData});
      //   setFormConfig(JSON.parse(JSON.stringify(updatedConfig)))
      // }
      else if(action?.type === 'addNewOption'){
        console.log(action,'action')
        let tempData = {...data};
        let tempConfig = JSON.parse(JSON.stringify(formConfig))
        let options=[...action?.field?.options]
        options.push({label:action?.value,value:action?.value})
        let updatedConfig = updateFormConfig(action?.field?.key,'options',options,tempConfig)
        console.log(action,'action',tempData,updatedConfig,defaultFormData)
        tempData[action.field.key] = action?.value
        setData({...tempData});
        setFormConfig(JSON.parse(JSON.stringify(updatedConfig)))
      }
      console.log(action,'action')
       if(action?.payload?.currentFieldData?.[0]?.dependencyData?.dependenceKey && action?.payload?.currentFieldData?.[0]?.key){
        if (action?.payload?.currentFieldData?.[0]?.dependencyData?.dependenceKey === obj?.dependencyData?.dependenceKey) {
            foundDependency= true;
            // foundDependencyKey=action?.payload?.data?.[action?.payload?.currentFieldData[0]?.key];
            foundDependencyKey=action?.payload?.currentFieldData?.[0]?.dependencyData?.dependenceKey;
            foundDependencyService=action?.payload?.currentFieldData?.[0]?.dependencyData?.ondependenceService;
            // foundDependencyPayloadID=action?.payload?.data?.[action?.payload?.currentFieldData[0]?.key];
            // console.log(action?.payload?.currentFieldData?.[0]?.dependencyData?.multipleDependencyKeys)
            let arr=action?.payload?.currentFieldData?.[0]?.dependencyData?.multipleDependencyKeys;
            const obj = {};
            arr.forEach((value, index) => obj[value] = action?.payload?.data[value]);
            // console.log(obj);
            foundDependencyPayloadID=JSON.parse(JSON.stringify(obj));
            // return { ...obj, "options": options };
        }
      }
    }
    
    );
    
    console.log(foundDependency,foundDependencyKey,foundDependencyService,foundDependencyPayloadID)
    if(foundDependency && foundDependencyKey && foundDependencyService && foundDependencyPayloadID){
      dynamicService(foundDependencyKey,action?.payload?.currentFieldData,foundDependencyService,foundDependencyPayloadID)
    }
    // setFormConfig(prevFormConfig =>({
    //   ...prevFormConfig,
    //   modifiedEmployees
    // }))
  // console.log(modifiedEmployees);
  
    // setFormConfig(prevFormData => ({
    //               ...prevFormData, // Spread the previous formData object
    //               "composeMail": 1    // Update the specified property
    //           }));
  };

  function insertAt(array, element, index) {
    // Ensure the index is within the bounds or at the end of the array
    let tempArray = array?.filter(el=>el?.value !== element?.value)
    // console.log(tempArray,'tempArray')  
    // Insert the element at the specified index
    tempArray.splice(index, 0, element);
  
    return tempArray;
  }

  const updateFormConfig = (key, updateKey, options, config) => {
    let temp = JSON.parse(JSON.stringify(config));
    temp.forEach((formElRow) => {
      formElRow?.forEach((formEl) => {
        if (formEl?.key === key) {
          formEl[updateKey] = options;
        }
        return formEl;
      });
      return formElRow;
    });
    return temp;
  };
  
  // Example usage
  const arr = [1, 2, 3, 4, 5];
  const result = insertAt(arr, 10, 2);
  // console.log(result); // Output: [1, 2, 10, 3, 4, 5]
  

  const onFocusField = (focus) => {
    // console.log(focus[0]);
    // console.log(focus[0]?.onFocusService);
    if(focus[0]?.onFocusGetData && focus[0]?.key && focus[0]?.onFocusService){
      dynamicService(focus[0]?.key,focus,focus[0]?.onFocusService)
    }
  }

  const handleSurveyChange = (item) => {
    // console.log(item);
    setSelectedSurvey(item);
  };
  
  const addNewSurvey = (survey,surveyID,surveyTemplateId) => {
    // console.log(survey);
    // setCurrentSurvey(surveyID);
    // setSurveyFlag(true);
    // setSelectedSurvey(survey);
    callForm(survey,surveyID,surveyTemplateId);
  };

  const openSubmittedActions = (survey,surveyID,surveyTemplateId,surveyResponseId) => {
    // console.log(survey,surveyID,surveyTemplateId,surveyResponseId);
    callForm(survey,surveyID,surveyTemplateId, surveyResponseId);
  };

  const cancelSurvey = () => {
    setSurveyFlag(false);
    setSelectedSurvey("");
    setCurrentSurvey();
    if(currentSurveyResponseId){
      setCurrentSurveyResponseId()
    }
    setFormConfig();
    setData();
    // defaultFormData.current={}
    setDefaultFormData()
    setCurrentTab("activeSurveys")
    getActiveSurveys()
  };

  const recallSurveyList = () => {
    setSurveyFlag(false);
    setSelectedSurvey("");
    setCurrentSurvey();
    if(currentSurveyResponseId){
      setCurrentSurveyResponseId()
    }
    setFormConfig();
    setData();
    getActiveSurveys();
    setDefaultFormData()
  }


  const validateSubmit = (payData) => {
    // console.log(formConfig);
    // console.log(payData)
    let flag = true;
    
    for (let i = 0; i < formConfig[0]?.length; i++) {
      // console.log(formConfig[0][i]);
      if(formConfig[0][i]?.rules[0]?.required){
        if(payData[formConfig[0][i]?.key]){
          
        }
        else{
          // console.log(formConfig[0][i]?.key);
          flag= false;
          return false;
        }
      }
    }
    return flag;
  }


  const SaveSurvey = () => {
    let payload ={...data};
    let quarter;
    let tempQuarterData;
    // if(payload['quarter']){
    //   if(typeof(payload['quarter'])!=="string"){
    //     // quarter = Math.floor((payload['quarter']?.month() + 3) / 3);
    //     // tempQuarterData = `Q${quarter}-${payload['quarter']?.year()}`
    //     tempQuarterData=payload['quarter']?payload['quarter']:dayjs(new Date())
    //   }
    //   else{
    //     quarter = Math.floor((dayjs(new Date(payload['quarter']))?.month() + 3) / 3);
    //     tempQuarterData = `Q${quarter}-${dayjs(new Date(payload['quarter']))?.year()}`
    //   }
    // }else{
    //   quarter = Math.floor((dayjs(new Date())?.month() + 3) / 3);
    //   tempQuarterData = `Q${quarter}-${dayjs(new Date())?.year()}`
    // }
    if(payload['quarter']){
      tempQuarterData=payload['quarter'] ? payload['quarter'] : dayjs(new Date())
    }
    else{
      tempQuarterData=payload['quarter'] ? payload['quarter'] : dayjs(new Date())
    }
    // payload['QuarterDate'] = payload['quarter']?payload['quarter']:dayjs(new Date());
    payload['quarter'] = tempQuarterData
    // console.log(payload,'payload');
    if(validateSubmit(payload)){
      // alertService.success("Success");
      saveServivce(payload)
    }
    else{
      alertService.error("Please fill all mandatory fields");
    }
    
    // saveServivce(payload);
    

  };

  const saveServivce = (payloadD) => {
    let payload={
      response: payloadD,
      surveyId:currentSurvey,
      surveyResponseId:currentSurveyResponseId,
      created_time:new Date(),
      created_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    // console.log(payload);
    ServiceUtils.postRequest("submitSurveyResponse",payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          
          recallSurveyList();
          setDefaultFormData()
          setSurveyFlag(false);
          setSelectedSurvey("");
          setCurrentSurvey();
          if(currentSurveyResponseId){
            setCurrentSurveyResponseId()
          }
          setFormConfig();
          setData();
          
        } else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error("Error while fetching data.");
      }
    );
  }

  const showConfirm = () => {
    confirm({
      title: 'Do you want to continue without saving the survey',
      icon: <ExclamationCircleFilled />,
      content: 'If yes, please proceed by clicking OK',
      onOk() {
        cancelSurvey();
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  }

  const onChangeTab = (key) => {
    setCurrentTab(key)
    if(key==="submittedResponses"){
      getSubmittedResponses();
    }
    else{
      getActiveSurveys();
    }
  };

  const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map((icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  });

  const columns = [
    {
        label: "Surveys",
        link: "",
    },
  ];

  const onSearch = (e,tab) => {
    // console.log(e?.target?.value);
    let searchTxt=e?.target?.value;
    // console.log(tab);
    if(tab==="activeSurveys"){
      let TData= JSON.parse(JSON.stringify(activeSurveyList?.bodyContent));
      const filterTable =TData.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(searchTxt.toLowerCase())
        )
      );
      setfilterActiveState({
        searchTex: e.target.value,
        filtered: !!e.target.value,
        data: e.target.value ? filterTable : activeSurveyList?.bodyContent
      });
    }
    else{
      let TData= JSON.parse(JSON.stringify(submittedResponsesList?.bodyContent));
      const filterTable =TData.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(searchTxt.toLowerCase())
        )
      );
      setfilterSubmittedState({
        searchTex: e.target.value,
        filtered: !!e.target.value,
        data: e.target.value ? filterTable : submittedResponsesList?.bodyContent
      });
    }
  };



  // const [state,setState] = useState({
  //   filteredInfo: null,
  //   sortedInfo: null,
  //   data,
  //   filtered: false,
  //   searchText: ""
  // });
  // const [searchTableText, setSearchTableText] = useState();
  // const emitEmpty = () => {
  //   setState({
  //     data: data,
  //     searchText: "",
  //     filtered: null
  //   });
  // };

  // const suffix = searchText ? (
  //   <UserOutlined onClick={emitEmpty} />
  // ) : null;


  const onChangeCollapse = (key) => {
    // console.log(key);
  };

  const arraysEqual = (arr1, arr2) => {
    // First, check if they have the same length
    console.log(arr1,arr2);
    if (arr1.length !== arr2.length) {
        return false;
    }
    
    // Then, compare each element
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    
    // If we pass both checks, the arrays are equal
    return true;
}


  const checkDisable = () => {
    // console.log(defaultFormData);
    // console.log(data);
    let flag = false;
    if(currentTab==="submittedResponses"){
      if(data && defaultFormData){
        for (const [key, value] of Object.entries(data)) {
          if(key in defaultFormData ){
            if(defaultFormData[key]!==data[key]){
              // console.log('l')
              // console.log(defaultFormData[key],data[key])
              
              if(key==="quarter"){
                if(typeof(data[key])==="object"){
                  let beforeQuarter = Math.floor((dayjs(new Date(defaultFormData[key]))?.month() + 3) / 3);
                  let quarter = Math.floor((data[key]?.month() + 3) / 3);
                  if(`Q${beforeQuarter}-${dayjs(new Date(defaultFormData[key]))?.year()}`===`Q${quarter}-${data[key]?.year()}`){
                    // console.log(`Q${quarter}-${data[key]?.year()}`)
                    // console.log(`Q${beforeQuarter}-${dayjs(new Date(defaultFormData[key]))?.year()}`);
                  }
                  else{
                    // console.log('l')
                    flag=true;
                  }
                }  
              }
              else{
                // console.log(defaultFormData[key],data[key])
                // console.log(Array.isArray(defaultFormData[key]),Array.isArray(data[key]))
                if(Array.isArray(defaultFormData[key]) && Array.isArray(data[key])){
                  if(arraysEqual(defaultFormData[key], data[key])){
                    // 
                  }
                  else{
                    // console.log('kl')
                    flag=true;
                  }
                }
                else{
                  flag=true;
                }
              }
              
            }
            // else{
            //   console.log(defaultFormData[key]);
            //   console.log(data[key])
            // }
          }
          else{
            if(data[key]){
              // console.log('j')
              flag=true;
            }
          }
        }
      }
    }
    else{
      flag=true;
    }
    // console.log(flag);
    return flag;

  }
  

  const getFIlters = () => {
    // console.log(datattt);
    // data?.map((item)=>{
    
    //   if(item.type="checkBox") {
    //     console.log(item);
    //     item["childeren"].render = (_, record) => (
          
    //       <Space size="middle">
    //           {record?.options?.length}
    //           {/* <a onClick={() => onProfileSelect(record)}>{record.name}</a> */}
    //       </Space>
    //   )
    //   }
    //   })
    // console.log(datattt);

  } 

  const clearAll = () => {
    // console.log()
    setShowFilterFlag(false);
  }

  const applyFiltersService = (payLoad) => {
    ServiceUtils.postRequest("surveyFilters",payLoad).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          if(currentTab==="activeSurveys"){
            let temp = JSON.parse(JSON.stringify(response?.data?.data));
  
            temp?.headerContent?.map((el) => {
              if (el.key === "surveyName") {
                el.render = (_, record) => (
                  <Space size="middle">
                      {record?.surveyName}
                  </Space>
                );
              } else if (el.key === "description") {
                el.render = (_, record) => (
                  <Space size="middle">
                    {record?.description}
                  </Space>
                );
              } else if (el.key === "responsesSubmitted") {
                el.render = (_, tags) => (
                  <Tag color="#fbe38b" key={tags}>
                    <span style={{ color: "black", fontWeight: "500" }}>
                      {tags.responsesSubmitted}
                    </span>
                  </Tag>
                );
              }
              if (el.key === "actions") {
                el.render = (_, record) => (
                  <Space size="middle">
                    <a onClick={() => addNewSurvey(record?.surveyName, record?.surveyId, record?.surveyTemplateId)}>
                      <ExportOutlined /> &nbsp; {record?.actions}
                    </a>
                  </Space>
                );
              }
            });
            setActiveSurveyList(temp);
          }
          else{
            let temp = JSON.parse(JSON.stringify(response?.data?.data));

            temp?.headerContent?.map((el) => {
              if (el.key === "surveyName") {
                // el.title=()=>{return (
                //   <div>
                //     <Space>
                //       {el?.key}
                //       <Dropdown
                //         dropdownRender={(menu) => (
                //           <div >
                            
                //             <Space
                //               style={{
                //                 padding: 8,
                //               }}
                //             >
                //               <Button type="primary">Click me!</Button>
                //             </Space>
                //           </div>
                //         )}
                //         trigger={['click']}
                //       >
                //           <Space>
                //             <SearchOutlined />
                //           </Space>
                //       </Dropdown>
                //     </Space>
                //   </div>
                // )};
                el.render = (_, record) => (
                  <Space size="middle">
                    <Tooltip title={record?.surveyName}>
                        {record?.surveyName} 
                    </Tooltip>
                  </Space>
                );
                // el.onFilter= (value, record) => record.surveyName.indexOf(value) === 0;
                // el=getColumnSearchProps('surveyName');
                // el.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                //   <div
                //     style={{
                //       padding: 8,
                //     }}
                //     onKeyDown={(e) => e.stopPropagation()}
                //   >
                //     <Input
                //       ref={searchInput}
                //       placeholder={`Search ${"surveyName"}`}
                //       value={selectedKeys[0]}
                //       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                //       onPressEnter={() => handleSearch(selectedKeys, confirm, "surveyName")}
                //       style={{
                //         marginBottom: 8,
                //         display: 'block',
                //       }}
                //     />
                //     <Space>
                //       <Button
                //         type="primary"
                //         onClick={() => handleSearch(selectedKeys, confirm, "surveyName")}
                //         icon={<SearchOutlined />}
                //         size="small"
                //         style={{
                //           width: 90,
                //         }}
                //       >
                //         Search
                //       </Button>
                //       <Button
                //         onClick={() => clearFilters && handleReset(clearFilters)}
                //         size="small"
                //         style={{
                //           width: 90,
                //         }}
                //       >
                //         Reset
                //       </Button>
                //       <Button
                //         type="link"
                //         size="small"
                //         onClick={() => {
                //           confirm({
                //             closeDropdown: false,
                //           });
                //           setSearchText(selectedKeys[0]);
                //           setSearchedColumn("surveyName");
                //         }}
                //       >
                //         Filter
                //       </Button>
                //       <Button
                //         type="link"
                //         size="small"
                //         onClick={() => {
                //           close();
                //         }}
                //       >
                //         close
                //       </Button>
                //     </Space>
                //   </div>
                // )
              } 
              else if(el.key === "HCPName"){
                el.render = (_, record) => (
                  <Space size="middle">
                    <Tooltip title={record?.HCPName}>
                        {record?.HCPName}
                    </Tooltip>
                  </Space>
                );
                // el.onFilter= (value, record) => record.HCPName.indexOf(value) === 0;
              }
              else if (el.key === "description") {
                el.render = (_, record) => (
                  <Space size="middle">
                    <Tooltip title={record?.description}>
                      {record?.description}
                    </Tooltip>
                  </Space>
                );
                // el.onFilter= (value, record) => record.description.indexOf(value) === 0;
              } else if (el.key === "responsesSubmitted") {
                el.render = (_, tags) => (
                  <Tag color="#fbe38b" key={tags}>
                    <span style={{ color: "black", fontWeight: "500" }}>
                      {tags.responsesSubmitted}
                    </span>
                  </Tag>
                );
              }
              //  else {
              //   el.render = (_, record) => (
              //     <Space size="middle">
              //       <Tooltip title={record?.description}>
              //         {record?.description}
              //       </Tooltip>
              //     </Space>
              //   );
              // }
              if (el.key === "actions") {
                el.render = (_, record) => (
                  <Space size="middle">
                    <a 
                    onClick={() => openSubmittedActions(record?.surveyName, record?.surveyId, record?.surveyTemplateId, record?.surveyResponseId)}
                      >
                      <ExportOutlined /> &nbsp; Open Survey
                    </a>
                  </Space>
                );
              }
            });
            setSubmittedResponsesList(temp);
          }

        } else if (response.status === 401) {
          navigate('/logout');
        }
      },
      (error) => {
        alertService.error("Error while fetching data.");
      }
    );
  };

  const clearFilters = () => {
    let payLoad={}
    if(currentTab==="activeSurveys"){
      payLoad = {
        "type":currentTab,
        "filters_json": filterActivedefaultData,
      }
      setFilterSelectedData(filterActivedefaultData)
    }
    else{
      payLoad = {
        "type":currentTab,
        "filters_json": filterSubmitteddefaultData,
      }
      setFilterSubSelectedData(filterSubmitteddefaultData)
    }
    // console.log(payLoad);
    applyFiltersService(payLoad);
  }

  const applyFilters = () => {
    let payLoad={}
    if(currentTab==="activeSurveys"){
      payLoad = {
        "type":currentTab,
        "filters_json": {...filterSelectedData,"searchText":searchFilterTerm},
      }
    }
    else{
      payLoad = {
        "type":currentTab,
        "filters_json": {...filterSubSelectedData, "searchText":searchFilterTerm},
      }
    }
    // console.log(payLoad);
    applyFiltersService(payLoad);
  }

  return (
    <div className="survey-main">
      <Card
          style={{ width: "100%", height: "fit-content" }}
          bodyStyle={{ padding: "10px" }}
      >
        {/* <BreadcrumbList active={columns} /> */}
        <BreadcrumbList active={[{ label: 'Surveys', link: '' }]} />
      </Card>
      <Layout>
        {!surveyFlag && !selectedSurvey && (
          <div>
            {showFilterFlag ? 
              <Sider
                // breakpoint="lg"
                // collapsedWidth="0"
                // width={'15%'}
                // onBreakpoint={(broken) => {
                //   console.log(broken);
                // }}
                width={250}
                style={{
                  overflow: 'visible'
                }}
                // onCollapse={(collapsed, type) => {
                //   console.log(collapsed, type);
                // }}
              > 
                  <Layout style={{height: '-webkit-fill-available'}}>
                    <Header className="survey-filter-header" style={{"padding": "0px !important"}}>
                      <div className="demo-logo-vertical" />
                      <Row gutter={16} style={{padding:'0px', margin:'0px'}}>
                        <Col span={12}>
                          <b>Filters</b>
                        </Col>
                        <Col span={12} style={{alignSelf: 'center'}}>
                          <span style={{opacity:'0.5',display: 'flex', justifyContent: 'end'}} onClick={clearAll} className="pointer">
                            <CloseOutlined />
                          </span>
                        </Col>
                      </Row>
                      <Divider/>
                      <div style={{padding:'6px'}}>
                        <Input
                          onChange={(e)=>{setSearchFilterTerm(e?.target?.value)}}
                          placeholder="Search in Table"
                          enterButton
                          value={searchFilterTerm}
                          // allowClear
                        />
                      </div>
                    </Header>
                  <Layout>
                    <Content className="survey-filter-content">
                      {currentTab==="activeSurveys" &&
                        <Collapse
                              defaultActiveKey={['1']}
                              onChange={onChangeCollapse}
                              expandIconPosition='end'
                              items={filterOptionsActiveData}
                              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        />   
                      }
                    { currentTab==="submittedResponses" && 
                      <Collapse
                        defaultActiveKey={['1']}
                        onChange={onChangeCollapse}
                        expandIconPosition='end'
                        items={filterSubmittedActiveData}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                      />
                    }
                    </Content>
                  </Layout>
                  <Footer>
                    <div className="submit-block" style={{marginBottom: '5px'}}>
                        <div style={{ paddingRight: "10px" }}>
                          {/* <Button
                          style={{marginRight:'10px'}}
                            // className="edit-meet-button"
                            onClick={() =>
                              clearFilters()
                            }
                          >
                            Clear
                          </Button> */}
                          <Button
                            className="edit-meet-button"
                            type="primary"
                            onClick={() =>
                              applyFilters()
                            }
                          >
                            Apply
                          </Button>
                        </div>
                    </div>
                  </Footer>
                </Layout>
                  
              </Sider>
              :
              <div>
                <Tooltip title="Filters">
                  <Button style={{margin:'15px'}} onClick={()=>{setShowFilterFlag(true)}}>
                      
                          <FilterOutlined />
                  </Button>
                </Tooltip>
                <Sider width={'5%'}/>
              </div>
            }
          </div>
        )}
      <Content>
        {!surveyFlag && !selectedSurvey && (
          <Tabs
            style={{ paddingLeft:'10px',paddingRight:'10px', paddingBottom:'10px' }}
            defaultActiveKey="1"
            items={items}
            onChange={onChangeTab}
          />
        )}
        <>
          {surveyFlag && selectedSurvey && (
            <div style={{ padding: "15px 30px" }}>
              <div className="kol-search" style={{ paddingBottom: "5px" }}>
                <Row style={{ width: "100%" }}>
                  <Col
                    xs={{ flex: "100%" }}
                    sm={{ flex: "100%" }}
                    md={{ flex: "100%" }}
                    lg={{ flex: "50%" }}
                    xl={{ flex: "50%" }}
                  >
                    <div
                      className={classNames("", {
                        "search-kol-mobile":
                          windowSize$ === "lg" ||
                          windowSize$ === "xl" ||
                          windowSize$ === "xxl",
                        "search-kol-mobile":
                          windowSize$ === "xs" ||
                          windowSize$ === "sm" ||
                          windowSize$ === "md",
                      })}
                    >
                        <div className="" title="Go to Home" >
                        <img className="pointer"  style={{width:'30%'}} src={AppIcon} />
                      </div>
                      <h1 style={{margin:'10px 0'}}>{surveyName}</h1>
                    </div>
                  </Col>
                  <Col
                    xs={{ flex: "100%" }}
                    sm={{ flex: "100%" }}
                    md={{ flex: "100%" }}
                    lg={{ flex: "50%" }}
                    xl={{ flex: "50%" }}
                  >
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <Row gutter={[16,16]}>
                        <Col>
                          {submittedBy &&<p style={{ opacity: "0.5" }}>Submitted by <b>{submittedBy}</b></p> }
                        </Col>
                        <Col>
                          {submittedOn && <p style={{ opacity: "0.5" }}>Submitted on <b>{submittedOn}</b></p> }
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  padding: "15px 30px",
                  backgroundColor: "white",
                  height: "calc(100vh - 200px)",
                  overflow: "auto",
                }}
                className="builder"
              >
                <AntBuilder disable={!userDetails?.role_access?.home?.surveys?.edit} config={formConfig} onAction={handle} onFocus={onFocusField} data={data} defaultData={defaultFormData}/>
                {formConfig?.length &&
                  <div style={{ marginTop: 23 }}>
                    <div className="submit-block">
                      <div style={{ paddingRight: "10px" }}>
                        <Button onClick={showConfirm}> {formConfig[0]?.length ? "Cancel" : "Back"}</Button>
                      </div>
                      {formConfig[0]?.length ?
                        <Button
                          className="edit-meet-button"
                          type="primary"
                          disabled={checkDisable()=== true ? false : true}
                          onClick={() => SaveSurvey()}
                        >
                          Submit
                        </Button>
                        :
                        <></>
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
          )}
        </>
      </Content>
      </Layout>
    </div>
  );
}
