import React from 'react';
import './Breadcrumb.scss';
import { Breadcrumb } from "antd";
import { HomeOutlined,ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../Utils/AppContext';
export default function BreadcrumbList({ active }) {
  const { data, updateData } = useAppContext();
  const navigate = useNavigate();
  const onTabSelect = (data) => {
    if(JSON.parse(localStorage?.getItem('activetab'))?.key === 'Surveys'){
      updateData({"label":"Conferences","key":"Conference"});
      localStorage.setItem("activetab", JSON.stringify({"label":"Conferences","key":"Conference"}));
    }else if(data === '/home'){
      localStorage.setItem('history', data);
    } 
    navigate(data)
  }
  return (
    <Breadcrumb
      separator=""
      items={[
        {
          title: (
            <>
              <div className='home-breadcrumb' onClick={() => onTabSelect('/home')}>
                <div>
                  <ArrowLeftOutlined className='arrow-display'/>
                  <span style={{ marginLeft: '2px' }}>Home</span>
                </div>
              </div>
            </>
          ),
        },
        {
          type: 'separator',
        },
        {
          title: (
            <>
              <div style={{ display: 'flex' }}>
                {active && active.map(el =>
                  <div className='subLink'>
                    {el.link !== '' ?
                      <div>
                        <div className='ant-bread' onClick={() => onTabSelect(el.link)}>
                          <span style={{ margin: '0px 2px' }}>{el.label}</span>
                        </div>
                        <label> /</label>
                      </div>
                      : <div style={{ margin: '0px 2px' }}>{el.label}</div>}
                  </div>
                )}
              </div>
            </>
          )
        }

      ]}
    />
  )
}