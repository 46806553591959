import React, { useEffect, useRef, useState } from 'react';
import './Home.scss';
import classNames from 'classnames';
import { Row, Col, Button, Input, Typography, Card, Avatar, Space, Image, Table,Tooltip, Dropdown,message, Divider, theme, Carousel, Flex, Slider, Switch, Tabs, Select } from "antd";
import { ServiceUtils } from '../../Shared/Utils/ServiceUtils';
import { alertService } from '../../Shared/Utils/ToasterUtils';
import { useNavigate } from 'react-router-dom';
import ConferenceImage from '../../Assets/images/conference.png';
import { getDecryptSession } from '../../Shared/Utils/CommonUtils';
import {
  DeleteOutlined, EditOutlined, MoreOutlined,FileTextOutlined,ArrowRightOutlined
} from '@ant-design/icons';
import KOL from './KOL.js/KOL';
import ArcutisAttendee from './ArcutisAttendee/ArcutisAttendee';
import { useSharedState } from "../../Shared/Services/WindowSizeService";
import Survey from './Survey/Survey';
import useWindowSize from '../../Shared/Utils/windowResizeUtils';
import surveyImage from "../../Assets/images/Survey.svg";
import moreImage from "../../Assets/images/3 dots.svg";
import { useAppContext } from '../../Shared/Utils/AppContext';
import ReactEcharts from 'echarts-for-react';

export default function Home({ ...props }) {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const { Title, Text } = Typography;
  const Search = Input.Search;
  const windowSize$ = useSharedState();
  const [tabList, settabList] = useState([]);
  const [dataObj, setDataObj] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [attendeeList, setAttendeeList] = useState([]);
  const [selectedMenu, setMenu] = useState(localStorage.getItem("activetab") ? JSON.parse(localStorage.getItem("activetab")) : { label: "Conferences", key: 'Conference' });
  const [searchQuery, setSearchQuery] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [activeSurveyList,setActiveSurveyList] = useState([]);
  const { data, updateData } = useAppContext();
  const [currentReportIndex, setCurrentReportIndex] = useState();
  const [expanded, setExpanded] = useState(false);
  const { TabPane } = Tabs;
  const { Option } = Select;
  const [reportsTab,setReportsTab] = useState('reports');
  const [hcpSalesTrendsData,setHcpSalesTrendsData] = useState({hcpIds:[],products:[],prescriptions:[]})
  const [hcpData,setHcpData]=useState({product:"",npi_id:"",type:'portfolio',portfolios:['ZORYVE']});
  const [summaryData,setSummaryData] = useState();
  const [prescriptions,setPrescriptions] =useState({});
  const effectHasRun = useRef(); 
  effectHasRun.current = false

  useEffect(()=>{
    const session = localStorage.getItem("arcutis-user");
    const userDetails = getDecryptSession(session);
    let homePage = false;

     Object?.keys(userDetails?.role_access?.home)?.map((key)=>{
      if(userDetails?.role_access?.home[key]?.edit || userDetails?.role_access?.home[key]?.view){
        homePage = true
      }
    })
    let tabs = [{
      label: 'Conferences',
      key: 'Conference',
      view:homePage
    },
    {
      label: 'Arcutis Attendee',
      key: 'Attendee',
      view:userDetails?.role_access?.arcutisAttendee?.view
    },
    {
      label:'KOL Profiles',
      key:'KOL',
      view:userDetails?.role_access?.kolProfiles?.view
    },
    {
      label: 'Reports',
      key: 'Reports',
      view:userDetails?.role_access?.reports?.view
    }]
    let accessibleTabs = tabs?.filter((tab)=>{
      return(tab?.view)
    })
    settabList([...accessibleTabs])
    onTabSelect({key:accessibleTabs?.[0]?.key})
  },[])
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const [reportList, setReportList] = useState({
    tableData: {
        headerContent: [],
        bodyContent: []
    }
});
useEffect(()=>{
  if(data?.key?.length > 0){
    onTabSelect(data)
  }
},[data])

const filteredAttendeeList = attendeeList.filter((attendee) =>
    Object.values(attendee).some(
      (value) =>
        typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const filteredProfileList = profileList.filter((profile) =>
    Object.values(profile).some(
      (value) =>
        typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  useEffect(() => {
    const history = localStorage.getItem('history');
    if (history && history !== '/home') {
      navigate(history);
      return;
    }
    const currentTab = JSON.parse(localStorage.getItem("activetab"));
    if (currentTab) {
      let activeTa = currentTab.key;
      selectedMenu.key = activeTa;
      setMenu(selectedMenu);
      if (currentTab.key === 'Conference') {
        fetchMeetingList();
        getActiveSurveys();
      } else if (currentTab.key === 'Profile') {
        // fetchProfileList();
      } else if (currentTab.key === 'Reports'){
        getAttendee();
      } else {
        // fetchAttendeeList();
      }
    }
    else {
      fetchMeetingList();
      getActiveSurveys();
    }
    const session = localStorage.getItem("arcutis-user");
    const userDetails = getDecryptSession(session);
    setUserDetails(userDetails);
    fetchMeetingList();
  }, [])

  useEffect(()=>{
    fetchNpiList();
  },[reportsTab])

  const fetchMeetingList = () => {
    let payload = {}
    ServiceUtils.getRequest('listOfConferences').then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setDataObj(response.data.data)
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching data')
        console.log(error)
      }
    );
  };
  const fetchNpiList = async() => {
    ServiceUtils.getRequest('kols').then(
      async(response) => {
        if (response.status === 200 && response?.data.status) {
          await setHcpSalesTrendsData({...hcpSalesTrendsData,hcpIds:response.data.data})
          handleHcpChangeData(response.data.data?.[0]?.key)
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching data')
        console.log(error)
      }
    );
  };
  const fetchProductsList = (payload) => {
    ServiceUtils.postRequest('products',payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setHcpSalesTrendsData({...hcpSalesTrendsData,products:response.data.data})
          setPrescriptions({});
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching data')
        console.log(error)
      }
    );
  };
  const fetchPrescriptionList = (payload) => {
    ServiceUtils.postRequest('prescription',payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setPrescriptions({...response.data.data})
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching data')
        console.log(error)
      }
    );
  };

  const fetchSummaryData = (payload) => {
    ServiceUtils.postRequest('summary',payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setSummaryData({...response.data.data})
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching data')
        console.log(error)
      }
    );
  };

  const fetchPortfoliosList = (payload) => {
    ServiceUtils.postRequest('portfolios',payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setHcpSalesTrendsData({...hcpSalesTrendsData,portfolios:response.data.data})
          let prescriptionPayload = {portfolio:[response.data.data?.[0]?.key],npi_id:payload?.npi_id,type:hcpData?.type}
          fetchPrescriptionList(prescriptionPayload);
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching data')
        console.log(error)
      }
    );
  };


  const fetchProfileList = () => {
    let payload = {}
    ServiceUtils.postRequest('listOfProfiles', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setProfileList(response.data.data)
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching data')
        console.log(error)
      }
    );
  };

  const onReportSelect = (record) => {
    if(record){
      window.open( record?.url);
    }
    console.log(record);
  }

  const getAttendee = () => {
    ServiceUtils.getRequest('reports').then(
        (response) => {
            if (response.status === 200 && response?.data.status) {
              response.data.tableData?.headerContent.map(el => {
                    if (el.key === 'reportName') {
                        el.render = (_, record) => (
                            <Space size="middle"
                            >
                                <a 
                                onClick={() => onReportSelect(record)}
                                >{record?.reportName}</a>
                            </Space>
                        )
                    } 
                    else if(el.key === 'description'){
                      el.render = (_, record) => (
                        <Tooltip title={record?.description} >
                          <Space size="middle">
                              {/* <a 
                              onClick={() => onReportSelect(record)}
                              >{record?.description}</a> */}
                              {record?.description}
                          </Space>
                        </Tooltip>
                    )
                    }
                    // else if (el.key === 'invitation_type') {
                    //     el.render = (_, tags) => (
                    //         <Tag color={tags.backgroundColor} key={tags}>
                    //             <span style={{ color: tags.color, fontWeight: '500' }}>{tags.invitation_type}</span>
                    //         </Tag>
                    //     )
                    // }
                })
                setReportList(response.data)
            }
            else if(response.status === 401 ){
              navigate('/logout');
            }
        },
        (error) => {
            alertService.error('Error while fetching data.')
        }
    );
}



  const onTabSelect = (tabs) => {
    localStorage.setItem("activetab", JSON.stringify(tabs));
    setSearchQuery('');
    setMenu(tabs);
    if (tabs.key === 'Conference') {
      setDataObj([]);
      fetchMeetingList();
      getActiveSurveys();
    } else if (tabs.key === 'Profile') {
      setProfileList([]);
      fetchProfileList();
    } else if (tabs.key === 'Attendee'){
      // setAttendeeList([]);
      // fetchAttendeeList();
    } else if (tabs.key === 'Reports'){
      getAttendee();
      // setAttendeeList([]);
      // fetchAttendeeList();
    } 
  };

  const onSelectCard = (data, startDate, endDate) => {
    localStorage.setItem("meetingListFilter", JSON.stringify({
      meetingFilter: [],
      attendeeFilter: [],
      calenderDate: startDate ? new Date(startDate) : new Date(),
      calenderEndDate: endDate && new Date(endDate),
      view: 'day',
      conferenceId: null
    }));
    navigate(data);
  };

  const setBg = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return "#" + randomColor;
  }
  const addNew = () => {
    if(selectedMenu.key === 'Profile'){
      navigate('profile/addProfile')
    }
    else if ( selectedMenu.key === 'Attendee'){
      navigate('profile/addAttendee')
    }
    else if(selectedMenu.key === 'Conference'){
      navigate('conference/addConference')
    }
    // selectedMenu.key === 'Profile' ? navigate('profile/addProfile') : navigate('profile/addAttendee')
  }

  const editConference = (id) => {
    navigate('conference/' + id);
  }

  const deleteConference = (id) => {
    let payload = {
      "type": "delete",
      "conference_id": id,
      // "conference_details": null,
      "user_id": userDetails?.user_id ? userDetails?.user_id : null
    }
    ServiceUtils.postRequest('addConference', payload).then(
        (response) => {
            if (response.status === 200 && response?.data.status) {
              fetchMeetingList();
            }
            else if(response.status === 401 ){
              navigate('/logout');
            }
        },
        (error) => {
            alertService.error('Error while fetching Objective.')
        }
    );
  }

  const items = [
    {
      label: <Space>Edit</Space>,
      key: '0',
      evtype:'edit'
    },
    {
      label: <Space>Delete</Space>,
      key: '1',
      evtype:'delete'
    }
  ];

  const onCli = ({ key }) => {
    message.info(`Click on item ${key}`);
  };
  const { useToken } = theme;
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: 'none',
  };
  const getActiveSurveys = () => {
    ServiceUtils.getRequest("listOfSurveys").then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setActiveSurveyList(response?.data?.data?.tableData?.bodyContent);
        } 
      },
      (error) => {
        alertService.error("Error while fetching data.");
      }
    );
  };

  const setCurrentStateReport= (ind, value) =>{
    setCurrentReportIndex(ind)
    setExpanded(value);
  }

  const handleHcpChangeData=async(value)=>{
    await setHcpData({...hcpData,npi_id:value})
    let payload = {npi_id:value}
    fetchSummaryData(payload);
    if(hcpData?.type === 'portfolio' && hcpData?.portfolios?.length > 0){
      let prescriptionPayload = {portfolio:hcpData?.portfolios,npi_id:value,type:hcpData?.type}
      fetchPrescriptionList(prescriptionPayload);
    }
  }

  const handleHcpTypeChange=(type)=>{
    setHcpData({...hcpData,type})
    if(type === 'products' && hcpData?.portfolios?.length > 0 ){
      let payload = {portfolio:hcpData?.portfolios,npi_id:hcpData?.npi_id,type:hcpData?.type}
      fetchProductsList(payload);
  }
    let payload = {type,npi_id:hcpData?.npi_id}
    fetchPortfoliosList(payload);
  }

  return (
    <>
      <div className='main-Content'>
        <div style={{maxHeight:"100%",overflow:"auto"}}>
          <div className='home-tabs '>
            {tabList && tabList?.length > 1 && tabList.map((tabs) =>
              <div className={classNames('tabs-list', { 'active': selectedMenu?.key === tabs.key })} onClick={() => onTabSelect(tabs)}>
                <Text>{tabs.label}</Text>
              </div>
            )}
          </div>
          {selectedMenu.key === 'Conference' && userDetails?.role_access?.home?.surveys?.view &&
            <div style={{padding:'0 20px'}}>
            <Title style={{padding:'0px 10px 10px 0px',margin:'0px'}} className='home-side-header' level={4}>Surveys</Title>
            <Card className="surveyCard" style={{background:"white",padding:'8px',minHeight:'16vh'}}>
              <div style={{display:'flex',justifyContent:"space-between" ,alignItems: 'center',marginBottom: '5px'}}>
              <Title className='home-side-sub-header' style={{padding:'5px'}} level={5}>Active Surveys</Title>
            
               <Button style={{border: '1px solid #000000CC', margin: '5px'}} type="default" 
                onClick={()=>onTabSelect({"label":'Surveys',"key":'Surveys'})}>
                <span className='home-action-button'>
                 <Image src={surveyImage} height={'16px'} preview={false} style={{marginBottom: '2px'}} alt='Survey' /> Go To Surveys
                </span>
              </Button>
              </div>
              {activeSurveyList?.length > 0 ? <Carousel dotPosition="bottom" autoplay autoplaySpeed={3000} adaptiveHeight>
                {activeSurveyList?.map((survey, index) => (
                  <Card styles={{body:{alignItems:'center'}}} className="internalCard" key={index} bordered={false} style={{ margin: '16px 0' ,width:'90%'}}>
                    <Row align="middle" wrap={false} gutter={16}>
                      <Col style={{alignItems: 'center',display: 'flex'}}>
                        <FileTextOutlined style={{ fontSize: '15px' ,paddingRight:'10px'}} />
                        <Text strong>{survey.surveyName}</Text>
                      </Col>
                      <Col>
                        <Text strong>{survey.surveytype}</Text>
                      </Col>
                      <Col>
                        <Text strong>{survey.description}</Text>
                      </Col>
                      {/* <Col>
                        <Text strong>{survey?.publishedOn}</Text>
                      </Col> */}
                      {/* <Col>
                        <Button type="link" icon={<ArrowRightOutlined />} />
                      </Col> */}
                    </Row>
                  </Card>
                ))}
              </Carousel>:
              <span className='noActiveSurveys'>No Active Surveys</span>}
            </Card>
          </div>}
          <div>
            {selectedMenu.key !== 'KOL' && selectedMenu.key !=='Attendee' && selectedMenu.key !=='Surveys' &&
              <div className='meetingList' >
                <Row style={{width:'100%'}}>
               { selectedMenu.key === 'KOL' && selectedMenu.key ==='Attendee' ? <Col xs={{ flex: '100%' }}
                            sm={{ flex: '100%' }}
                            md={{ flex: '100%' }}
                            lg={{ flex: '50%' }}
                            xl={{ flex: '50%' }}>

                  <div 
                    className={classNames("", {
                      "search-kol-mobile":
                          windowSize$ === "lg" ||
                          windowSize$ === "xl" ||
                          windowSize$ === "xxl",
                      "search-kol-mobile":windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md",
                  })}
                  >
                    {/* <Search style={{ width: '100%' }} size="large" placeholder="Search" value={searchQuery} onChange={handleSearchInputChange} /> */}
                  </div>
                </Col>:<></>}
                <Col xs={{ flex: '100%' }}
                            sm={{ flex: '100%' }}
                            md={{ flex: '50%' }}
                            lg={{ flex: '50%' }}
                            xl={{ flex: '50%' }}>

                    <div style={{display:"flex", justifyContent:"start"}}
                    >
                      {selectedMenu.key === 'Conference' && userDetails?.role_access?.home?.conferences?.view && 
                      <Title className='home-side-header' style={{margin:'0px'}} level={4}>{selectedMenu.label}</Title>}</div></Col>
                {userDetails?.role_access?.home?.conferences?.edit && <Col xs={{ flex: '100%' }}
                            sm={{ flex: '100%' }}
                            md={{ flex: '50%' }}
                            lg={{ flex: '50%' }}
                            xl={{ flex: '50%' }}>

                    <div style={{display:"flex", justifyContent:"end"}}
                    >
                      <Title className='total-count' style={{marginRight:'18px'}} level={4}> Total {selectedMenu.key === 'Conference' ? dataObj.length :
                        selectedMenu.key === 'Profile' ? filteredProfileList.length : selectedMenu.key === 'Reports' ? reportList?.tableData?.bodyContent?.length : filteredAttendeeList.length} {selectedMenu.key}</Title>
                      {(selectedMenu.key === 'Profile' || selectedMenu.key === 'Attendee' || selectedMenu.key === 'Conference') && <Button style={{ background: '#000000', marginTop: '5px', marginLeft: '5px' }} type="primary" onClick={() => addNew()}><span className='home-action-button'>+ Add</span></Button>}
                    </div>
                  </Col>}
                </Row>
              </div>
            }
            {selectedMenu.key === 'Conference' && userDetails?.role_access?.home?.conferences?.view && 
              <div className='conference'>
                <div className='conference-height'>
                  <Row type="flex">
                    {dataObj && dataObj.map(meet =>
                      <>
                        <Col style={{ padding: '0px 1% 0px 0px' }} className='tile' xs={{ flex: '100%' }}
                          sm={{ flex: '50%' }}
                          md={{ flex: '50%' }}
                          lg={{ flex: '25%' }}
                          xl={{ flex: '25%' }}>
                          <div className='conference-card'>
                            <Card 
                            title={meet?.conference_name}
                            styles={{header:{backgroundColor:"#F3E8E8"}}} 
                             extra={
                              userDetails?.role_access?.home?.conferences?.edit && <div style={{display:'flex'}}>
                                {/* <Tooltip title={"Edit " + meet?.conference_name}>
                                  <EditOutlined className="pointer" style={{marginRight:'20px'}} onClick={()=>{editConference(meet?.conference_id)}}/>
                                </Tooltip>
                                <Tooltip title={"Delete " + meet?.conference_name} onClick={()=>{deleteConference(meet?.conference_id)}}>
                                  <DeleteOutlined className="pointer"/>
                                </Tooltip> */}
                                <Dropdown
                                  menu={{items,
                                  }
                                  }
                                  placement="bottom"
                                    // onClick: {editDeleteConference({meet?.conference_id})}
                                  
                                    dropdownRender={(menu) => (
                                      <div style={contentStyle}>
                                        {/* {React.cloneElement(menu, {
                                          style: menuStyle,
                                        })}
                                        <Divider
                                          style={{
                                            margin: 0,
                                          }}
                                        /> */}
                                        <Space className='pointer'
                                          style={{
                                            padding: 8,
                                          }}
                                          onClick={()=>{editConference(meet?.conference_id)}}
                                        >
                                          {/* <Button type="primary">Click me!</Button> */}
                                          Edit 
                                          {/* <EditOutlined/> */}
                                        </Space>
                                        <br/>
                                        <Divider
                                          style={{
                                            margin: 0,
                                          }}
                                        />
                                        <Space className='pointer'
                                          style={{
                                            padding: 8,
                                          }}
                                          onClick={()=>{deleteConference(meet?.conference_id)}}
                                        >
                                          {/* <Button type="primary">Click me!</Button> */}
                                          Delete
                                           {/* <DeleteOutlined/> */}
                                        </Space>
                                      </div>
                                    )}
                                  trigger={['click']}
                                >
                                  <p onClick={(e)=>{e.preventDefault()}}>
                                    <Space className='pointer' style={{marginTop:'12px', fontSize:'20px'}}>
                                      {/* <MoreOutlined /> */}
                                      <Image src={moreImage} alt="More" height={"15px"} style={{alignItems:"center", display:"flex"}} preview={false}/>
                                    </Space>
                                  </p>
                                </Dropdown>
                              </div>
                            } 
                            // style={{background: 'none'}}
                            > 
                              <Card className='pointer' style={{ width: '100%', height: '170px', background: 'none' , display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#EEF1F2B2'}} onClick={() => onSelectCard('meetingDetails' + '/' + meet?.conference_id, meet?.conference_start_date, meet?.conference_end_data)}>
                                {/* <div className='conference-text' style={{padding:'12%'}}>
                                  <span>
                                    <Title level={3}>{meet.conference_name}</Title>
                                    <Title level={5}>{meet.conference_description}</Title>
                                  </span>
                                  <div> */}
                                {/* <Avatar.Group>
                                      {meet && meet.attendee_Profile_URL && meet.attendee_Profile_URL.length > 0 && meet.attendee_Profile_URL.map(el =>
                                        <span>
                                          <Avatar src={el.URL} />
                                        </span>
                                      )}
                                    </Avatar.Group>
                                  </div> */}
                                {/* </div> */}
                                {/* <div className='conference-text'> */}
                                {/* <Text className='conference-team'>{meet.agenda}</Text> */}
                                {/* <Text className='conference-team'>{meet.attendee}</Text>
                                </div>
                                <div className='conference-text'>
                                  <Text className='conference-text-color conference-time'>{meet.meeting_start_time}</Text>
                                  <Text className='hrs'>{meet.hours}</Text>
                                  <Text className='conference-text-color conference-time'>{meet.meeting_end_time}</Text> */}
                                {/* </div> */}
                                {/* <p>{meet?.conference_name}</p> */}
                                <Image style={{width: '100%' , height: '150px', verticalAlign: 'middle'}} preview={false} src={meet?.conference_image} />
                              </Card>
                            </Card>
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </div>
            }
            {selectedMenu.key === 'Profile' &&
                <div className='conference'>
                  <div className='conference-height'>
                     <Row>
                      {filteredProfileList && filteredProfileList.map(el =>
                        <Col className='tile' style={{ padding: '0px 1% 0px 0px' }} xs={{ flex: '100%' }}
                          sm={{ flex: '50%' }}
                          md={{ flex: '50%' }}
                          lg={{ flex: '25%' }}
                          xl={{ flex: '25%' }}>
                          <div className='attendee-body'>
                            <Card className='pointer' style={{ width: '100%', height: 'fit-content', borderRadius: '4px' }} onClick={() => onSelectCard('profileDetails' + '/' + el.profile_id)}>
                              <Space size={16}>
                                <Space wrap size={16}>
                                  <Avatar
                                    style={{
                                      backgroundColor: setBg(),
                                      verticalAlign: 'middle',
                                    }}
                                    size={55}>
                                    <span className='avatar-Text'>{el.full_name.split(" ")[0][0]}{el.full_name.split(" ")[1][0]}</span>
                                  </Avatar>
                                </Space>
                                <div>
                                  <Title level={4} style={{ color: '#3D3D3D' }}>{el.full_name}</Title>
                                  <Text className='text-ellipsis-one-line'>{el.venue}</Text>
                                </div>
                              </Space>
                              <div style={{ marginTop: '10px' }} className="display-flex justify-content">
                                <div style={{ display: 'grid' }}>
                                  <Text style={{ color: '#B9B9B9' }}>Degree</Text>
                                  <Text className='text-center'>{el.degree}</Text>
                                </div>
                                <div style={{ display: 'grid' }}>
                                  <Text style={{ color: '#B9B9B9' }}>Area of Expertise</Text>
                                  <Text className='text-center'>{el.area_of_expertise}</Text>
                                </div>
                              </div>
                              <Space size={10}>
                                <Space wrap size={10}>
                                  <svg style={{ marginTop: '5px' }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                                    <g id="email_black_24dp" opacity="0.5">
                                      <path id="Path_11416" data-name="Path 11416" d="M0,0H20V20H0Z" fill="none" />
                                      <path id="Path_11417" data-name="Path 11417" d="M17,4H3.667A1.664,1.664,0,0,0,2.008,5.667L2,15.667a1.672,1.672,0,0,0,1.667,1.667H17a1.672,1.672,0,0,0,1.667-1.667v-10A1.672,1.672,0,0,0,17,4Zm0,3.333L10.333,11.5,3.667,7.333V5.667l6.667,4.167L17,5.667Z" transform="translate(-0.333 -0.667)" />
                                    </g>
                                  </svg>
                                </Space>
                                <Text style={{ color: '#2973FE' }}>{el.email}</Text>
                              </Space>
                              <div>
                                <Space size={10} >
                                  <Space wrap size={10}>
                                    <svg style={{ marginTop: '5px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18">
                                      <g id="phone--filled" opacity="0.5">
                                        <path id="Path_11415" data-name="Path 11415" d="M12.742,13.395,14,12.135a1.232,1.232,0,0,1,1.316-.27l1.536.613a1.226,1.226,0,0,1,.765,1.125v2.812a1.221,1.221,0,0,1-1.283,1.209C5.576,16.956,3.4,7.843,2.994,4.356A1.221,1.221,0,0,1,4.215,3H6.932a1.215,1.215,0,0,1,1.125.765L8.67,5.3a1.215,1.215,0,0,1-.264,1.316l-1.26,1.26S7.871,12.787,12.742,13.395Z" transform="translate(-1.307 -1.312)" />
                                        <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" width="18" height="18" fill="none" />
                                      </g>
                                    </svg>
                                  </Space>
                                  <Text>{el.phone_number}</Text>
                                </Space>
                              </div>
                            </Card>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                </div>
            }
            {
              selectedMenu.key === 'Attendee' &&
                // <div className='conference'>
                //   <div className='conference-height'>
                //     <Row>
                //       {filteredAttendeeList && filteredAttendeeList.map(el =>
                //         <Col className='tile' style={{ padding: '0px 1% 0px 0px' }} xs={{ flex: '100%' }}
                //           sm={{ flex: '50%' }}
                //           md={{ flex: '50%' }}
                //           lg={{ flex: '25%' }}
                //           xl={{ flex: '25%' }}>
                //           <div className='attendee-body pointer'>
                //             <Card style={{ width: '100%', height: 'fit-content', borderRadius: '4px' }} onClick={() => onSelectCard('attendeeDetails' + '/' + el.attendee_id)}>

                //               <Space size={16}>
                //                 <Space wrap size={16}>
                //                   <Avatar
                //                     style={{
                //                       backgroundColor: setBg(),
                //                       verticalAlign: 'middle',
                //                     }}
                //                     size={55}
                //                   >
                //                     <span className='avatar-Text'>
                //                       {el.attendee_name.split(" ")[0][0]}{el.attendee_name.split(" ")[1][0]}
                //                     </span>
                //                   </Avatar>
                //                 </Space>
                //                 <div>
                //                   <Title level={4} style={{ color: '#3D3D3D' }}>{el.attendee_name}</Title>
                //                   <Text className='text-ellipsis-one-line'>{el.designation}</Text>
                //                 </div>
                //               </Space>
                //               <div style={{ marginTop: '10px' }} className="display-flex justify-content">
                //                 <div style={{ display: 'grid' }}>
                //                   <Text style={{ color: '#B9B9B9' }}>Attendee ID</Text>
                //                   <Text className='text-center'>{el.attendee_id}</Text>
                //                 </div>
                //                 <div style={{ display: 'grid' }}>
                //                   <Text style={{ color: '#B9B9B9' }}>Department</Text>
                //                   <Text className='text-center'>{el.department}</Text>
                //                 </div>
                //               </div>
                //               <Space size={10}>
                //                 <Space wrap size={10}>
                //                   <svg style={{ marginTop: '5px' }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                //                     <g id="email_black_24dp" opacity="0.5">
                //                       <path id="Path_11416" data-name="Path 11416" d="M0,0H20V20H0Z" fill="none" />
                //                       <path id="Path_11417" data-name="Path 11417" d="M17,4H3.667A1.664,1.664,0,0,0,2.008,5.667L2,15.667a1.672,1.672,0,0,0,1.667,1.667H17a1.672,1.672,0,0,0,1.667-1.667v-10A1.672,1.672,0,0,0,17,4Zm0,3.333L10.333,11.5,3.667,7.333V5.667l6.667,4.167L17,5.667Z" transform="translate(-0.333 -0.667)" />
                //                     </g>
                //                   </svg>
                //                 </Space>
                //                 <Text style={{ color: '#2973FE' }}>{el.attendee_email}</Text>
                //               </Space>
                //               <div>
                //                 <Space size={10} >
                //                   <Space wrap size={10}>
                //                     <svg style={{ marginTop: '5px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18">
                //                       <g id="phone--filled" opacity="0.5">
                //                         <path id="Path_11415" data-name="Path 11415" d="M12.742,13.395,14,12.135a1.232,1.232,0,0,1,1.316-.27l1.536.613a1.226,1.226,0,0,1,.765,1.125v2.812a1.221,1.221,0,0,1-1.283,1.209C5.576,16.956,3.4,7.843,2.994,4.356A1.221,1.221,0,0,1,4.215,3H6.932a1.215,1.215,0,0,1,1.125.765L8.67,5.3a1.215,1.215,0,0,1-.264,1.316l-1.26,1.26S7.871,12.787,12.742,13.395Z" transform="translate(-1.307 -1.312)" />
                //                         <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" width="18" height="18" fill="none" />
                //                       </g>
                //                     </svg>
                //                   </Space>
                //                   <Text>{el.mobile}</Text>
                //                 </Space>
                //               </div>

                //             </Card>
                //           </div>
                //         </Col>
                //       )}
                //     </Row>          
                //   </div>
                // </div>
                <ArcutisAttendee userDetails={userDetails}/>
            }
              {selectedMenu.key === 'Reports' && (
          <div style={{padding:"0 30px"}}>
          <Tabs activeKey={reportsTab} onChange={(value)=>setReportsTab(value)}>
            <TabPane tab="Reports" key="reports">
                <div className="conference">
                  {isMobile ? (
                    <>
                      <div style={{ padding: '5px', marginBottom: '20px' }} className="report-mobile-card">
                        <Row gutter={[10, 10]}>
                          {reportList?.tableData?.bodyContent.map((item, index) => (
                            <Col xs={24} sm={12} md={8} key={item.slNo}>
                              <Card
                                title={
                                  <div>
                                    <a
                                      style={{ color: '#235D9F', fontWeight: '400', padding: '0 15px' }}
                                      onClick={() => onReportSelect(item)}
                                    >
                                      {item?.reportName}
                                    </a>
                                  </div>
                                }
                                style={{ border: '0.5px solid #EAEAEA' }}
                                styles={{
                                  body: { padding: '0 10px 10px 10px' },
                                  head: { borderBottom: '1px solid #EAEAEA' },
                                }}
                                bordered={false}
                              >
                                <Divider style={{ margin: '0 0 5px 0', borderWidth: '2px' }} />
                                <Row gutter={[16, 16]}>
                                  <Col xs={7} sm={7} md={7}>
                                    <p style={{ margin: 0, color: '#5D6063' }}>Description&nbsp; </p>
                                  </Col>
                                  <Col xs={17} sm={17} md={17}>
                                    <Typography.Paragraph
                                      style={{ fontWeight: '500', marginBottom: 0 }}
                                      ellipsis={{
                                        rows: 2,
                                        expandable: 'collapsible',
                                        expanded: index === Number(currentReportIndex) && expanded ? true : false,
                                        onExpand: (_, info) => setCurrentStateReport(index, info.expanded),
                                        symbol: index === Number(currentReportIndex) && expanded ? <>See less</> : <>See more</>,
                                      }}
                                    >
                                      {item.description}
                                    </Typography.Paragraph>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </>
                  ) : (
                    <div className="reports-table">
                      <Table
                        pagination={false}
                        columns={reportList.tableData.headerContent}
                        dataSource={reportList.tableData.bodyContent}
                        bordered
                        scroll={{
                          y: 'calc(100vh - 270px)',
                        }}
                      />
                    </div>
                  )}
                </div>
            </TabPane>
            <TabPane tab="HCP Sales Trends" key="hcp_sales_trends">
              <div>
             {summaryData?.label && <Card style={{borderRadius:"10px",marginBottom:"20px"}}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "10px 0",
                              }}
                            >
                              <p className="sub-header-label">
                               {summaryData?.label}
                              </p>
                            </div>
                            <Divider style={{ margin: 0 }} />
                            <p className='sub-header'>{summaryData?.subHeader}</p>
                                <>
                                  <Row gutter={[16, 16]}>
                                    {summaryData?.values?.map(
                                      (prescriptionData, index) => (
                                        <Col
                                          key={index}
                                          xs={24}
                                          sm={12}
                                          md={8}
                                          lg={6}
                                        >
                                          <>
                                            <p className="labelSectionValueName">{prescriptionData?.label}</p>
                                            <p className="sectionValueName">{prescriptionData?.value || '-'}</p>
                                            </>
                                        </Col>
                                      )
                                    )}
                                  </Row>
                                </>

                          </Card>}
              <Card title="Prescribing Trend" style={{ width: '100%', borderRadius: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>

                        <Select
                        placeholder="Select HCP Name"
                        size="large"
                        onChange={handleHcpChangeData}
                        value={hcpData?.npi_id}
                        style={{ minWidth: 120, marginRight: '20px' }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().includes(input.toLowerCase())
                        }
                      >
                        {hcpSalesTrendsData?.hcpIds?.map((idData)=>{
                          return(
                            <Option value={idData?.key}>{idData?.value}</Option>
                          )
                        })}
                      </Select>
                       <Select
                        placeholder="Select Type"
                        size="large"
                        value={hcpData?.type}
                        style={{ width: 200 ,marginRight: '20px'}}
                        showSearch
                        optionFilterProp="children"
                        onChange={(type)=>{handleHcpTypeChange(type)}}  
                        filterOption={(input, option) =>
                          option.children.toLowerCase().includes(input.toLowerCase())
                        }
                      >
                        <Option value="portfolio">Portfolio</Option>
                        <Option value="products">Products</Option>
                      </Select>

                      <Select
                      mode="multiple"  
                      size="large"
                      disabled={!hcpData?.type}
                      placeholder="Select Portfolios"  
                      value={hcpData?.portfolios}
                      onChange={(values)=>{
                        setHcpData({...hcpData,portfolios:values})
                        let payload = {portfolio:values,npi_id:hcpData?.npi_id,type:hcpData?.type}
                        if(hcpData?.type === 'products'){
                          fetchProductsList(payload);
                        }else{
                          fetchPrescriptionList(payload);
                        }
                        }}   
                      style={{ minWidth: 120, marginRight: '20px' }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {hcpSalesTrendsData?.portfolios?.map((productData) => (
                        <Option key={productData?.key} value={productData?.key}>
                          {productData?.value}
                        </Option>
                      ))}
                    </Select>

                     {hcpData?.type==='products'&& hcpData?.portfolios?.length > 0 &&  <Select
                      mode="multiple"  
                      size="large"
                      placeholder="Select Products"  
                      onChange={(values)=>{
                        setHcpData({...hcpData,product:values})
                        let payload = {product:values,npi_id:hcpData?.npi_id,type:hcpData?.type,portfolio:hcpData?.portfolios}
                          fetchPrescriptionList(payload);
                        }}   
                      style={{ minWidth: 120, marginRight: '20px' }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {hcpSalesTrendsData?.products?.map((productData) => (
                        <Option key={productData?.key} value={productData?.key}>
                          {productData?.value}
                        </Option>
                      ))}
                    </Select>}
                        </div>
                         {prescriptions?.[0] ? <ReactEcharts  key={JSON.stringify(prescriptions[0])}  option={prescriptions?.[0]} style={{ height: '300px', width: '100%' }} />:null}
                        </Card>
              </div>
            </TabPane>
          </Tabs>
          </div>
              )}
            { selectedMenu.key === 'KOL' &&
               <KOL userDetails={userDetails}/>
            }
            {selectedMenu.key ==='Surveys' &&
              <Survey userDetails={userDetails}/>
            }
          </div>
        </div>
      </div>
    </>
  )
}