import React from "react";
import { Card, Tabs } from "antd";
import UserManagement from "./UserManagement/UserManagement";
import RoleManagement from "./RoleManagement/RoleManagement";
import BreadcrumbList from "../../Shared/Components/Breadcrumb/Breadcrumb";
import './AdminMain.scss';

const { TabPane } = Tabs;

export default function AdminMain(props) {
  return (
    <div className="admin-main">
      <Card
        style={{ width: "100%", height: "fit-content" }}
        bodyStyle={{ padding: "10px" }}
      >
        <BreadcrumbList active={[{ label: "Admin Dashboard", link: "" }]} />
      </Card>
      <Card bordered={false} style={{ margin:'10px' }} className="admin-body-card">
        <Tabs defaultActiveKey="userManagement" className="admin-body-card">
          <TabPane tab="User Management" key="userManagement">
            <UserManagement />
          </TabPane>
          <TabPane tab="Role Management" key="roleManagement">
            <RoleManagement />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
}
