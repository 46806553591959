import React, { useEffect, useState } from 'react';
import './Header.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { sessionService } from '../../Utils/SessionService';
import SVGIcon from '../SVGIcon/SVGIcon';
import { VARIABLES } from '../../../Environments/constants';
import { getDecryptSession, titleCase, updateFormConfig } from '../../Utils/CommonUtils';
import {Row, Col, Button, Dropdown, Menu, Modal } from 'antd';
import { LogoutOutlined, KeyOutlined, UserOutlined, BellFilled ,SettingOutlined} from '@ant-design/icons';
import FormBuilder from '../Ant-FormBuilder/Ant-FormBuilder';
import formConfig from '../../../Assets/json/change-password.json'
import { ServiceUtils } from '../../Utils/ServiceUtils';
import { alertService } from '../../Utils/ToasterUtils';
import Icon from '@ant-design/icons/lib/components/Icon';
import AppIcon from '../../../Assets/images/app-logo.png'



export default function Header({ logoUrl, selectedMenu }) {

  const [changePasswordVisible, setChangePasswordVisible] = React.useState(false);
  const [config, setConfig] = React.useState(formConfig);
  const location = useLocation();
  const [credential, setCredential] = React.useState({
    user_name: "",
    password: "",
    new_password: "",
  });
  const [logoutFlag, setLogoutFlag] = useState(false);


  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload());
  })


  const navigate = useNavigate();
  const session = localStorage.getItem("arcutis-user");
  const userDetails = getDecryptSession(session);

  const logout = () => {
    // navigate('/logout');
    // setLogoutFlag(true);
    logoutSession()
  }

  const logoutSession = () => {
    ServiceUtils.getRequest('logout').then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          navigate('/logout');
          setLogoutFlag(true);
        } 
        else if(response.status === 401 ){
          navigate('/logout');
          setLogoutFlag(true);
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching data')
        // console.log(error)
      }
    );
  };

  const handleClick = (el) => {
    if (el.key === 'logout') logoutSession();
    else if (el.key === 'conf') navigate('/admin');
    else if (el.key === 'dashboard') navigate('/dashboard');
    else if (el.key === 'change_password') setChangePasswordVisible(true);
    else if (el.key === 'settings') navigate('/admin/settings');
    // navigate('/dashboard/change_password');
  }

  const handleBeforeUnload = () => {
    // console.log(location.pathname);
    // console.log(logoutFlag);
    if(!logoutFlag){
      if(location.pathname==="/home"){
        // localStorage.setItem('history', location.pathname);
        // if(location.pathname==="")
        //
      }
      else{
        localStorage.setItem('history', location.pathname);
      }
    }
    else{
      localStorage.clear();
    }
  };

  const handleOk = () => {
    setChangePasswordVisible(false);
  };

  const handleCancel = () => {
    setChangePasswordVisible(false);
  };

  const handleChange = (action) => {
    if (action?.type === "onChange") {
      let tempConfig = [...config];
      tempConfig = updateFormConfig(tempConfig, "new_password", { validateStatus: "", help: "" });
      tempConfig = updateFormConfig(tempConfig, "user_name", { validateStatus: "", help: "" });
      setConfig(tempConfig);
      setCredential({ ...credential, ...action.payload.data });
    }
    if (action?.type === "submit") {
      let curr_password = action.payload.data.password
      let new_password = action.payload.data.new_password
      let tempConfig = [...config];
      if (curr_password === new_password) {
        tempConfig = updateFormConfig(tempConfig, "new_password", { validateStatus: "error", help: "Current password and new password can't be same" })
        setConfig(tempConfig);
      } else {
        ServiceUtils.postRequest("change_password", credential).then(
          (response) => {
            if (response?.status === 200) {
              if (response?.data?.status.toLowerCase() === "success") {
                alertService.success(
                  response?.data?.message || "Successfully changed password"
                );
                navigate("/logout");
              } else if (response?.data?.status.toLowerCase() === "failure") {
                alertService.error(
                  response?.data?.message || "Failed"
                );
                let tempConfig = [...config];
                let responseData = response?.data?.message;
                if (responseData?.toLowerCase().includes("username")) {
                  tempConfig = updateFormConfig(tempConfig, "user_name", { validateStatus: "error", help: "Invalid Credentials" })
                }
                setConfig(tempConfig);
              }
            }
            else if(response.status === 401 ){
              navigate('/logout');
            }
          },
          (error) => {
            alertService.error("Error while changing the password.");
          }
        );
      }
    }
  }

  const menuUser = (
    <Menu
      onClick={handleClick}
      items={
        [
        {
          label: 'Log-out',
          key: 'logout',
          icon: <LogoutOutlined />,
        }
      ]}
    />
  );

  const menu = (
    <Menu
      onClick={handleClick}
      items={
        [
        // {
        //   label: 'Change Password',
        //   key: 'change_password',
        //   icon: <KeyOutlined />,
        // },
         {
          label: 'Settings',
          key: 'settings',
          icon: <SettingOutlined />,
        },
        {
          label: 'Log-out',
          key: 'logout',
          icon: <LogoutOutlined />,
        }
      ]}
    />
  );

  const goBack = () => {
    localStorage.setItem('history', '/home');
    navigate('/home')
  }


  return (
    <div className="header-main" data-test="header-container">
      <div className="" title="Go to Home" >
        {/* <div style={{ color: 'white', marginTop: '5px', fontSize: '17px', fontWeight: 500, cursor: 'pointer' }} onClick={() => { navigate('/home'); sessionService.setSession('true', 'displayDashboard') }}>KOL Tracking System</div> */}
        <img className="pointer" onClick={() => goBack()}  style={{width:'60%'}} src={AppIcon} />
      </div>

      <div className="right-menu">
        <Row style={{flexFlow:"nowrap"}}>
          <Col><div className="bell-notification mailbox">
          {/* <SVGIcon icon="bell" /> */}
          <BellFilled />
          <div class="notification" role="status">12</div>
      </div></Col>
          <Col><Dropdown overlay={ userDetails?.admin_access ? menu : menuUser} trigger={['click']}>
          <Button type='text' className='user-info-btn'>
            <div className="user-info">
              <div className="info">
                <div className="profile-pic">
                  {/* <SVGIcon icon="user" /> */}
                  <UserOutlined />
                </div>
                <div className="text">
                  <div className="name">{userDetails?.user_name}</div>
                  {/* <div className="role">
                    {userDetails?.[VARIABLES.userRole]?.map(el => titleCase(el.replace('_', ' '))).join(', ')}
                  </div> */}
                </div>
              </div>
            </div>
          </Button>
        </Dropdown></Col>
        </Row>
      
        
      </div>

      <Modal className="primary-modal" centered={true} title="Change Password" visible={changePasswordVisible} onOk={handleOk} onCancel={handleCancel}
        footer={[
          <Button key="submit" htmlType="submit" type="primary" value="submit" form="password_change_">
            Change
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>
        ]} >
        <FormBuilder
          className='change-password'
          config={config}
          id="password_change_"
          onAction={handleChange}
          data={credential}
        />
      </Modal>
    </div>
  );
}