import React, { useEffect, useRef, useState } from "react";
import "./TouchPoints.scss";
import {
  Row,
  Col,
  Card,
  Button,
  Space,
  Avatar,
  Divider,
  Layout,
  Checkbox,
  Tooltip,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { ServiceUtils } from "../../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../../Shared/Utils/ToasterUtils";
import { getDecryptSession } from "../../../../../Shared/Utils/CommonUtils";
import {
  MailOutlined,
  PhoneOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";

export default function TouchPoints({ ...props }) {
  const [touchPointDetails, setTouchPointDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const { id } = useParams();
  const { attendee_id } = useParams();
  const navigate = useNavigate();
  const dynamicCardColors = [
    "#EBF3FA",
    "#FFFCEB",
    "#F0F2F3",
    "#FAE9F2",
    "#FFF0F0",
    "#EEEFED",
  ];

  useEffect(() => {
    const session = localStorage.getItem("arcutis-user");
    const userDetails = getDecryptSession(session);
    setUserDetails(userDetails);
    fetchTouchPointDetails();
  }, []);


  const fetchTouchPointDetails = () => {
    let payload = {
      profileId: id,
      sectionId:Number(props?.menuId)
    }
    ServiceUtils.postRequest('kolProfile', payload).then(
      (response) => {
        if (response?.status === 200) {
          setTouchPointDetails({...response.data?.data});
        } 
        else if(response?.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response?.data?.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching user role.')
        console.log(error)
      }
    );
  };

  const formatEmailsId = (data) => {
      const validEmail = data.filter(person => person.email !== null);
      const emails = validEmail.map(person => person.email).join(',')
      openOutlook(emails)
  }

  const openOutlook=(emails)=>{
      const mailtoLink = `mailto:${emails}`;
      window.location.href = mailtoLink;
  }
  return (
    <>
      <div
        className="touch-points-root"
        data-test="touch-points-container"
      >
        <Layout>
          <Content>
            <div className="list-container">
              <div className="list-container">
                <p className="headerLabel">Touch Points {touchPointDetails?.user_name && `- ${touchPointDetails?.user_name}`}</p>
                {touchPointDetails?.relationShipPoints?.length > 0 &&<Card
                  className="touchPoint"
                  style={{
                    padding: 0,
                    maxheight: "80vh",
                    backgroundColor: "#F7F7F7",
                    borderRadius: "20px",
                  }}
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ flex: 1, padding: "16px" }}>
                       <Card style={{ padding: "20px", borderRadius: "20px" }}>
                        <div>
                         {<Card>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "10px",
                              }}
                            >
                              <p className="sub-header-label"></p>
                              <Space size="middle">
                                <span className="headerLink">
                                  <TeamOutlined /> Group Chat
                                </span>
                                <span onClick={()=>formatEmailsId(touchPointDetails?.relationShipPoints)} className="headerLink ml-3">
                                  <MailOutlined /> Group Email
                                </span>
                              </Space>
                            </div>
                            <Divider style={{ margin: 0 }} />
                            <div>
                              <Row
                                style={{ margin: "0 20px" }}
                                gutter={[16, 16]}
                                justify="start"
                              >
                                {touchPointDetails?.relationShipPoints?.map(
                                  (arcutisEngagements) => {
                                    return (
                                      <Col xs={24} sm={12} md={8} lg={8} xl={4}>
                                        <Card
                                          style={{
                                            padding: "10px 20px",
                                            margin: "10px 3px",
                                            backgroundColor: `${
                                              dynamicCardColors[
                                                Math.floor(Math.random() * 6)
                                              ]
                                            }`,
                                          }}
                                        >
                                          <Space
                                            wrap
                                            size={16}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {arcutisEngagements?.profile_picture !==
                                              "" &&
                                            arcutisEngagements?.profile_picture !==
                                              null ? (
                                              <Avatar
                                                size={120}
                                                src={
                                                  arcutisEngagements?.profile_picture
                                                }
                                              />
                                            ) : (
                                              <Avatar
                                                style={{
                                                  verticalAlign: "middle",
                                                }}
                                                size={120}
                                              >
                                                <UserOutlined
                                                  style={{ fontSize: "100px" }}
                                                />
                                              </Avatar>
                                            )}
                                          </Space>
                                          <p className="arcutisName">
                                            {arcutisEngagements?.name || '-'}
                                          </p>
                                          <p className="arcutisRole">
                                            {arcutisEngagements?.role || '-'}
                                          </p>
                                          <Divider style={{ margin: 0 }} />
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              margin: "10px 0",
                                            }}
                                          >
                                            <Button
                                              type="primary"
                                              className="arcutisButtons"
                                            >
                                              <TeamOutlined />
                                            </Button>
                                            <Button
                                              type="primary"
                                              disabled={arcutisEngagements?.email === null}
                                              className="arcutisButtons"
                                              onClick={()=>openOutlook(arcutisEngagements?.email)} 
                                            >
                                              <MailOutlined />
                                            </Button>
                                            <Tooltip title={arcutisEngagements?.phone}>
                                            <Button disabled={arcutisEngagements?.phone === null} type="primary" className="arcutisButtons">
                                                <PhoneOutlined />
                                            </Button>
                                            </Tooltip>
                                          </div>
                                        </Card>
                                      </Col>
                                    );
                                  }
                                )}
                              </Row>
                            </div>
                          </Card>}
                        </div>
                      </Card>
                    </div>
                  </div>
                </Card>}
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    </>
  );
}
