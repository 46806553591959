import { useEffect, useState } from 'react';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { auditTime, map, distinctUntilChanged } from 'rxjs/operators';

// export interface WindowSize {
//   size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
//   height: number;
//   width: number;
// }
// export interface Breakpoints {
//   [unit: string]: number
// }
const breakPoints = {
  xxl: 1400,  // XX-Large devices (larger desktops) (≥1400px) // No media query since the xxl breakpoint has no upper bound on its width
  xl: 1200,   // X-Large devices (large desktops, less than 1400px) (≥1200px)
  lg: 992,    // Large devices (desktops, less than 1200px) (≥992px)
  md: 768,    // Medium devices (tablets, less than 992px) (≥768px)
  sm: 576,    // Small devices (landscape phones, less than 768px) (≥576px)
  xs: 0,      // X-Small devices (portrait phones, less than 576px) (<576px)
};
export const useSharedState = () => {
  let subscription;
  const windowSizeChanged = new BehaviorSubject(Object.keys(breakPoints).find(k => window.outerWidth >= breakPoints[k]) || "md")
  const [value, setValue] = useState(Object.keys(breakPoints).find(k => window.outerWidth >= breakPoints[k]) || "md");
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    subscription = fromEvent(window, 'resize').pipe(
      auditTime(100),
      map(event => event.currentTarget),
      map(windowEvent => (Object.keys(breakPoints).find(k => windowEvent.outerWidth >= breakPoints[k])),
        distinctUntilChanged())
    )
      .subscribe((windowSize) => {
        windowSizeChanged.next(windowSize || "md");
        setValue(windowSize || "md");

      });
    return () => subscription.unsubscribe();
  });
  return value;
};
