import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Select, Space, Typography } from "antd";
import {
  EditOutlined,
  LeftOutlined,
  CaretDownOutlined,
  RightOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import useWindowSize from "../../../Shared/Utils/windowResizeUtils";
import MenuItem from "antd/es/menu/MenuItem";
import "./Agenda.scss";
import { useNavigate } from "react-router-dom";

const Agenda = ({ ...props }) => {
  const { Title } = Typography;
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const [agendaData, setAgendadata] = useState({});
  useEffect(() => {
    let tempEventData = groupEventsByDate(props?.events);
    setAgendadata({ ...tempEventData });
  }, [props?.events, props?.currentDate?.current]);
  const handleChange = (action) => {
    props.handleAgendaMonthChange(action);
  };
  const goToToday = () => {
    props.handleNavigate("TODAY", props?.date);
    props.updateView("day");
  };

  const handleView = (event) => {
    props.updateView(event, props?.date);
  };
  const handleMonthChange = (month) => {
    const newDate = new Date(
      props?.currentDate?.current.getFullYear(),
      month,
      1
    );
    props.handleNavigate("DATE", newDate);
    props.updateView("month");
  };
  const formatToTime = (date) => {
    const formattedTime12Hour = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime12Hour;
  };
  const goToEdit = (meetingData) => {
    props.onCalenderSelect(meetingData);
  };
  const dateAndDayFormatter = (date) => {
    const dateObject = new Date(date);
    const today = new Date();
    const tomorrow = new Date();
    const yesterday = new Date();
    tomorrow.setDate(today.getDate() + 1);
    yesterday.setDate(today.getDate() - 1);
    const dayOfMonth = dateObject.getDate();
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayOfWeek = daysOfWeek[dateObject.getDay()];
    if (dateObject.toDateString() === today.toDateString()) {
      return `${dayOfWeek} ${dayOfMonth} | Today`;
    } else if (dateObject.toDateString() === tomorrow.toDateString()) {
      return `${dayOfWeek} ${dayOfMonth} | Tomorrow`;
    } else if (dateObject.toDateString() === yesterday.toDateString()) {
      return `${dayOfWeek} ${dayOfMonth} | Yesterday`;
    } else {
      return `${dayOfWeek} ${dayOfMonth}`;
    }
  };

  function groupEventsByDate(events) {
    let tempData = [...events];

    tempData.push({
      id: 1400058,
      title: "[Arcutis] SDPA Jameka McElroy-Brooklyn and Arcutis 1pm PST",
      start: new Date("2024-08-21T07:30:00.000Z"),
      end: new Date("2024-08-21T08:20:00.000Z"),
      backgroundColor: "#C6E0B4",
      color: "#000000",
      meeting_host: "Garineh Galian ",
      meeting_timings: "from 24 Jun 2024 1:00 PM to 1:50 PM",
    });

    const selectedMonth = new Date(props?.currentDate?.current).getMonth();
    const selectedYear = new Date(props?.currentDate?.current).getFullYear();
    const sortedSchedule = tempData.sort(
      (a, b) => new Date(a.start) - new Date(b.start)
    );
    const groupedSchedule = sortedSchedule.reduce((groups, event) => {
      const eventDate = new Date(event.start);
      const eventMonth = eventDate.getMonth();
      const eventYear = eventDate.getFullYear();
      if (eventMonth === selectedMonth && eventYear === selectedYear) {
        const dateString = eventDate.toISOString().split("T")[0];
        if (!groups[dateString]) {
          groups[dateString] = [];
        }
        groups[dateString].push(event);
      }

      return groups;
    }, {});

    return groupedSchedule;
  }

  return (
    <div className="agendaContainer">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Title
            level={5}
            style={{ paddingRight: "5px", margin: "0 10px 0 0" }}
            onClick={goToToday}
          >
            <CalendarOutlined style={{ marginRight: "10px" }} />
            Today
          </Title>
          <Space>
            <LeftOutlined
              style={{ fontSize: "16px" }}
              onClick={() => handleChange("Prev")}
              />
            <RightOutlined
              style={{ fontSize: "16px" }}
              onClick={() => handleChange("Next")}
            />
          </Space>
          {/* <Title style={{fontWeight:"700",margin:"0 0 0 10px"}} level={5}>{props?.label}</Title> */}
          <span className="rbc-toolbar-label">
            <Select
              suffixIcon={<CaretDownOutlined style={{ color: "black" }} />}
              onChange={handleMonthChange}
              className="no-border"
              value={new Date(props?.currentDate?.current).getMonth()}
            >
              {Array.from({ length: 12 }).map((_, i) => (
                <MenuItem key={i} value={i}>
                  {new Date(2024, i, 1).toLocaleString("default", {
                    month: "long",
                  })}{" "}
                  {/* Replace with dynamic year if needed */}
                </MenuItem>
              ))}
            </Select>
            {new Date(props?.currentDate?.current).getFullYear() ||
              new Date().getFullYear()}
          </span>
        </div>
        <Select
          onChange={handleView}
          value={props?.view?.current}
          style={{ width: isMobile ? 60 : 120 }}
        >
          <MenuItem value="month">Month</MenuItem>
          <MenuItem value="week">Week</MenuItem>
          <MenuItem value="day">Day</MenuItem>
          <MenuItem value="agenda">Agenda</MenuItem>
        </Select>
      </div>
      {Object.keys(agendaData)?.length > 0 ? (
        Object.keys(agendaData)?.map((agendaDate) => {
          return (
            <>
              <div className="agendaDate" style={{color:new Date().toDateString() === new Date(agendaDate).toDateString()  && '#235D9F'}}>{dateAndDayFormatter(agendaDate)}</div>
              {agendaData?.[agendaDate]?.map((event) => {
                return (
                  <Card
                    style={{
                      margin: "10px 0",
                      borderLeft: `7px solid ${event?.backgroundColor}`,
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "4px",
                      borderTopRightRadius: "0",
                      borderBottomRightRadius: "0",
                    }}
                  >
                    <Row>
                      <Col span={12} style={{ alignSelf: "center" }}>
                        <div style={{ display: "flex" }}>
                          <div
                            className="agendaText"
                            style={{ minWidth: "8rem" }}
                          >
                            {formatToTime(event?.start)}-
                            {formatToTime(event?.end)}
                          </div>
                          <div
                            className="agendaText"
                            style={{ marginLeft: "4rem" }}
                          >
                            {event?.title}
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        {props.userDetails?.admin_access && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              className="btn-border"
                              onClick={() => goToEdit(event)}
                            >
                              <EditOutlined />
                              Edit
                            </Button>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Card>
                );
              })}
            </>
          );
        })
      ) : (
        <span className="noEvent">There are no events in this range.</span>
      )}
    </div>
  );
};

export default Agenda;
