import { Card, Table, Col, Row, Typography, Select,Button } from "antd";
import { React, useState, useEffect } from "react";
import BreadcrumbList from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useSharedState } from "../../../../Shared/Services/WindowSizeService";
import { ServiceUtils } from '../../../../Shared/Utils/ServiceUtils';
import { alertService } from '../../../../Shared/Utils/ToasterUtils';
import "./SyncMeeting.scss";
export default function SyncMeeting({ ...props }) {
  const windowSize$ = useSharedState();
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const id = props?.id;
  const syncMeetingData=props?.syncMeetingData;
  // console.log(props?.syncMeetingData);
  const [syncInputs,setSyncInputs] = useState(props?.syncPayload ? props?.syncPayload : {})
  const [selectionType, setSelectionType] = useState("checkbox");
  const [conferenceList, setConferenceList] = useState();
  const [selectedConference, setSelectedConference] = useState();
  const [selectedMeetings, setSelectedMeetings] = useState();
  const [attendeeList, setAttendeeList] = useState({
    tableData: {
        headerContent: [],
        bodyContent: []
    }
  });
  const [selectUserModalFlag, setSelectUserModalFlag] = useState(false);

  useEffect(()=>{
    getStagingDetails();
  },[])
  
  const columns = [
    {
      title: "Meeting Name",
      dataIndex: "meetingName",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Meeting Venue",
      dataIndex: "meetingVenue",
    },
    {
      title: "Meeting Host",
      dataIndex: "meetingHost",
    },
  ];
  const data = [
    {
      key: "1",
      meetingName: "John Brown",
      meetingVenue: 32,
      meetingHost: "New York No. 1 Lake Park",
    },
    {
      key: "2",
      meetingName: "Jim Green",
      meetingVenue: 42,
      meetingHost: "London No. 1 Lake Park",
    },
    {
      key: "3",
      meetingName: "Joe Black",
      meetingVenue: 32,
      meetingHost: "Sydney No. 1 Lake Park",
    },
    {
      key: "4",
      meetingName: "Disabled User",
      meetingVenue: 99,
      meetingHost: "Sydney No. 1 Lake Park",
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedMeetings(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const filter = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  const handleConferenceChange = (value) => {
    console.log(value);
    setSelectedConference(value);
  };

  const getStagingDetails = () => {
    ServiceUtils.getRequest('getStagingDetails').then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          // setDataObj(response.data.data)
          console.log(response.data);
          setConferenceList(response.data?.data?.conferenceList)
          setAttendeeList(response.data?.data?.tableData);
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching data')
        console.log(error)
      }
    );
  };

  const validateInputs = () => {
    let flag = true;
    if(selectedConference && selectedMeetings?.length){
      flag= true;
    }
    else if(selectedConference){
      flag=false;
      alertService.error('Please Select atleast one Meeting')
    }
    else if(selectedMeetings?.length){
      flag=false;
      alertService.error('Please Select Conference')
    }
    else{
      flag=false;
      alertService.error('Please Select Conference and Meeting')
    }
    return flag;
  }

  const SaveData = () => {
    if(validateInputs()){
      let payload = {
        "selectedConference": selectedConference,
        "selectedMeetings":selectedMeetings
      }
      // console.log(payload)
      ServiceUtils.postRequest('updateStagingDetails', payload).then(
        (response) => {
          if (response.status === 200 && response?.data.status) {
            // console.log(response?.data);
            // localStorage.setItem('history', '/home');
            // navigate("/home")
            window.location.reload();
          } 
          else if(response.status === 401 ){
            navigate('/logout');
          }
          else {
            alertService.error(response.data.message)
          }
        },
        (error) => {
          alertService.error('Error while fetching data')
          // console.log(error)
        }
      );
    }
    // else{
    //   alertService.error('Please Select Conference and Meeting')
    // }
  }

  const closeSync = () => {
    props.setCloseSyncFlag(false);
  }

  const onClickROw = (record, selected, selectedRows, nativeEvent) => {
    console.log(record, selected,selectedRows,nativeEvent)
  }
  return (
    <>
      <div className="sync-page" data-test="profile-container">
        <Card
          style={{ width: "100%", height: "fit-content" }}
          bodyStyle={{ padding: "10px" }}
        >
          <BreadcrumbList active={[{ label: "Sync Meetings", link: "" }]} />
          {/* <BreadcrumbList active={[{ label: 'Meeting List ', link: '/home/meetingDetails/' + id }, { label: ' ', link: '' }, { label: ' Meeting Details', link: '' }]} /> */}
        </Card>
        <div className="list-container">
          <Card className="engagement-card">
            <Row gutter={[16, 16]}>
              <Col
                className={classNames("", {
                  "class-33":
                    windowSize$ === "lg" ||
                    windowSize$ === "xl" ||
                    windowSize$ === "xxl" ||
                    windowSize$ === "md",
                  "width-100": windowSize$ === "sm",
                })}
              >
                <div style={{paddingLeft:'5px'}}>
                  <span style={{"fontWeight": 500}}>Total meetings : {syncMeetingData?.total_meetings}</span><br/>
                  <span style={{"fontWeight": 500}}>New Meetings : {syncMeetingData?.new_meetings}</span><br/>
                  <span style={{"fontWeight": 500}}>Updated Meetings : {syncMeetingData?.updated_meetings}</span>
                </div>
              </Col>
              <Col
                className={classNames("", {
                  "class-33":
                    windowSize$ === "lg" ||
                    windowSize$ === "xl" ||
                    windowSize$ === "xxl",
                  "width-100": windowSize$ === "sm",
                })}
              ></Col>
              <Col
                className={classNames("", {
                  "class-33":
                    windowSize$ === "lg" ||
                    windowSize$ === "xl" ||
                    windowSize$ === "xxl",
                  "width-100":
                    windowSize$ === "sm" ||
                    windowSize$ === "md" ||
                    windowSize$ === "xs",
                })}
                style={{ marginBottom: "18px" }}
              >
                <div>
                  <Title level={5}>Conference List</Title>
                  <Select
                    // mode="multiple"
                    // defaultValue={attendeeFilterType}
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    placeholder="Please select"
                    onChange={handleConferenceChange}
                    options={conferenceList?.length && conferenceList}
                    filterOption={filter}
                    value={selectedConference}
                    // maxTagCount="responsive"
                  />
                </div>
              </Col>
            </Row>
            <div 
            style={{minHeight: "calc(100vh - 290px)", overflow: "auto"}}
            >
                <Table
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                  }}
                  columns={attendeeList?.headerContent}
                  dataSource={attendeeList?.bodyContent}
                  bordered
                  pagination={false}
                  scroll={{
                    y: "calc(100vh - 370px)",
                  }}
                  style={{scrollbarColor:"auto",border:'none'}}
                  // onSelect={onClickROw}
                />
            </div>
            <div style={{ marginTop: 24 }}>
                  <div className="submit-block">
                    <div style={{ paddingRight: "10px" }}>
                      <Button
                        className="edit-meet-button"
                        type="primary"
                        onClick={() =>
                          SaveData()
                          // console.log("test")
                        }
                      >
                        Save
                      </Button>
                    </div>
                    <Button
                      onClick={()=>{
                        // cancelAddEdit
                        // console.log("test")
                        closeSync()}
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
          </Card>
        </div>
      </div>
    </>
  );
}
