import React, { useEffect, useRef, useState } from "react";
import "./PrescriptionBehaviour.scss";
import {
  Row,
  Col,
  Card,
  Button,
  Space,
  Avatar,
  Divider,
  Layout,
  Checkbox,
  Select,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { ServiceUtils } from "../../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../../Shared/Utils/ToasterUtils";
import { getDecryptSession } from "../../../../../Shared/Utils/CommonUtils";
import {
  MailOutlined,
  PhoneOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import ReactEcharts from 'echarts-for-react';

export default function PrescriptionBehaviour({ ...props }) {
let prescrptionBehviourData ={
    summaryDetails:{
        label:"Summary",
        subHeader:"Zoryve Commercial TRx %",
        values:[
        {label:"Zoryve 13WK TRx",value:"33.44"},
        {label:"Zoryve 13WK NRx",value:"23.49"},
        {label:"PDRP Flag",value:"Yes"},
        {label:"PDRP Effective Date",value:"Apr 21st 2024"}]
    }
    }
  const [prescriptionBehaviourDetails, setPrescriptionBehaviourDetails] = useState({...prescrptionBehviourData});
  const [userDetails, setUserDetails] = useState({});
  const { id } = useParams();
  const { attendee_id } = useParams();
  const navigate = useNavigate();
  const { Option } = Select;

  useEffect(() => {
    const session = localStorage.getItem("arcutis-user");
    const userDetails = getDecryptSession(session);
    setUserDetails(userDetails);
  }, []);

  const fetchProfileDetails = () => {
    let payload = {
      profileId: id,
    };
    ServiceUtils.postRequest("profileDetails", payload).then(
      (response) => {
        if (response?.status === 200 && response?.data?.status) {
          setPrescriptionBehaviourDetails(response.data?.data);
        } else if (response?.status === 401) {
          navigate("/logout");
        } else {
          alertService.error(response?.data?.message);
        }
      },
      (error) => {
        alertService.error("Error while fetching user role.");
        console.log(error);
      }
    );
  };

  const fetchAttendeeDetails = () => {
    let payload = {
      attendee_id: attendee_id,
    };
    ServiceUtils.postRequest("attendeesInfo", payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          setPrescriptionBehaviourDetails(response.data.data);
        } else if (response.status === 401) {
          navigate("/logout");
        } else {
          alertService.error(response.data.message);
        }
      },
      (error) => {
        alertService.error("Error while fetching user role.");
        console.log(error);
      }
    );
  };


  const mapTheSectionValue = (sectionValues) => {
    console.log(sectionValues, "sectionValues");
    // if(sectionValues)
    let htmlContent = <></>;
    if (sectionValues?.type === "checkbox") {
      htmlContent = (
        <>
          <Checkbox checked={sectionValues?.value} className="sectionValueName">
            {sectionValues?.label}
          </Checkbox>
        </>
      );
    } else if (sectionValues?.type === "label") {
      htmlContent = (
        <>
          <p className="labelSectionValueName">{sectionValues?.label}</p>
          <p className="sectionValueName">{sectionValues?.value}</p>
        </>
      );
    }
    return htmlContent;
  };

  const getOption = () => ({
    title: {
      text: '',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    legend: {
      data: ['Zoryve_NRX', 'Zoryve_TRX'],
      bottom: '10px', 
      left: '10%',
    },
    xAxis: {
      type: 'category',
      data: ['01 Aug', '10 Aug', '30 Aug', '10 Sept', '20 Sept', '30 Sept', '10 Dec', '20 Dec', '30 Dec'],
    },
    yAxis: {
      type: 'value',
      name: 'Prescriptions',
      min: 0,
      max: 10,
    },
    series: [
      {
        name: 'Zoryve_NRX',
        type: 'line',
        data: [2, 9.34, 5, 7, 3, 6, 9, 8, 10],
        smooth: false,
        lineStyle: {
          color: '#0057D9',
        },
        itemStyle: {
          color: '#0057D9',
        },
      },
      {
        name: 'Zoryve_TRX',
        type: 'line',
        data: [1, 6, 4, 5, 2, 3, 8, 6, 9],
        smooth: false,
        lineStyle: {
          color: '#00B200',
        },
        itemStyle: {
          color: '#00B200',
        },
      },
    ],
  });
  return (
    <>
      <div
        className="prescription-behaviour-root"
        data-test="prescription-behaviour-container"
      >
        <Layout>
          <Content>
            <div className="list-container">
              <div className="list-container">
                <p className="headerLabel">Prescription Behaviour</p>
                <Card
                  className="profileCard"
                  style={{
                    padding: 0,
                    maxheight: "80vh",
                    backgroundColor: "#F7F7F7",
                    borderRadius: "15px",
                  }}
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                        <div>
                          <Card style={{borderRadius:"10px",marginBottom:"20px"}}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "10px 0",
                              }}
                            >
                              <p className="sub-header-label">
                               {prescrptionBehviourData?.summaryDetails?.label}
                              </p>
                            </div>
                            <Divider style={{ margin: 0 }} />
                            <p className='sub-header'>{prescrptionBehviourData?.summaryDetails?.subHeader}</p>
                                <>
                                  <Row gutter={[16, 16]}>
                                    {prescrptionBehviourData?.summaryDetails?.values?.map(
                                      (prescriptionData, index) => (
                                        <Col
                                          key={index}
                                          xs={24}
                                          sm={12}
                                          md={8}
                                          lg={6}
                                        >
                                          <>
                                            <p className="labelSectionValueName">{prescriptionData?.label}</p>
                                            <p className="sectionValueName">{prescriptionData?.value}</p>
                                            </>
                                        </Col>
                                      )
                                    )}
                                  </Row>
                                </>

                          </Card>
                          <Card title="Prescribing Trend" style={{ width: '100%', borderRadius: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
                            <Select defaultValue="Week" size="large" style={{ minWidth: 120,marginRight:"20px" }}>
                            <Option value="Week">Week</Option>
                            <Option value="Month">Month</Option>
                            </Select>
                            <Select mode="multiple" size="large" defaultValue={['All']} style={{ width: 200 }}>
                            <Option value="All">All</Option>
                            <Option value="Zoryve_TRX">Zoryve_TRX</Option>
                            <Option value="Zoryve_NRX">Zoryve_NRX</Option>
                            </Select>
                        </div>
                        <ReactEcharts option={getOption()} style={{ height: '300px', width: '100%' }} />
                        </Card>

                        </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    </>
  );
}
