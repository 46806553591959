// src/RoleManagement.js

import React, {useEffect, useState} from 'react';
import { Table, Button, Switch, Tooltip, } from 'antd';
import { ServiceUtils } from '../../../Shared/Utils/ServiceUtils';
import { alertService } from '../../../Shared/Utils/ToasterUtils';
import { useNavigate } from 'react-router-dom';
import { getDecryptSession } from '../../../Shared/Utils/CommonUtils';



const RoleManagement = () => {

  const navigate = useNavigate();

  const [roleList, setRoleList] = useState({
    tableData: {
        headerContent: [],
        bodyContent: []
    }
  });
  const [userDetails, setUserDetails] = useState({});

const getRoleDetails = () => {
  ServiceUtils.getRequest('rolesList').then(
    (response) => {
      if (response.status === 200 && response?.data.status) {
        let res = JSON.parse(JSON.stringify(response.data?.data));
        setRoleList(res)
      } 
      else if(response.status === 401 ){
        navigate('/logout');
      }
      else {
        alertService.error(response.data.message)
      }
    },
    (error) => {
      // alertService.error('Error while fetching data')
      console.log(error)
    }
  );
}

  useEffect(()=>{
    const session = localStorage.getItem("arcutis-user");
    const userDetails = getDecryptSession(session);
    setUserDetails(userDetails);
    if(userDetails.admin_access){
      getRoleDetails()
    }
  },[])

  return(
    <Table
      columns={roleList?.headerContent} 
      dataSource={roleList?.bodyContent} 
      scroll={{ x: true, y: 400 }}
      pagination={false}
      bordered
    />
  )

}

export default RoleManagement;
