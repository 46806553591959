import React, { useState } from 'react';
import './IconComponent.scss';
import Header from '../Header/Header';
import SVGIcon, { iconMapping } from '../SVGIcon/SVGIcon';
import { alertService } from '../../Utils/ToasterUtils';

export default function IconComponent() {
  const [searchText, setSearch] = useState('');
  const iconsKeys = Object.keys(iconMapping);

  const copyIcon = (iconKey) => {
    navigator.clipboard.writeText(iconKey);
    alertService.success(`Icon key copied to clipboard (${iconKey})`);
  }

  return (
    <>
      <Header />
      <div className="icon-component-root">
        <div className="page-header">Icons in AuditLens</div>
        <div className="page-contents">
          <div className="search-box">
            <input className="form-control" placeholder="Search icons..." onChange={(e)=> setSearch(e?.target?.value) } />
          </div>
          <div className="icon-container">
            {iconsKeys?.map((key, i) => {
              return (!searchText || key.includes(searchText)) && <div key={i} className="icon-card" onClick={() => copyIcon(key)}>
                <SVGIcon icon={key} />
                <div className="icon-name">{key}</div>
              </div>
            })}
          </div>
        </div>
      </div>
    </>
  )
}