import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  InputNumber,
  Cascader,
  TreeSelect,
  Button,
  Checkbox,
  Radio,
  Upload,
  message,
  Space,
  Slider,
  Divider,
} from "antd";
import "./Ant-DynamicFormBuilder.scss";
import SVGIcon from "../SVGIcon/SVGIcon";
import moment from "moment";
import "moment/locale/en-in";
import {
  CaretDownOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  DragOutlined,
} from "@ant-design/icons";
// import { DEPLOYED_ENVIRONMENT, ENVIRONMENTS } from "../../../Environments/environments";
import { API_ENDPOINTS } from "../../../Environments/config";
import { Option } from "antd/lib/mentions";
import classNames from "classnames";
import dayjs from "dayjs";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragNDropContainer from "../DragAndDropContainer/DragAndDropContainer";

// Form supports fields of type
// 1. Text
// 2. TextArea
// 3. Number
// 4. Password
// 5. Single Select
// 6. Multi Select
// 7. Cascader
// 8. TreeSelect
// 9. DatePicker
// 10. Checkbox
// 11. Radio
// 12. Slider

export default function FormBuilder({
  config,
  onAction,
  onFocus,
  data,
  defaultData,
  id,
  resetOnSubmit,
  disable,
  ...props
}) {
  const [form] = Form.useForm();
  const { SHOW_CHILD } = Cascader;
  const formLayout = "vertical";
  const { TextArea } = Input;
  // const [ _ , setDataForm] = useState();
  const inputRef = useRef(null);
  const [newOption, setNewOption] = useState({});
  let visibleCount = 0;

  useEffect(() => {
    if (data) {
      let tempData = JSON.parse(JSON.stringify(data));
      let formData = {};
      config.map((row) =>
        row.map((el) => {
          if (el?.key === "fielding_end_date") {
            if (tempData?.fielding_end_date) {
              formData.fielding_end_date = moment(tempData?.fielding_end_date);
            }
          } else if (el?.key === "due_date") {
            if (tempData?.due_date) {
              formData.due_date = moment(tempData?.due_date);
            }
          } else if (el?.key === "date") {
            if (tempData?.date) {
              formData.date = moment(tempData?.date);
            } else {
              formData.date = moment(new Date());
            }
          } else if (el?.key === "publication_date") {
            if (tempData?.publication_date) {
              formData.publication_date = moment(tempData?.publication_date);
            } else {
              formData.publication_date = moment(new Date());
            }
          } else if (el?.key) {
            formData[el?.key] = tempData[el?.key];
          }
        })
      );
      // setDataForm({ ...formData });
      form.setFieldsValue({ ...formData });
    }
  }, [data]);

  /**
   * On input value changes
   * @param {*} e - change event
   * @param {*} field - form field
   */
  const onSelectChange = (e, field) => {
    // console.log(e, field);
    try {
      if (field?.key === "project_code_id") {
        let clientName = "";
        field?.options.map((option) => {
          if (option?.value === e) {
            clientName = option?.client_name;
          }
        });
        let tempData = data;
        tempData["project_code_id"] = e;
        tempData["client_name"] = clientName;
        form.setFieldsValue({ ...tempData });
        const payload = {
          key: "project_code_id",
          value: e,
        };
        payload["data"] = { ...tempData };
        if (typeof onAction === "function")
          onAction({ type: "onChange", payload });
      } else if (field?.key === "fielding_start_date") {
        let startDate = form.getFieldValue("fielding_start_date");
        let endDate = form.getFieldValue("fielding_end_date");
        if (startDate && endDate) {
          let tempData = data;
          tempData["fielding_start_date"] = startDate;
          tempData["fielding_end_date"] = endDate;
          let diff = moment(endDate) - moment(startDate);
          if (diff / (1000 * 60 * 60 * 24) < 0) {
            form.setFieldsValue({ fielding_end_date: "" });
            tempData["fielding_end_date"] = "";
          }
        }
      } else if (field?.key === "fielding_end_date") {
        let startDate = form.getFieldValue("fielding_start_date");
        let endDate = form.getFieldValue("fielding_end_date");
        if (startDate && endDate) {
          let tempData = data;
          tempData["fielding_start_date"] = startDate;
          tempData["fielding_end_date"] = endDate;
          let diff = moment(endDate) - moment(startDate);
          if (diff / (1000 * 60 * 60 * 24) < 0) {
            form.setFieldsValue({ fielding_start_date: "" });
            tempData["fielding_start_date"] = "";
          }
        }
      } else if (field?.key === "action") {
        if (typeof onAction === "function")
          onAction({
            type: "onSelect",
            payload: { key: field?.key, data: { [field?.key]: e } },
            formId: id,
          });
      } else if (field.type === "date-picker") {
        console.log("k");
        let tempData = data;
        console.log(data);
        tempData["Date"] = e?.$d;
        // form.setFieldsValue({ ...tempData });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onFormLayoutChange = (changedValues, allValues) => {
    // console.log(data);
    // console.log(changedValues);
    // console.log(Object?.keys(changedValues)[0]);
    // console.log(config)
    console.log(allValues);
    let resOrd = config[0].filter((item) => {
      return item?.key === Object?.keys(changedValues)[0];
    });
    // console.log(resOrd);
    const payload = {};
    payload["key"] = Object.keys(changedValues)[0];
    payload["data"] = { ...data, ...changedValues };
    payload["allData"] = { ...data, ...allValues };
    payload["currentFieldData"] = resOrd;
    if (typeof onAction === "function")
      onAction({ type: "onChange", payload, formId: id });
  };

  const onFocusField = (e) => {
    e?.preventDefault();
    let resOrd = config[0].filter((item) => {
      return item?.key === e?.target?.id;
    });
    // console.log(e?.target?.id);
    // console.log(resOrd);
    onFocus(resOrd);
  };

  /**
   * Finish event of form
   * @param {*} formData - form data
   */
  const onFinish = (formData) => {
    let errors = {};
    if (Object.keys(errors).length === 0) {
      try {
        const payload = {
          data: formData,
        };
        if (typeof onAction === "function")
          onAction({ type: "submit", payload, formId: id });
        if (resetOnSubmit) form.resetFields();
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const infoClicked = (el) => {
    if (typeof onAction === "function")
      onAction({
        type: "infoClick",
        payload: { clickedField: el },
        formId: id,
      });
  };

  const handleAddSelect = (ele) => {
    if (typeof onAction === "function")
      onAction({
        type: "add-select",
        payload: { key: ele?.key, value: ele?.value },
        formId: id,
      });
  };

  const uploadProps = {
    name: "file",
    onChange(info) {
      if (info.file.status !== "uploading") {
        // console.log(info, info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`, 3);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`, 3);
      }
    },
  };

  const search = (array) =>
    array?.find((element) => element?.required === true);

  const onDropEventHandler = (draggedItemData, index) => {
    console.log(draggedItemData, "draggedItemData");
    if (typeof onAction === "function")
      onAction({
        type: "dragAndDrop",
        payload: { draggedItemData: draggedItemData?.dragItem, index },
        formId: id,
      });
  };

  const handleNewOptionAddition = (key, value) => {
    setNewOption({
      ...newOption,
      [key]: value,
    });
  };

  return (
    <Form
      id={id}
      form={form}
      layout={formLayout}
      onFinish={onFinish}
      onValuesChange={onFormLayoutChange}
      className="form-dyn-builder"
      name={id}
      disabled={disable}
      onFocus={onFocusField}
      fields={config}
      initialValues={defaultData}
      //   onFieldsChange={(e)=>console.log(e)}
    >
      {config?.map((list, i) => {
        return (
          <Row id={i} className="form-row">
            {list?.map((field, i) => {
              return (
                <Col md={24} lg={24} sm={24} xs={24} span={field?.col}>
                  <Row>
                    {field?.serialNumber && (
                      <div
                        className="field-label"
                        style={{
                          top: "4px",
                          paddingRight: "5px",
                          position: "relative",
                        }}
                      >
                        {++visibleCount}.
                      </div>
                    )}
                    <Col
                      md={field?.col}
                      lg={field?.col}
                      sm={22}
                      xs={22}
                      span={field?.col}
                    >
                      {field?.type && (
                        <>
                          <div className="form-question">
                            <label className="field-label" style={field?.style}>
                              {field?.label}
                              {search(field?.rules)?.required &&
                                !field?.disableAsterisk && (
                                  <span className="required-star"> *</span>
                                )}
                              {field.optional && (
                                <span className="optional">(optional)</span>
                              )}
                            </label>
                            <span>
                              {field?.info && (
                                <Button
                                  className="info-button"
                                  type="link"
                                  icon={<InfoCircleOutlined />}
                                  onClick={() => infoClicked(field)}
                                  ghost
                                >
                                  {field?.info}
                                </Button>
                              )}
                            </span>
                          </div>
                          {field?.tooltip && (
                            <>
                              <SVGIcon
                                icon={"info-fill"}
                                className="m-0 ps-1 fontCircleInfo"
                                data-tip
                                data-for={field?.key}
                              />
                              {/* <ReactTooltip id={field?.key} place="top">
                      {field?.tip}
                    </ReactTooltip> */}
                            </>
                          )}
                        </>
                      )}
                      {field?.type && field?.type === "input" && (
                        <>
                          <Form.Item
                            name={field?.key}
                            rules={field?.rules}
                            validateStatus={field?.validateStatus}
                            help={field?.help}
                          >
                            <Input
                              placeholder={field?.placeholder}
                              disabled={field?.disabled}
                              autoComplete={field?.autoComplete || "off"}
                              // validateStatus={validateEmptyField(name) ? 'success' : 'error'}
                            />
                          </Form.Item>
                        </>
                      )}
                      {field?.type && field?.type === "button" && (
                        <>
                          <Form.Item name={field?.key} rules={field?.rules}>
                            <Button
                              className="submit-btn"
                              type={field?.variant}
                              htmlType={field?.submit ? "submit" : "button"}
                              onClick={() => {
                                onFinish({ key: field?.key });
                              }}
                              disabled={field?.disabled}
                            >
                              {field?.title}
                            </Button>
                          </Form.Item>
                        </>
                      )}
                      {field?.type && field?.type === "select" && (
                        <>
                          <Form.Item name={field?.key} rules={field?.rules}>
                            <Select
                              placeholder={field?.placeholder}
                              suffixIcon={
                                <CaretDownOutlined
                                  style={{ pointerEvents: "none" }}
                                />
                              }
                              onChange={(e) => onSelectChange(e, field)}
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                              showSearch
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={field?.options}
                              disabled={field?.disabled}
                              maxTagCount={field?.maxTagCount}
                              loading={field?.loading}
                              autoComplete={field?.autoComplete || "off"}
                              defaultValue={field?.defaultValue}
                            ></Select>
                          </Form.Item>
                        </>
                      )}
                      {field?.type === "selectWithAdd" && (
                        <>
                          <Form.Item name={field?.key} rules={field?.rules}>
                            <Select
                              ref={inputRef}
                              placeholder={field?.placeholder}
                              suffixIcon={
                                <CaretDownOutlined
                                  style={{ pointerEvents: "none" }}
                                />
                              }
                              onChange={(e) => onSelectChange(e, field)}
                              getPopupContainer={(trigger) => document.body}
                              showSearch
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <Divider style={{ margin: "8px 0" }} />
                                  <div
                                    style={{
                                      display: "flex",
                                      padding: "0 8px 4px",
                                      gap: "8px",
                                    }}
                                  >
                                    <Input
                                      placeholder={`Enter the ${field?.label}`}
                                      // ref={inputRef}
                                      value={newOption[field?.key]}
                                      onChange={(e) =>
                                        handleNewOptionAddition(
                                          field?.key,
                                          e?.target?.value
                                        )
                                      }
                                      onKeyDown={(e) => e.stopPropagation()}
                                      style={{ flex: 1 }} // Ensure the input takes up the full available space
                                    />
                                    <Button
                                      type="text"
                                      icon={<PlusOutlined />}
                                      onClick={() => {
                                        onAction({
                                          type: "addNewOption",
                                          field,
                                          value: newOption[field?.key],
                                          payload: {
                                            key: field?.key,
                                            currentFieldData: [field],
                                            data: {
                                              [field?.key]:
                                                newOption[field?.key],
                                            },
                                          },
                                          formId: id,
                                        });
                                        inputRef.current.blur();
                                        if (inputRef.current) {
                                          inputRef.current.blur();
                                        }
                                      }}
                                    >
                                      Add
                                    </Button>
                                  </div>
                                </>
                              )}
                              options={field?.options}
                              disabled={field?.disabled}
                              maxTagCount={field?.maxTagCount}
                              loading={field?.loading}
                              autoComplete={field?.autoComplete || "off"}
                              defaultValue={field?.defaultValue}
                            />
                          </Form.Item>
                        </>
                      )}
                      {field?.type && field?.type === "other-select" && (
                        <>
                          <Form.Item name={field?.key} rules={field?.rules}>
                            <Select
                              placeholder={field?.placeholder}
                              suffixIcon={
                                <CaretDownOutlined
                                  style={{ pointerEvents: "none" }}
                                />
                              }
                              onChange={(e) => onSelectChange(e, field)}
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                              showSearch
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={field?.options}
                              disabled={field?.disabled}
                              maxTagCount={field?.maxTagCount}
                              loading={field?.loading}
                              autoComplete={field?.autoComplete || "off"}
                            ></Select>
                          </Form.Item>
                          {field?.otherOption?.type &&
                            field?.otherOption?.type === "input" &&
                            data?.[field?.key] === "Other" && (
                              <>
                                <Form.Item
                                  name={field?.otherOption?.key}
                                  rules={field?.otherOption?.rules}
                                  validateStatus={
                                    field?.otherOption?.validateStatus
                                  }
                                  help={field?.otherOption?.help}
                                >
                                  <Input
                                    placeholder={
                                      field?.otherOption?.placeholder
                                    }
                                    disabled={field?.otherOption?.disabled}
                                    autoComplete={
                                      field?.otherOption?.autoComplete || "off"
                                    }
                                    // validateStatus={validateEmptyField(name) ? 'success' : 'error'}
                                  />
                                </Form.Item>
                              </>
                            )}
                        </>
                      )}
                      {field?.type && field?.type === "styled-select" && (
                        <>
                          <Form.Item name={field?.key} rules={field?.rules}>
                            <Select
                              placeholder={field?.placeholder}
                              suffixIcon={
                                <CaretDownOutlined
                                  style={{ pointerEvents: "none" }}
                                />
                              }
                              onChange={(e) => onSelectChange(e, field)}
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                              showSearch
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              disabled={field?.disabled}
                              maxTagCount={field?.maxTagCount}
                              loading={field?.loading}
                              autoComplete={field?.autoComplete || "off"}
                            >
                              {field?.options?.map((el) => {
                                return (
                                  <Option
                                    value={el?.value}
                                    style={{ ...el?.style }}
                                  >
                                    <div
                                      style={{
                                        paddingLeft: 7,
                                        borderRadius: 5,
                                        ...(el?.color_code && {
                                          backgroundColor: el?.color_code,
                                        }),
                                      }}
                                    >
                                      {el.label}
                                    </div>
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </>
                      )}
                      {field?.type && field?.type === "select-clicked" && (
                        <>
                          <Form.Item rules={field?.rules}>
                            <Select
                              placeholder={field?.placeholder}
                              suffixIcon={
                                <CaretDownOutlined
                                  style={{ pointerEvents: "none" }}
                                />
                              }
                              onSelect={(e) => onSelectChange(e, field)}
                              showSearch
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={field?.options}
                              disabled={field?.disabled}
                              maxTagCount={field?.maxTagCount}
                              loading={field?.loading}
                              autoComplete="off"
                            ></Select>
                          </Form.Item>
                        </>
                      )}
                      {field?.type && field?.type === "multi-select" && (
                        <>
                          <Form.Item name={field?.key} rules={field?.rules}>
                            <Select
                              placeholder={field?.placeholder}
                              suffixIcon={
                                <CaretDownOutlined
                                  style={{ pointerEvents: "none" }}
                                />
                              }
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                              optionFilterProp="label"
                              showSearch
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              mode="multiple"
                              showArrow={true}
                              options={field?.options}
                              disabled={field?.disabled}
                              maxTagCount={field?.maxTagCount || "responsive"}
                              loading={field?.loading}
                              autoComplete="none"
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  {field?.extraButton && (
                                    <Button
                                      type="link"
                                      icon={<PlusOutlined />}
                                      onClick={() =>
                                        handleAddSelect({
                                          key: field?.key,
                                          value: field?.extraButton?.value,
                                        })
                                      }
                                      block
                                    >
                                      {field?.extraButton?.title}
                                    </Button>
                                  )}
                                </>
                              )}
                            ></Select>
                          </Form.Item>
                        </>
                      )}
                      {field?.type && field?.type === "date-picker" && (
                        <>
                          <Form.Item
                            name={field?.key}
                            rules={field?.rules}
                            getValueProps={(value) => {
                              return { value: dayjs(value) };
                            }}
                            value={dayjs(new Date())}
                          >
                            <DatePicker
                              style={{
                                width: "100%",
                                ...(field?.borderless && {
                                  padding: "4px 2px",
                                  color: "black",
                                  fontWeight: 600,
                                }),
                              }}
                              format={field?.format || "DD MMMM YYYY"}
                              showTime
                              placeholder={field?.placeholder}
                              disabled={field?.disabled}
                              disabledDate={(d) =>
                                !d ||
                                d.isBefore(field?.minDate || "") ||
                                d.isAfter(field?.maxDate || "")
                              }
                              onChange={(e) => onSelectChange(e, field)}
                              allowClear={false}
                              autoComplete="off"
                              bordered={!field?.borderless}
                              initialValues={dayjs(new Date())}
                            />
                          </Form.Item>
                        </>
                      )}
                      {field?.type && field?.type === "quarter-picker" && (
                        <>
                          <Form.Item
                            name={field?.key}
                            rules={field?.rules}
                            getValueProps={(value) => {
                              return { value: dayjs(value) };
                            }}
                            value={dayjs(new Date())}
                          >
                            <DatePicker
                              style={{
                                width: "100%",
                                ...(field?.borderless && {
                                  padding: "4px 2px",
                                  color: "black",
                                  fontWeight: 600,
                                }),
                              }}
                              placeholder={field?.placeholder}
                              disabled={field?.disabled}
                              onChange={(e) => onSelectChange(e, field)}
                              allowClear={false}
                              autoComplete="off"
                              picker="quarter"
                              bordered={!field?.borderless}
                            />
                          </Form.Item>
                        </>
                      )}
                      {field?.type && field?.type === "inputNumber" && (
                        <>
                          <Form.Item name={field?.key} rules={field?.rules}>
                            <InputNumber
                              style={{ width: "100%" }}
                              placeholder={field?.placeholder}
                              disabled={field?.disabled}
                              min={1}
                              controls={false}
                              autoComplete="off"
                            />
                          </Form.Item>
                        </>
                      )}
                      {field?.type && field?.type === "Select-Cascader" && (
                        <Form.Item name={field?.key} rules={field?.rules}>
                          <Cascader
                            style={{ width: "100%" }}
                            placeholder={field?.placeholder}
                            maxTagCount={field?.maxTagCount}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            options={field?.options}
                            multiple={field?.type === "Select_Multi_Cascader"}
                            showSearch={true}
                          />
                        </Form.Item>
                      )}
                      {field?.type && field?.type === "tree-select" && (
                        <>
                          <Form.Item name={field?.key} rules={field?.rules}>
                            <TreeSelect
                              placeholder={field?.placeholder}
                              disabled={field?.disabled}
                              hideSelectedOptions={field?.hideSelectedOption}
                              showArrow
                              style={{ width: "100%" }}
                              treeData={field?.options}
                              treeCheckable={true}
                              allowClear={true}
                              labelInValue={true}
                              showSearch={true}
                              showCheckedStrategy={SHOW_CHILD}
                              menuPosition="fixed"
                              maxTagCount={field?.maxTagCount}
                              closeMenuOnSelect={field?.closeMenuSelect}
                            />
                          </Form.Item>
                        </>
                      )}
                      {field?.type && field?.type === "textArea" && (
                        <Form.Item name={field?.key} rules={field?.rules}>
                          <TextArea
                            rows={3}
                            maxLength={500}
                            placeholder={field?.placeholder}
                            disabled={field?.disabled}
                            autoComplete="off"
                          />
                        </Form.Item>
                      )}
                      {field?.type && field?.type === "password" && (
                        <Form.Item
                          name={field?.key}
                          rules={field?.rules}
                          validateStatus={field?.validateStatus}
                          help={field?.help}
                        >
                          <Input.Password
                            placeholder={field?.placeholder}
                            disabled={field?.disabled}
                            current-password=""
                            autoComplete="off"
                          />
                        </Form.Item>
                      )}
                      {field?.type && field?.type === "checkbox" && (
                        <Form.Item name={field?.key} rules={field?.rules}>
                          <Checkbox.Group>
                            <Space direction={field?.direction}>
                              {field?.options.map((option) => (
                                <>
                                  <Checkbox value={option?.value}>
                                    {option?.label}
                                  </Checkbox>
                                  {/* <br/> */}
                                </>
                              ))}
                            </Space>
                          </Checkbox.Group>
                        </Form.Item>
                      )}
                      {field?.type && field?.type === "radio" && (
                        <Form.Item name={field?.key} rules={field?.rules}>
                          <Radio.Group>
                            <Space direction={field?.direction}>
                              {field?.options.map((option) => (
                                <Radio value={option?.value}>
                                  {option?.label}
                                </Radio>
                              ))}
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      )}
                      {field?.type && field?.type === "radioButton" && (
                        <Form.Item name={field?.key} rules={field?.rules}>
                          <Radio.Group
                            size={field?.size || "small"}
                            disabled={field?.disabled}
                          >
                            <Space>
                              {field?.options.map((option) => (
                                <Radio.Button value={option?.value}>
                                  {option?.label}
                                </Radio.Button>
                              ))}
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      )}
                      {field?.type && field?.type === "file-upload" && (
                        <Form.Item name={field?.key} rules={field?.rules}>
                          <Upload
                            action={
                              "http://192.168.3.171:5000/api/v1.0/audit_lens/" +
                              API_ENDPOINTS[field?.apiEndpoint]
                            }
                            accept={field?.accept}
                            maxCount={field?.maxCount}
                            {...uploadProps}
                          >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload>
                        </Form.Item>
                      )}
                      {field?.type && field?.type === "label" && (
                        <Form.Item name={field?.key} rules={field?.rules}>
                          <div
                            style={{
                              padding: "5px 0",
                              fontWeight: 600,
                              ...field?.styles,
                            }}
                          >
                            {field?.isDate
                              ? moment(form.getFieldValue(field?.key)).format(
                                  "LL"
                                )
                              : form.getFieldValue(field?.key) || ""}
                          </div>
                        </Form.Item>
                      )}
                      {field?.type && field?.type === "slider" && (
                        <>
                          <Form.Item name={field?.key} rules={field?.rules}>
                            <Slider
                              min={1}
                              max={100}
                              placeholder={field?.placeholder}
                              disabled={field?.disabled}
                              marks={field?.marks}
                            />
                          </Form.Item>
                          {/* <Form.Item name={field?.key} rules={field?.rules}>
                    <InputNumber
                      min={1}
                      max={100}
                      onChange={(e)=>{if(e?.target?.value > 100) return}}
                      style={{ margin: '0 16px' }}
                    />
                    </Form.Item> */}
                        </>
                      )}
                      {field?.type && field?.type === "dragAndDrop" && (
                        <>
                          <Form.Item name={field?.key} rules={field?.rules}>
                            <DndProvider backend={HTML5Backend}>
                              <DragNDropContainer
                                key="empty-div"
                                onDrop={onDropEventHandler}
                                path="0"
                                containerType="droppable"
                                isEmpty
                                canDrag={true}
                                dragType=""
                                dropType={["options"]}
                                padding="5px 0"
                              >
                                {field?.options?.map((el, index) => {
                                  return (
                                    <DragNDropContainer
                                      path={index}
                                      dragItem={{
                                        element: el,
                                        key: field?.key,
                                        options: field?.options,
                                      }}
                                      onDrop={onDropEventHandler}
                                      canDrag={disable ? !disable : true}
                                      dragType={"options"}
                                      dropType={["options"]}
                                    >
                                      <div
                                        value={el?.value}
                                        style={{ ...el?.style }}
                                      >
                                        <div
                                          style={{
                                            paddingLeft: 7,
                                            padding: "10px",
                                            border: disable
                                              ? "1px solid grey"
                                              : "1px solid rgb(141 193 236)",
                                            cursor: "pointer",
                                            borderRadius: "10px",
                                            margin: "10px 0",
                                            ...(el?.color_code && {
                                              backgroundColor: el?.color_code,
                                            }),
                                          }}
                                        >
                                          <DragOutlined
                                            style={{ margin: "0 10px" }}
                                          />
                                          {el.label}
                                        </div>
                                      </div>
                                    </DragNDropContainer>
                                  );
                                })}
                              </DragNDropContainer>
                            </DndProvider>
                          </Form.Item>
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        );
      })}
    </Form>
  );
}
