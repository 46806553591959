import React from 'react';

import Img from '../../Assets/images/page-not-found.svg';
import './PagesNotFound.scss';
import { useNavigate } from 'react-router-dom';
import { Button, Image } from 'antd';
import { RightOutlined } from '@ant-design/icons';


export default function PageNotFound() {
  const navigate = useNavigate();

  const gotoHome = () => {
    navigate('/home')
  };

  return (
    <main className="page-not-found">
      <div className="empty-state-container">
        <div className="card">
          <div className="card-body">
            <div className="state-figure">
              <Image className="img-fluid" src={Img} alt="" style={{ maxWidth: 320 }} preview={false}/>
            </div>
            <h3 className="state-header"> Page not found! </h3>
            <p className="state-description lead"> The URL is pointing to something that doesn't exist. </p>
            <div className="state-action">
              <Button type='link' className="btn btn-lg btn-light" onClick={gotoHome} icon={<RightOutlined />}>
                Go To Home
              </Button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}