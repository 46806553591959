import React from 'react';
import { Card } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

const ActivityDetails = () => {
  return (
    <Card
      style={{ textAlign: 'center', margin: '0 auto', padding: 20 ,border:'none'}}
      cover={
        <WarningOutlined style={{ fontSize: '64px', color: '#faad14', margin: '20px 0' }} />
      }
    >
      <h3 style={{fontSize:"25px"}}>Construction in Progress</h3>
      <p style={{fontSize:"20px"}}>We are working hard to bring you something awesome. Stay tuned! 😉</p>
    </Card>
  );
};

export default ActivityDetails;
