export const ENVIRONMENTS = {
  localhost: {
    baseurl: '',
  },
  dev: {
    baseurl: '/api/',
  },
  prod: {
    baseurl: '/api/',
  },
};

export const DEPLOYED_ENVIRONMENT = 'dev';
export const BASE_PATH = '';

