// src/UserFormPage.js

import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Layout,
  Avatar,
  Upload,
  Switch,
  Row,
  Col,
  Breadcrumb,
  Card,
  message,
} from "antd";
import { ArrowLeftOutlined, EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import BreadcrumbList from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import { ServiceUtils } from "../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../Shared/Utils/ToasterUtils";
import CryptoJS from "crypto-js";
import { getDecryptSession } from "../../../../Shared/Utils/CommonUtils";
const { Option } = Select;
const { Header, Content } = Layout;





const AddEditUser = ({ ...props }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(true);
  const [defaultUserData, setDefaultUserData] =useState();
  const [userData, setUserData] = useState();
  const roles = [
    { label: "Admin", value: "admin" },
    { label: "User", value: "user" },
  ];
  const [passwordEnabled, setPasswordEnabled] = useState(false);

  useEffect(() => {
    if (userId) {
      //call api
      // getUserdata()
    }
  }, []);

  const getUserDetails = () => {
    let data = {
      key: "9",
      firstName: "Murali",
      lastName:"Pinnaka",
      email: "m.pinnaka",
      role: "Admin",
      passwordEnabledLogin: false,
      id: 9,
    }
    setUserData(data)
    // setLoader(false);
  }

  useEffect(()=>{
    const session = localStorage.getItem("arcutis-user");
    const userDetails = getDecryptSession(session);
    setUserDetails(userDetails);
    if(userId){
      // getUserDetails()
      getUserdata()
    }
    else{
      setLoader(false);
      // setLoader(false);
    }
  },[])

  const SubmitButton = ({ form, children }) => {
    const [submittable, setSubmittable] = React.useState(false);
  
    // Watch all values
    const values = Form.useWatch([], form);
    // console.log(values);
    // console.log(form);
    // console.log(JSON.stringify(defaultUserData)===JSON.stringify(values))
    React.useEffect(() => {
      form
        .validateFields({
          validateOnly: true,
        })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false));
    }, [form, values]);
    // console.log(values);
    // console.log(submittable);
    // console.log(isRequired);
    // console.log(!submittable, JSON.stringify(defaultUserData)===JSON.stringify(values))
    // console.log(!submittable || JSON.stringify(defaultUserData)===JSON.stringify(values))

    return (
      <Button type="primary" htmlType="submit" disabled={!submittable || (JSON.stringify(defaultUserData)===JSON.stringify(values)) } onClick={handleSave}>
        {children}
      </Button>
    );
  };

  const getUserdata = () => {
    let payload={
      userId : Number(userId)
    }
    ServiceUtils.postRequest('userData', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          // setProfileList(response.data.data)
          // navigate("/admin/settings")
          let userDa= JSON.parse(JSON.stringify(response.data.data));
          console.log(userDa);
          setUserData(userDa)
          setDefaultUserData(userDa);
          setLoader(false);
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching data')
        console.log(error)
      }
    );
  }

  const addEditUser = () => {
    let userInfo = JSON.parse(JSON.stringify(userData));
    if(userInfo?.password){
      const key = 'ThisIsASecureKey1234567890123456'; // Same key as used in the backend
      const iv = 'ThisIsAnIV123456'; // Same IV as used in the backend
      let pass = userInfo?.password;
      const cipherText = CryptoJS.AES.encrypt(pass, CryptoJS.enc.Utf8.parse(key), {
            iv: CryptoJS.enc.Utf8.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
      });
      userInfo.password = cipherText.toString();
    }
    if(userInfo?.email){
      userInfo.email = userInfo.email + "@arcutis.com"; 
    }
    let payload = 
    {
      "userData" : userInfo,
      "type" : userId ? "edit" : "add",
      "userId": userId
    }
    ServiceUtils.postRequest('userOps', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          // setProfileList(response.data.data)
          navigate("/admin/settings")
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching data')
        console.log(error)
      }
    );
  }

  const handleSave = (values) => {
    // console.log(userData);
    // console.log(userId);
    addEditUser()
    // if (userId) {
    //   //save data
    //   addEditUser()
    // } else {
    //   addEditUser()
    //   //save data
    // }
    // navigate("/admin/settings");
  };

  const handleInputChange = (key, value, action) => {
    if(key==="password"){
      // console.log(key)
      if(value){
        // console.log(value)
        setIsRequired(true)
      }
      else{
          // console.log(value)
          setIsRequired(false);
      }
    }
    if (action === "onchange") {
      setUserData({
        ...userData,
        [key]: value,
      });
    }
  };

  const getPasswordStatus = () => {
    return userData?.["passwordEnabledLogin"] ? true : false;
  }

  const [isRequired, setIsRequired] = useState(false);

  const checkRequired = () => {
    console.log(isRequired);
    return isRequired;
  }



  return (
    <div>
      {!loader &&
        <div>
          <Card
            style={{ width: "100%", height: "fit-content" }}
            bodyStyle={{ padding: "10px" }}
          >
            {userId ? (
              <Breadcrumb
                separator=""
                items={[
                  {
                    title: (
                      <>
                        <div
                          className="settings-breadcrumb"
                          onClick={() => navigate("/admin/settings")}
                        >
                          <div>
                            <ArrowLeftOutlined className="arrow-display" />
                            <span style={{ marginLeft: "2px" }}>Settings</span>
                          </div>
                        </div>
                      </>
                    ),
                  },
                  {
                    type: "separator",
                  },
                  {
                    title: (
                      <div style={{ display: "flex" }}>
                        <div style={{ margin: "0px 2px" }}>Edit User</div>
                      </div>
                    ),
                  },
                ]}
              />
            ) : (
              <Breadcrumb
                separator=""
                items={[
                  {
                    title: (
                      <>
                        <div
                          className="settings-breadcrumb"
                          onClick={() => navigate("/admin/settings")}
                        >
                          <div>
                            <ArrowLeftOutlined className="arrow-display" />
                            <span style={{ marginLeft: "2px" }}>Settings</span>
                          </div>
                        </div>
                      </>
                    ),
                  },
                  {
                    type: "separator",
                  },
                  {
                    title: (
                      <div style={{ display: "flex" }}>
                        <div style={{ margin: "0px 2px" }}>Add User</div>
                      </div>
                    ),
                  },
                ]}
              />
            )}
          </Card>
          <Content
            style={{
              padding: "24px",
              background: "#fff",
              height: "calc(100vh - 90px)",
            }}
          >
              <Form
                  initialValues={userData && userData}
                  // ErrorList={(e)=>{console.log(e)}}
                  form={form} 
                  name="validateOnly" 
                  layout="vertical" 
                  autoComplete="off"
                  
                >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col
                    className="gutter-row"
                    xs={{ flex: "100%" }}
                    sm={{ flex: "100%" }}
                    md={{ flex: "50%" }}
                    lg={{ flex: "25%" }}
                    xl={{ flex: "25%" }}
                  >
                    <label
                      class="form-label text-ellipsis-one-line"
                      style={{ marginBottom: "5px" }}
                    >
                      First name
                    </label>
                    <Form.Item name="firstName"
                      rules={
                        [
                          {
                            required: true,
                            message: "First name is required.",
                          },
                        ]
                      }
                      >
                      <Input
                        // style={{ width: "100%" }}
                        // key={"name"}
                        // value={userData?.["firstName"]}
                        onChange={(e) =>
                          handleInputChange("firstName", e.target.value, "onchange")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={{ flex: "100%" }}
                    sm={{ flex: "100%" }}
                    md={{ flex: "50%" }}
                    lg={{ flex: "25%" }}
                    xl={{ flex: "25%" }}
                  >
                    <label
                      class="form-label text-ellipsis-one-line"
                      style={{ marginBottom: "5px" }}
                    >
                      Last name
                    </label>
                    <Form.Item name="lastName"
                      rules={
                        [
                          {
                            required: true,
                            message: "Last name is required.",
                          },
                        ]
                      }
                      >
                      <Input
                        // style={{ width: "100%" }}
                        // key={"name"}
                        // value={userData?.["name"]}
                        onChange={(e) =>
                          handleInputChange("lastName", e.target.value, "onchange")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={{ flex: "100%" }}
                    sm={{ flex: "100%" }}
                    md={{ flex: "50%" }}
                    lg={{ flex: "25%" }}
                    xl={{ flex: "25%" }}
                  >
                    <label
                      class="form-label text-ellipsis-one-line"
                      style={{ marginBottom: "5px" }}
                    >
                      Email
                    </label>
                    <Form.Item name="email"
                      rules={
                        [
                          {
                            pattern: new RegExp(/^[a-zA-Z0-9]*$/),
                            message: "No Space or Special Characters Allowed"
                          },
                          {
                            required: true,
                            message: "Email is required.",
                          },
                        ]
                      }
                      >
                      <Input
                        addonAfter="@arcutis.com"
                        // style={{ width: "100%" }}
                        // key={"username"}
                        // value={userData?.["email"]}
                        onChange={(e) =>
                          handleInputChange("email", e.target.value, "onchange")
                        }
                        
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={{ flex: "100%" }}
                    sm={{ flex: "100%" }}
                    md={{ flex: "50%" }}
                    lg={{ flex: "25%" }}
                    xl={{ flex: "25%" }}
                  >
                    <label
                      class="form-label text-ellipsis-one-line"
                      style={{ marginBottom: "5px" }}
                    >
                      Role
                    </label>
                    <Form.Item name="role"
                      rules={
                        [
                          {
                            required: true,
                            message: "Role is required.",
                          },
                        ]
                      }
                      >
                      <Select
                        // style={{ width: "100%" }}
                        // value={userData?.["role"]}
                        onChange={(e) => {
                          handleInputChange("role", e, "onchange");
                        }}
                        options={roles}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  {!userId &&
                    <Col
                      className="gutter-row"
                      xs={{ flex: "100%" }}
                      sm={{ flex: "100%" }}
                      md={{ flex: "50%" }}
                      lg={{ flex: "25%" }}
                      xl={{ flex: "25%" }}
                    >
                      <label
                        class="form-label text-ellipsis-one-line"
                        style={{ marginBottom: "5px" }}
                      >
                        Enable Password Login
                      </label>
                      <Form.Item name="passwordEnabledLogin"
                        // rules={
                        //   [
                        //     {
                        //       required: true,
                        //       message: "Role is required.",
                        //     },
                        //   ]
                        // }
                        >
                        <Switch
                          // value={userData?.["passwordEnabledLogin"]}
                          onChange={(e) => {
                          handleInputChange("passwordEnabledLogin", e, "onchange");
                        }}
                      />
                      </Form.Item>
                    </Col>
                  }
                  {userData?.["passwordEnabledLogin"] && !userId &&
                    <Col
                      className="gutter-row"
                      xs={{ flex: "100%" }}
                      sm={{ flex: "100%" }}
                      md={{ flex: "50%" }}
                      lg={{ flex: "25%" }}
                      xl={{ flex: "25%" }}
                    >
                      <label
                        class="form-label text-ellipsis-one-line"
                        style={{ marginBottom: "5px" }}
                      >
                        Password
                      </label>
                      <Form.Item name="password"
                        rules={
                          [
                            // {
                            //   pattern: new RegExp("/^\S*$/"),
                            //   message:"No spaces are allowed"
                            // },
                            {
                              required: true ,
                              message: "Password is required.",
                            },
                          ]
                        }
                        >
                        <Input.Password
                          // style={{ width: "100%" }}
                          // key={"name"}
                          // value={userData?.["name"]}
                          // type="password"
                          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                          disabled={!userData?.passwordEnabledLogin}
                          onChange={(e) =>
                            handleInputChange("password", e.target.value, "onchange")
                          }
                        />
                      </Form.Item>
                    </Col>
                  }
                </Row>
              </Form>
              <div className="submit-block"  style={{ position: 'absolute',bottom: '5px', right:'5px' }}>
                <div style={{ paddingRight: "10px" }}>
                  {/* <Button
                    className="edit-meet-button"
                    type="primary"
                    onClick={() => handleSave()}
                  >
                    {userId?'Update':'Save'}
                  </Button> */}
                  <SubmitButton form={form} >{userId?'Update':'Save'}</SubmitButton>
                </div>
                <Button onClick={()=>navigate("/admin/settings")}>Cancel</Button>
              </div>
          </Content>
        </div>
      }
    </div>
  );
};

export default AddEditUser;
