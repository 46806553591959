import React, { useEffect, useState, useRef } from "react";
import { Table, Button, Dropdown, Menu, Switch, Tooltip,Modal, Form,AutoComplete,
  Cascader,
  Checkbox,
  Col,
  Input,
  InputNumber,
  Row,
  Space,
  Select} from "antd";
import { MoreOutlined ,CheckOutlined, CloseOutlined, ReloadOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useSharedState } from "../../../Shared/Services/WindowSizeService";
import { ServiceUtils } from "../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../Shared/Utils/ToasterUtils";
import { map, get } from "lodash";
import CryptoJS from "crypto-js";
import { getDecryptSession } from "../../../Shared/Utils/CommonUtils";





const UserManagement = () => {
  // const [data, setData] = useState(sourceData);
  const { Option } = Select;
  const [searchFilterTerm, setSearchFilterTerm] = useState();
  const [userDetails, setUserDetails] = useState({});
  const navigate = useNavigate();
  const windowSize$ = useSharedState();
  const [openRest, setOpenReset] = useState(false);
  const [currentUserId, setCurrentUserId] = useState();
  const [userManagementTableData, setUserManagementTableData] = useState({
        headerContent: [],
        bodyContent: []
    });
  const tableDataRef = useRef();
  tableDataRef.current = userManagementTableData;

  const [state,setState] = useState(
    {
      filteredInfo: null,
      sortedInfo: null,
      data : userManagementTableData?.bodyContent,
      filtered: false,
      searchTex: ""
    }
  )
  const [EnablePasswordModal, setEnablePasswordModal] = useState(false);

  function debounce(func, timeout = 300){
    console.log('ii')
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  const onSearch = (e) => {
    const reg = new RegExp(e.target.value, "gi");
    const filteredData = map(userManagementTableData?.bodyContent, record => {
      const firstNameMatch = get(record, "firstName")?.match(reg);
      const lastNameMatch = get(record, "lastName")?.match(reg);
      const emailMatch = get(record, "email")?.match(reg);
      const roleMatch = get(record, "role")?.match(reg);
      // console.log(nameMatch,addressMatch);
      if (!firstNameMatch && !lastNameMatch && !emailMatch && !roleMatch) {
        return null;
      }
      // console.log(record);
      return record;
    }).filter(record => !!record);

    setState({
      searchTex: e.target.value,
      filtered: !!e.target.value,
      data: e.target.value ? filteredData : userManagementTableData?.bodyContent
    });
  };

  // useEffect(()=>{
  //   console.log(userManagementTableData);
  // },[userManagementTableData])

    const menu = (record) => (
      <Menu>
        <Menu.Item key="edit" onClick={() => handleActions(record, "edit")}>
          Edit
        </Menu.Item>
        {/* <Menu.Item key="delete" onClick={() => handleActions(record, "edit")}>
          Delete
        </Menu.Item> */}
      </Menu>
    );

  const handleActions = (data, action) => {
    // console.log(data);
    if (action === "edit") {
      //call api
      navigate(`/admin/user/${data?.id}`);
    } else if (action === "delete") {
      //call API
    }
  };

  const enablePasswordService = (key, id, value) => {
    let payload = 
    {
      "userData" : {
        [key]:value,
      },
      "type" : "edit",
      "userId": id
    }
    ServiceUtils.postRequest('userOps', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          // setProfileList(response.data.data)
          setState({
            filteredInfo: null,
            sortedInfo: null,
            data : {},
            filtered: false,
            searchTex: ""
          })
          getUserTableDetails()
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching data')
        console.log(error)
      }
    );
  }

  useEffect(()=>{
    // console.log(state.bodyContent)
  },[state])

  const handleDropdownChange = (value, key, id,withUpdate) => {
    setCurrentUserId(id)
    // console.log(withUpdate);
    // console.log(record);
    if(key === "passwordEnabled" && value===false && withUpdate===true){
      enablePasswordService(key, id, value)
    }
    else{
      let tempTableData = [...tableDataRef?.current?.headerContent]
      let tempTableBody = [...tableDataRef?.current?.bodyContent];
      // if(key === "enablePasswordValue"){
      //   tempTableData[index]["passwordEnabled"] = value;
      // }
      for (let i = 0; i < tempTableBody?.length; i++) {
        if(tempTableBody[i].id=== id){
          // alertService.info("Make sure to ")
          // console.log(tempTableBody[i][key],value);
          tempTableBody[i][key] = value;
          if(value){
            setEnablePasswordModal(true);
          }
          else{
            setEnablePasswordModal(false);
          }

        }
      }
      // tempTableBody[index][key] = value;
      setUserManagementTableData(
        {
          headerContent:tempTableData,
          bodyContent: tempTableBody
      },)
      // console.log(tempTableBody);
      state.bodyContent = tempTableBody;
      tableDataRef.current.bodyContent=tempTableBody;
      setState(state);
    }
    
  };

  const openPaswordModal = (rec) => {
    // console.log(rec);
    setOpenReset(true)
    setCurrentUserId(rec?.id)
  }

  const getUserTableDetails = () => {
    ServiceUtils.getRequest('usersList').then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          // setDataObj(response.data.data)
          // console.log(response?.data?.data);
          let res = response.data?.data;
          res?.headerContent?.map(el => {
            // if (el.key === 'passwordEnabled') {
            //     el.render = (_, record) => (
            //       <div style={{ textAlign: 'center' }}>
            //       {record?.passwordEnabled ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />}
            //     </div>
            //     )
            // } 
            if(el.key === 'passwordEnabled'){
              el.render = (_, record,index) => (
                <div style={{ textAlign: 'center' }}>
                <Switch defaultChecked={record?.passwordEnabled} onChange={(e)=>{handleDropdownChange(e,el?.key, record?.id, true)}}/>
                {record?.passwordEnabled ?
                  <Tooltip title={"Reset password"}>
                    <ReloadOutlined style={{marginLeft:'15px'}} className="pointer" onClick={()=>{openPaswordModal(record)}}/>
                  </Tooltip>
                  :
                  <ReloadOutlined style={{marginLeft:'15px', opacity:'0.5'}} className="disabled-cursor" />
                }
              </div>
            )
            }
            else if (el.key === 'action') {
                el.render = (_, record) => (
                  <Dropdown overlay={menu(record)} trigger={["click"]}>
                    <Button type="link" icon={<MoreOutlined />} />
                  </Dropdown>
                )
            }
            else if(el.key === "email"){
              el.render = (_, record) => (
                <Space size="middle">
                  <Tooltip title={record?.email}>
                      {record?.email}
                  </Tooltip>
                </Space>
              );
              // el.onFilter= (value, record) => record.HCPName.indexOf(value) === 0;
            }
          
          })
          setUserManagementTableData(res);
          state.data=res?.bodyContent;
          state.searchTex="";
          setState(state);
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        // alertService.error('Error while fetching data')
        console.log(error)
      }
    );
  }

  useEffect(()=>{
    const session = localStorage.getItem("arcutis-user");
    const userDetails = getDecryptSession(session);
    setUserDetails(userDetails);
    if(userDetails.admin_access){
      getUserTableDetails();
    }
  },[])

  const [autoCompleteResult, setAutoCompleteResult] = useState([]);
  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(['.com', '.org', '.net'].map((domain) => `${value}${domain}`));
    }
  };
  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  const [form] = Form.useForm();
  const onFinish = (values) => {
    // console.log('Received values of form: ', values);
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };

  // const 

  const submitPassword = () => {
    // console.log(form.getFieldsValue());

    let userInfo = JSON.parse(JSON.stringify(form.getFieldsValue()));
    if(userInfo?.password){
      const key = 'ThisIsASecureKey1234567890123456'; // Same key as used in the backend
      const iv = 'ThisIsAnIV123456'; // Same IV as used in the backend
      let pass = userInfo?.password;
      const cipherText = CryptoJS.AES.encrypt(pass, CryptoJS.enc.Utf8.parse(key), {
            iv: CryptoJS.enc.Utf8.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
      });
      userInfo.password = cipherText.toString();
    }
    if(userInfo?.oldPassword){
      const key = 'ThisIsASecureKey1234567890123456'; // Same key as used in the backend
      const iv = 'ThisIsAnIV123456'; // Same IV as used in the backend
      let pass = userInfo?.oldPassword;
      const cipherText = CryptoJS.AES.encrypt(pass, CryptoJS.enc.Utf8.parse(key), {
            iv: CryptoJS.enc.Utf8.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
      });
      userInfo.oldPassword = cipherText.toString();
    }
    if(userInfo?.email){
      userInfo.email = userInfo.email + "@arcutis.com"; 
    }
    let payload = 
    {
      "userData" : {
        password:userInfo?.password,
        oldPassword:userInfo?.oldPassword,
        passwordEnabled:true
      },
      "type" : "edit",
      "userId": currentUserId
    }
    ServiceUtils.postRequest('userOps', payload).then(
      (response) => {
        if (response.status === 200 && response?.data.status) {
          // setProfileList(response.data.data)
          navigate("/admin/settings")
          setOpenReset(false);
          getUserTableDetails();
          form.resetFields();
        } 
        else if(response.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching data')
        console.log(error)
      }
    );
  }

  const SubmitButton = ({ form, children }) => {
    const [submittable, setSubmittable] = React.useState(false);
  
    // Watch all values
    const values = Form.useWatch([], form);
    // console.log(values);
    React.useEffect(() => {
      form
        .validateFields({
          validateOnly: true,
        })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false));
    }, [form, values]);
    return (
      <Button type="primary" htmlType="submit" disabled={!submittable} onClick={submittable && submitPassword}>
        {children}
      </Button>
    );
  };

  // const EnablePasswordModal = (id) => {
  //   const [open, setOpen] = useState(true);
  //   // const showModal = () => {
  //   //   setOpen(true);
  //   // };
  //   const hideModal = () => {
  //     setOpen(false);
  //   };
    
  //   return (
  //     <div>
  //       <Modal
  //         title="Modal"
  //         open={open}
  //         onOk={hideModal}
  //         onCancel={hideModal}
  //       >
  //         <p>Bla bla ...</p>
  //         <p>Bla bla ...</p>
  //         <p>Bla bla ...</p>
  //       </Modal>
  //     </div>
  //   );
  // };

  const confirmProceedPassword = () => {
    setOpenReset(true)
    setEnablePasswordModal(false);
  }
  const disablePassword = () => {
    setEnablePasswordModal(false)
    handleDropdownChange(false,'passwordEnabled', currentUserId, true)
  }

  const revertDataOnCancel = () => {
      setOpenReset(false);
      setState({
        filteredInfo: null,
        sortedInfo: null,
        data : {},
        filtered: false,
        searchTex: ""
      })
      if(userDetails.admin_access){
        getUserTableDetails();
      }
  }


  return (
    <div className="user-management" >
      <Row style={{width:'100%', margin:'5px'}}>
          <Col xs={{ flex: '100%' }}
              sm={{ flex: '100%' }}
              md={{ flex: '100%' }}
              lg={{ flex: '50%' }}
              xl={{ flex: '50%' }}
          >

            <div 
              className={classNames("", {
                "search-kol-mobile":
                    windowSize$ === "lg" ||
                    windowSize$ === "xl" ||
                    windowSize$ === "xxl",
                "search-kol-mobile":windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md",
            })}
            >
              <Input.Search value={state?.searchTex} style={{ width: '100%' }} size="large" placeholder="Search" onChange={ onSearch} />
            </div>
          </Col>
          <Col xs={{ flex: '100%' }}
              sm={{ flex: '100%' }}
              md={{ flex: '100%' }}
              lg={{ flex: '50%' }}
              xl={{ flex: '50%' }}
          >
            {userDetails.admin_access &&
              <div 
                className={classNames("", {
                  "search-kol-mobile":
                      windowSize$ === "lg" ||
                      windowSize$ === "xl" ||
                      windowSize$ === "xxl",
                  "search-kol-mobile":windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md",
              })}
              >
                <div style={{display:'flex',justifyContent:"flex-end", margin:'3px'}}>
                  <Button
                    type="primary"
                    onClick={() => navigate(`/admin/user/add`)}
                    style={{ marginBottom: 16 }}
                  >
                    Add User
                  </Button>
                </div>
              </div>
            }
          </Col>
      </Row>
      
      <div className={classNames("", {
        "survey-container":
          windowSize$ === "lg" ||
          windowSize$ === "xl" ||
          windowSize$ === "xxl",
        "survey-container-small":
          windowSize$ === "xs" ||
          windowSize$ === "sm" ||
          windowSize$ === "md",
      })}>
        <Table 
          columns={userManagementTableData?.headerContent} 
          // dataSource={userManagementTableData?.bodyContent}
          dataSource={Object.keys(state?.data).length && state.data} 
          pagination={false}
          bordered
          scroll={{
            y: "calc(100vh - 330px)",
          }}
        />
      </div>
      <Modal
        title="Reset password"
        centered
        // style={{
        //   top: 20,
        // }}
        open={openRest}
        onOk={() => setOpenReset(false)}
        onCancel={() => setOpenReset(false)}
        footer={[
          <Button key="submit"  onClick={()=>{ revertDataOnCancel() }}>
            Cancel
          </Button>,
          <SubmitButton form={form}>Save</SubmitButton>,
        ]}
      >
        <div style={{paddingTop:'10px'}}>
          <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
              // initialValues={}
              style={{
                maxWidth: 600,
              }}
              scrollToFirstError
            > 
                <Form.Item
                  name="oldPassword"
                  label="Old Password"
                  tooltip={{
                    title: 'Not required for resetting password for the first time',
                    icon: <InfoCircleOutlined />,
                  }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please input your old password!',
                  //   },
                  // ]}
                  // hasFeedback
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The new password that you entered do not match!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>


                {/* <Form.Item {...tailFormItemLayout}>
                  <Button type="primary" htmlType="submit">
                    Register
                  </Button>
                </Form.Item>  */}
            </Form>
        </div>
      </Modal>
      <Modal
          title="Modal"
          open={EnablePasswordModal}
          onOk={()=>{confirmProceedPassword()}}
          onCancel={()=>{ disablePassword()}}
        >
          <p>Please make sure to add password for enabling the password login..!!</p>
        </Modal>
      
    </div>
  );
};

export default UserManagement;
