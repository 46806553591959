import React from 'react';
import classNames from 'classnames';
import './SVGIcon.scss';
import { ReactComponent as IconChevronUp } from '../../../Assets/icons/chevron-up.svg';
import { ReactComponent as IconChevronDown } from '../../../Assets/icons/chevron-down.svg';
import { ReactComponent as IconChevronRight } from '../../../Assets/icons/chevron-right.svg';
import { ReactComponent as IconChevronLeft } from '../../../Assets/icons/chevron-left.svg';
import { ReactComponent as IconBell } from '../../../Assets/icons/bell.svg';
import { ReactComponent as IconSearch } from '../../../Assets/icons/search.svg';
import { ReactComponent as IconSettings } from '../../../Assets/icons/settings.svg';
import { ReactComponent as IconInfo } from '../../../Assets/icons/information.svg';
import { ReactComponent as IconBuilding } from '../../../Assets/icons/building.svg';
import { ReactComponent as IconUserArrowRight } from '../../../Assets/icons/user-arrow-right.svg';
import { ReactComponent as IconStackH } from '../../../Assets/icons/stack-h.svg';
import { ReactComponent as IconCalendarTimer } from '../../../Assets/icons/calendar-timer.svg';
import { ReactComponent as IconCalendar } from '../../../Assets/icons/calendar.svg';
import { ReactComponent as IconCmd } from '../../../Assets/icons/cmd.svg';
import { ReactComponent as IconUserRound } from '../../../Assets/icons/user-round.svg';
import { ReactComponent as IconFile } from '../../../Assets/icons/file-text.svg';
import { ReactComponent as IconPersonDashed } from '../../../Assets/icons/person-dashed-box.svg';
import { ReactComponent as IconPersonLinesRight } from '../../../Assets/icons/person-lines-right.svg';
import { ReactComponent as IconBriefcase } from '../../../Assets/icons/briefcase.svg';
import { ReactComponent as IconCartPlus } from '../../../Assets/icons/cart-plus.svg';
import { ReactComponent as IconCashCoin } from '../../../Assets/icons/cash-coin.svg';
import { ReactComponent as IconPlusSqDotted } from '../../../Assets/icons/plus-square-dotted.svg';
import { ReactComponent as IconListCheck } from '../../../Assets/icons/list-check.svg';
import { ReactComponent as IconColumnsGap } from '../../../Assets/icons/columns-gap.svg';
import { ReactComponent as IconCheckCircle } from '../../../Assets/icons/check-circle.svg';
import { ReactComponent as IconCheckCircleApply } from '../../../Assets/icons/check-circle-apply.svg';
import { ReactComponent as IconListDotBorder } from '../../../Assets/icons/list-dot-border.svg';
import { ReactComponent as IconListDot } from '../../../Assets/icons/list-dot.svg';
import { ReactComponent as IconCheckBig } from '../../../Assets/icons/check-big.svg';
import { ReactComponent as IconPencil } from '../../../Assets/icons/pencil.svg';
import { ReactComponent as IconTrash } from '../../../Assets/icons/trash.svg';
import { ReactComponent as IconCloseCircle } from '../../../Assets/icons/close-circle.svg';
import { ReactComponent as IconCalendarWeek } from '../../../Assets/icons/calendar-week.svg';
import { ReactComponent as IconCheck } from '../../../Assets/icons/check.svg';
import { ReactComponent as IconPencilFill } from '../../../Assets/icons/pencil-fill.svg';
import { ReactComponent as IconSendMail } from '../../../Assets/icons/send-mail.svg';
import { ReactComponent as IconTravel } from '../../../Assets/icons/travel.svg';
import { ReactComponent as IconPhone } from '../../../Assets/icons/phone.svg';
import { ReactComponent as IconPeople } from '../../../Assets/icons/people.svg';
import { ReactComponent as IconSortAsc } from '../../../Assets/icons/sort-asc.svg';
import { ReactComponent as IconSortDesc } from '../../../Assets/icons/sort-desc.svg';
import { ReactComponent as IconSortDefault } from '../../../Assets/icons/sort-default.svg';
import { ReactComponent as IconEyeView } from '../../../Assets/icons/eye-view.svg';
import { ReactComponent as IconGeoLocation } from '../../../Assets/icons/geo-location.svg';
import { ReactComponent as IconPersonDir } from '../../../Assets/icons/person-dir.svg';
import { ReactComponent as IconDiagram } from '../../../Assets/icons/diagram.svg';
import { ReactComponent as IconCloudMoon } from '../../../Assets/icons/cloud-moon.svg';
import { ReactComponent as IconBirthday } from '../../../Assets/icons/birthday-cake.svg';
import { ReactComponent as IconAwardCap } from '../../../Assets/icons/award-cap.svg';
import { ReactComponent as IconClockTimer } from '../../../Assets/icons/clock-history.svg';
import { ReactComponent as IconList } from '../../../Assets/icons/list.svg';
import { ReactComponent as IconLogout } from '../../../Assets/icons/logout.svg';
import { ReactComponent as IconGrid } from '../../../Assets/icons/grid_2x2.svg';
import { ReactComponent as IconDownload } from '../../../Assets/icons/download.svg';
import { ReactComponent as IconCircleCheck } from '../../../Assets/icons/check-circle.svg';
import { ReactComponent as IconCopyClipboard } from '../../../Assets/icons/copy-icon.svg';
import { ReactComponent as IconReload } from '../../../Assets/icons/reload-icon.svg';
import { ReactComponent as IconTable } from '../../../Assets/icons/table-icon.svg';
import { ReactComponent as IconPeopleFill } from '../../../Assets/icons/people-fill.svg';
import { ReactComponent as IconCakeColor } from '../../../Assets/icons/birthday-cake-color.svg';
import { ReactComponent as IconCalender3 } from '../../../Assets/icons/calender3.svg';
import { ReactComponent as IconBorderStyle } from '../../../Assets/icons/border-style.svg';
import { ReactComponent as IconDelete } from '../../../Assets/icons/delete-close.svg';
import { ReactComponent as IconApproval } from '../../../Assets/icons/approval.svg';
import { ReactComponent as Icon3DotVertical } from '../../../Assets/icons/vertical-3-dot.svg';
import { ReactComponent as IconExpiring } from '../../../Assets/icons/expiring-project.svg';
import { ReactComponent as IconChart } from '../../../Assets/icons/chart.svg';
import { ReactComponent as IconArrowup } from '../../../Assets/icons/open-in-new-tap.svg';
import { ReactComponent as RightArrow } from '../../../Assets/icons/right_arrow.svg';
import { ReactComponent as IconChartArrowup } from '../../../Assets/icons/arrow-up.svg';
import { ReactComponent as IconFullView } from '../../../Assets/icons/full-view.svg';
import { ReactComponent as IconFullViewExit } from '../../../Assets/icons/full-view-exit.svg';
import { ReactComponent as WorkFromHome } from '../../../Assets/icons/WorkFromHome.svg';
import { ReactComponent as Map } from '../../../Assets/icons/map.svg';
import { ReactComponent as ExpiredVector } from '../../../Assets/icons/expired-vector.svg';
import { ReactComponent as MapFillMarker } from '../../../Assets/icons/map-fill.svg';
import { ReactComponent as UploadIcon } from '../../../Assets/icons/upload.svg';
import { ReactComponent as UserTrans } from '../../../Assets/icons/User-trans.svg';
import { ReactComponent as TableView } from '../../../Assets/icons/table-view.svg';
import { ReactComponent as Home } from '../../../Assets/icons/home.svg';
import { ReactComponent as Completed } from '../../../Assets/icons/completed.svg';
import { ReactComponent as UserMultiple } from '../../../Assets/icons/user-multiple.svg';
import { ReactComponent as UserStar } from '../../../Assets/icons/user-star.svg';
import { ReactComponent as Report } from '../../../Assets/icons/report.svg';
import { ReactComponent as Task } from '../../../Assets/icons/task.svg';
import { ReactComponent as  NoteBook } from '../../../Assets/icons/notebook.svg';
import { ReactComponent as  Catalog } from '../../../Assets/icons/catalog.svg';
import { ReactComponent as  User } from '../../../Assets/icons/user.svg';
import { MenuUnfoldOutlined, StarOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { ReactComponent as  UserToDo } from '../../../Assets/icons/user-todo.svg';
import { ReactComponent as CheckboxList } from '../../../Assets/icons/checkbox.svg';
import { ReactComponent as RadioList } from '../../../Assets/icons/choices.svg';
import { ReactComponent as TextArea } from '../../../Assets/icons/textarea.svg';
import { ReactComponent as DropdownList } from '../../../Assets/icons/dropdown-round.svg';
import { ReactComponent as TextField } from '../../../Assets/icons/input-field.svg';
import { ReactComponent as UserTransparent } from '../../../Assets/icons/user-transparent.svg';
import { ReactComponent as NotebookReference } from '../../../Assets/icons/notebook-reference.svg';
import { ReactComponent as NotebookSelected } from '../../../Assets/icons/notebook-selected.svg';
import { ReactComponent as Numbers } from '../../../Assets/icons/numbers.svg';
import {ReactComponent as Meeting } from '../../../Assets/icons/meeting.svg';
import {ReactComponent as Sync } from '../../../Assets/icons/sync.svg';

export const iconMapping = {
  'user-star' : <UserStar/>,
  'user-multiple' : <UserMultiple/>,
  'user-todo' : <UserToDo/>,
  'user-delete' : <UserDeleteOutlined/>,
  'important' : <StarOutlined/>,
  'catalog' : <Catalog/>,
  'notebook' : <NoteBook/>,
  'task' : <Task/>,
  'report' : <Report/>,
  'user-trans' : <UserTrans/>,
  'to-auditee' : <UserMultiple/>,
  'completed' : <Completed/>,
  'todo-list' : <Home/>,
  'table-view' : <TableView/>,
  'chevron-up': <IconChevronUp />,
  'chevron-down': <IconChevronDown />,
  'chevron-right': <IconChevronRight />,
  'chevron-left': <IconChevronLeft />,
  'bell': <IconBell />,
  'search': <IconSearch />,
  'settings': <IconSettings />,
  'info': <IconInfo />,
  'building': <IconBuilding />,
  'user-arrow-right': <IconUserArrowRight />,
  'stack-h': <IconStackH />,
  'calendar': <IconCalendar />,
  'calendar-timer': <IconCalendarTimer />,
  'clock-history': <IconClockTimer />,
  'cmd': <IconCmd />,
  'user-round': <IconUserRound />,
  'file-text': <IconFile />,
  'person-dashed-box': <IconPersonDashed />,
  'person-lines-right': <IconPersonLinesRight />,
  'briefcase': <IconBriefcase />,
  'cart-plus': <IconCartPlus />,
  'cash-coin': <IconCashCoin />,
  'plus-square-dotted': <IconPlusSqDotted />,
  'columns-gap': <IconColumnsGap />,
  'grid': <IconGrid />,
  'check-circle': <IconCheckCircle />,
  'check-circle-apply': <IconCheckCircleApply />,
  'list': <IconList />,
  'list-dot': <IconListDot />,
  'list-dot-border': <IconListDotBorder />,
  'list-check': <IconListCheck />,
  'check-big': <IconCheckBig />,
  'pencil': <IconPencil />,
  'trash': <IconTrash />,
  'close-circle': <IconCloseCircle />,
  'calendar-week': <IconCalendarWeek />,
  'check': <IconCheck />,
  'pencil-fill': <IconPencilFill />,
  'send-mail': <IconSendMail />,
  'travel': <IconTravel />,
  'phone': <IconPhone />,
  'people': <IconPeople />,
  'sort-asc': <IconSortAsc />,
  'sort-desc': <IconSortDesc />,
  'sort-default': <IconSortDefault />,
  'eye-view': <IconEyeView />,
  'geo-location': <IconGeoLocation />,
  'person-dir': <IconPersonDir />,
  'diagram': <IconDiagram />,
  'cloud-moon': <IconCloudMoon />,
  'birthday': <IconBirthday />,
  'award-cap': <IconAwardCap />,
  'logout': <IconLogout />,
  'download': <IconDownload />,
  'check-circle-deact': <IconCircleCheck/>,
  'copy-clipboard': <IconCopyClipboard/>, 
  'reload-circle':<IconReload/>,
  'table-list':<IconTable/>,
  'people-fill':<IconPeopleFill/>,
  'birthday-cake-color':<IconCakeColor/>,
  'calender3':<IconCalender3/>,
  'border-style':<IconBorderStyle/>,
  'delete':<IconDelete/>,
  'approval':<IconApproval/>,
  'vertical-3-dot':<Icon3DotVertical/>,
  'expiring-project' : <IconExpiring/>,
  'right-arrow': <RightArrow />,
  'chart' : <IconChart/>,
  'open-in-new-tap':<IconArrowup/>,
  'chart-arrow-up':<IconChartArrowup/>,
  'full-view':<IconFullView/>,
  'full-view-exit':<IconFullViewExit/>,
  'work-from-home':<WorkFromHome/>,
  'map':<Map/>,
  'expired-vector':<ExpiredVector/>,
  'map-fill-marker':<MapFillMarker/>,
  'upload':<UploadIcon/>,
  'user':<User/>,  
  'menu-folded' : <MenuUnfoldOutlined/>,
  'checkbox-list':<CheckboxList/>,
  'dropdown-list':<DropdownList/>,
  'radio-list':<RadioList/>,
  'text-area':<TextArea />,
  'text-field':<TextField />,
  'user-transparent':<UserTransparent/>,
  'notebook-reference': <NotebookReference />,
  'notebook-selected': <NotebookSelected />,
  'numbers':<Numbers />,
  'meeting':<Meeting />,
  'sync': <Sync/>,
};

export default function SVGIcon({ icon, className, ...props }) {
  return (
    <div className={classNames('icon', 'icon-' + icon, className,)} {...props} >
      {iconMapping[icon] ? iconMapping[icon] : '' }
    </div>
  );
}
