import React, { useEffect, useRef, useState } from "react";
import "./ArcutisEngagements.scss";
import {
  Row,
  Col,
  Card,
  Button,
  Space,
  Avatar,
  Divider,
  Layout,
  Checkbox,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { ServiceUtils } from "../../../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../../../Shared/Utils/ToasterUtils";
import { getDecryptSession } from "../../../../../Shared/Utils/CommonUtils";
import {
  MailOutlined,
  PhoneOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";

export default function ArcutisEngagements({ ...props }) {
  const [arcutisEngagementDetails, setArcutisEngagementDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const { id } = useParams();
  const { attendee_id } = useParams();
  const navigate = useNavigate();
  const dynamicCardColors = [
    "#EBF3FA",
    "#FFFCEB",
    "#F0F2F3",
    "#FAE9F2",
    "#FFF0F0",
    "#EEEFED",
  ];

  useEffect(() => {
    const session = localStorage.getItem("arcutis-user");
    const userDetails = getDecryptSession(session);
    setUserDetails(userDetails);
    fetchArcutisEngagementDetails();
  }, []);


  const fetchArcutisEngagementDetails = () => {
    let payload = {
      profileId: id,
      sectionId:Number(props?.menuId)
    }
    ServiceUtils.postRequest('kolProfile', payload).then(
      (response) => {
        if (response?.status === 200) {
          setArcutisEngagementDetails({...response.data?.data});
        } 
        else if(response?.status === 401 ){
          navigate('/logout');
        }
        else {
          alertService.error(response?.data?.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching user role.')
        console.log(error)
      }
    );
  };

  const mapTheSectionValue = (sectionValues) => {
    console.log(sectionValues, "sectionValues");
    let htmlContent = <></>;
    if (sectionValues?.type === "checkbox") {
      htmlContent = (
        <>
          <Checkbox checked={sectionValues?.value} className="sectionValueName">
            {sectionValues?.label}
          </Checkbox>
        </>
      );
    } else if (sectionValues?.type === "label") {
      htmlContent = (
        <>
          <p className="labelSectionValueName">{sectionValues?.label}</p>
          <p className="sectionValueName">{sectionValues?.value}</p>
        </>
      );
    } else if(sectionValues?.type === 'checkboxAndLabel'){
      htmlContent = ( 
        <>
        <p className="labelSectionValueName">{sectionValues?.label}</p>
        {sectionValues?.internalSections?.map((data)=>{
          return(
            data?.type === 'checkbox' ? 
            <p><Checkbox checked={data?.value} className="sectionValueName">
            {data?.label}
            </Checkbox></p>
            :
            <p className="sectionValueName">{data?.label}</p>
          )
        })}
        </>
      )
    }
    return htmlContent;
  };
  return (
    <>
      <div
        className="arcutis-engagement-root"
        data-test="arcutis-engagement-container"
      >
        <Layout>
          <Content>
            <div className="list-container">
              <div className="list-container">
                <p className="headerLabel">Arcutis Engagements {arcutisEngagementDetails?.user_name && `- ${arcutisEngagementDetails?.user_name}`}</p>
                <Card
                  className="profileCard"
                  style={{
                    padding: 0,
                    maxheight: "80vh",
                    backgroundColor: "#F7F7F7",
                    borderRadius: "20px",
                  }}
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ flex: 1, padding: "16px" }}>
                      <Card style={{ padding: "20px", borderRadius: "20px" }}>
                        <div>
                          {arcutisEngagementDetails?.roles?.sections?.map(
                            (section) => {
                              return (
                                <>
                                  <p className="sectionLabelName">
                                    {section?.sectionName}
                                  </p>
                                  <Row gutter={[16, 16]}>
                                    {section?.sectionsValues?.map(
                                      (sectionValue, index) => (
                                        <Col
                                          key={index}
                                          xs={24}
                                          sm={12}
                                          md={8}
                                          lg={6}
                                        >
                                          {mapTheSectionValue(sectionValue)}
                                        </Col>
                                      )
                                    )}
                                    <Divider style={{ margin: 0 }} />
                                  </Row>
                                </>
                              );
                            }
                          )}
                        </div>
                      </Card>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    </>
  );
}
