// import React, { useState, useCallback, useEffect } from "react";
// import "./UserLogin.scss";
// import { Row, Col } from "antd";
// import { sessionService } from "../../Shared/Utils/SessionService";
// import { VARIABLES } from "../../Environments/constants";
// import LoginBg from "../../Assets/images/login_bg.webp";
// import { Image } from "antd";
// import Config from "../../Assets/json/UserLogin.json";
// import AntBuilder from "../../Shared/Components/Ant-FormBuilder/Ant-FormBuilder";
// import { ServiceUtils } from "../../Shared/Utils/ServiceUtils";
// import { alertService } from "../../Shared/Utils/ToasterUtils";
// import { updateFormConfig } from "../../Shared/Utils/CommonUtils";
// import { useSharedState } from '../../Shared/Services/WindowSizeService';
// import { MicrosoftLoginButton } from "react-social-login-buttons";
// import classNames from 'classnames';
// import msalInstance from "../../Environments/msalConfig";
// import { encrypt, setEncryptUserSession, decryptSession } from "../../Shared/Utils/CommonUtils";
// import CryptoJS from "crypto-js";
// import bcrypt from 'bcryptjs';


// export default function UserLogin(props) {
//   const windowSize$ = useSharedState();
//   const [credential, setCredential] = useState({
//     user: "",
//     password: "",
//   });
//   const [timer, setTimer] = useState(5);
//   const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);
//   const [formConfig, setFormConfig] = useState(Config);



//   useEffect(() => {
//     timer > 0 && setTimeout(timeOutCallback, 1000);
//   }, [timer, timeOutCallback]);




//   const handleLogin = async () => {
//     // if (action?.type === "onChange") {
//     //   let tempConfig = [...formConfig];
//     //   tempConfig = updateFormConfig(tempConfig, "user", { validateStatus: "", help: "" })
//     //   tempConfig = updateFormConfig(tempConfig, "password", { validateStatus: "", help: "" })
//     //   setFormConfig(tempConfig);
//     //   console.log(action.payload.data);
//     //   setCredential({ ...credential, ...action.payload.data });
//     // }
//     // if (action?.payload?.data?.key === "sign-in") {
//     // let session = setEncryptUserSession();
//     // sessionService.setSession(session);
//     // let temp = {};
//     // let t = CryptoJS.AES.encrypt(payLoad?.password, "arcutisArcutists").toString();
//     // console.log(credential?.password);
//     // let encPass = encrypt(credential?.password);
//     // temp.user=credential?.user;
//     // temp.password = encPass;
//     // props.updateAuth(session);
//     // setCredential(temp);
//     // console.log(temp);

//     try {
//       const response = await msalInstance.loginPopup();
//       console.log('Login response:', response);
//       if (response) {
//         const userDetails = {
//         accessToken : response?.accessToken
//         };
//         console.log('user', userDetails)
//         loginAction(userDetails);
//       }
//       // User has been logged in successfully
//     } catch (error) {
//       console.log('Login error:', error);
//     }
//   };

//   const loginAction = (userData) => {
//     ServiceUtils.postRequest("login", userData).then(
//       (response) => {
//         if (response.status === 200 && response?.data.status === true) {
//           let session = setEncryptUserSession(response?.data.data);
//           sessionService.setSession(JSON.parse(JSON.stringify((session))));
//           props.updateAuth(session);
//         } else {
//           alertService.error(
//             response?.data?.message || "Error while trying to login"
//           );
//         }
//       },
//       (error) => {
//         alertService.error("Error while trying to login");
//         console.log("userLogin Error", error);
//       }
//     );
// }

// return (
//   <div className="login-root">
//     <div className={classNames('', {
//       "mob-tab-header": (windowSize$ === "lg" || windowSize$ === "xl" || windowSize$ === "xxl"),
//       "mob-tab-header-small": (windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md"),
//     })}>
//       <p
//         className={classNames('', {
//           "header-cnt": (windowSize$ === "lg" || windowSize$ === "xl" || windowSize$ === "xxl"),
//           "header-cnt-small": (windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md"),
//         })}>
//         KOL Tracking System
//       </p>
//     </div>
//     <Row className="main-container">
//       <Col
//         className={classNames('', {
//           "left-content": (windowSize$ === "lg" || windowSize$ === "xl" || windowSize$ === "xxl"),
//           "left-content-small": (windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md"),
//         })}>
//         <Image
//           rootClassName="bg-image"
//           height="102%"
//           src={LoginBg}
//           preview={false}
//           alt="Login"
//           className="image-tab"
//         />
//         <div className="top-left">KOL Tracking System</div>
//       </Col>

//       <Col className={classNames('', {
//         "right-content": (windowSize$ === "lg" || windowSize$ === "xl" || windowSize$ === "xxl"),
//         "right-content-small": (windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md"),
//       })}>
//         <Row className="login-content">
//           <div className="formCenter">
//             <h1 className={classNames('', {
//               "header": (windowSize$ === "lg" || windowSize$ === "xl" || windowSize$ === "xxl"),
//               "header-small": (windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md"),
//             })}>KOL Tracking System</h1>
//             {/* <div className="subHeader">Sign In to your account</div>
//               <AntBuilder
//                 config={formConfig}
//                 onAction={handleLogin}
//                 data={credential}
//               /> */}
//             <MicrosoftLoginButton className="microsoft-login-button" style={{ width: '235px' }} onClick={handleLogin} />
//             {/* <Button className="right-btn" onClick={showForgotPassword} type="link">Forgot Password?</Button> */}
//           </div>
          
//         </Row>
//       </Col>
//     </Row>
//   </div>
// );
// }

import React, { useState, useCallback, useEffect } from "react";
import "./UserLogin.scss";
import { Row, Col, Card, Button, Divider, Input, Carousel, Tooltip } from "antd";
import { sessionService } from "../../Shared/Utils/SessionService";
import { VARIABLES } from "../../Environments/constants";
import LoginBg from "../../Assets/images/login_bg.webp";
import { Image } from "antd";
import Config from "../../Assets/json/UserLogin.json";
import AntBuilder from "../../Shared/Components/Ant-FormBuilder/Ant-FormBuilder";
import { ServiceUtils } from "../../Shared/Utils/ServiceUtils";
import { alertService } from "../../Shared/Utils/ToasterUtils";
import { updateFormConfig } from "../../Shared/Utils/CommonUtils";
import { useSharedState } from '../../Shared/Services/WindowSizeService';
import classNames from 'classnames';
import { encrypt, setEncryptUserSession, decryptSession } from "../../Shared/Utils/CommonUtils";
import CryptoJS from "crypto-js";
import bcrypt from 'bcryptjs'; 
import { MicrosoftLoginButton } from "react-social-login-buttons";
import {
  BASE_PATH,
  DEPLOYED_ENVIRONMENT,
  ENVIRONMENTS,
} from "../../Environments/environments";
import { PublicClientApplication } from '@azure/msal-browser';
import {useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone ,ExperimentOutlined} from '@ant-design/icons';
import loginBackgroundImage from '../../Assets/images/Background Image.png';
import carousalImage1 from '../../Assets/images/Carousel 1.svg';
import carousalImage2 from '../../Assets/images/Carousel 2.svg';
import carousalImage3 from '../../Assets/images/Carousel 3.svg';
import useWindowSize from "../../Shared/Utils/windowResizeUtils";
import { Version } from "../../Environments/config";


export default function UserLogin(props) {
  const windowSize$ = useSharedState();
  const navigate = useNavigate();
  const [credential, setCredential] = useState({
    user: "",
    password: "",
  });
  const [timer, setTimer] = useState(5);
  const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);
  const [formConfig, setFormConfig] = useState(Config);

  const [checkUserNameLogin, setUserNameLogin] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width <= 768;


  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const userNameLogin = () => {
    setUserNameLogin(!checkUserNameLogin);
  }



  const checkDomain = async () => {
    let payload = {
      "env":DEPLOYED_ENVIRONMENT
      // "env":"localhost"
    }
    await ServiceUtils.postRequest('domainCheck', payload).then(
      (response) => {
        // console.log(response);
        if (response.status === 200 && response?.data) {
          // console.log(response?.data)
          MSLogin(response?.data)
        } 
        else if(response.status === 401 ){
          // navigate('/logout');
          alertService.error(response.data.message)
        }
        else {
          alertService.error(response.data.message)
        }
      },
      (error) => {
        alertService.error('Error while fetching data')
        console.log(error)
      }
    );
  }
  
  const MSLogin = async (data) => {
    const reqData = JSON.parse(JSON.stringify(data))
    const msalConfig = {
      auth: {
        clientId: reqData?.client_id,
        authority: 'https://login.microsoftonline.com/' + reqData?.tenant_id, //Tenant ID
        redirectUri: reqData?.redirect_url // Replace with your registered redirect URI
      } 
    };

    // console.log(msalConfig);
      
    // const msalConfig = {
    //   auth: {
    //     clientId: 'c308ce30-e51b-45b1-a37f-805de5f3d1a9',
    //     authority: 'https://login.microsoftonline.com/b548525a-b69a-4783-aace-d41d1719c993', //Tenant ID
    //     redirectUri: 'https://kolts-test.arcutis-cloudapps.com/' // Replace with your registered redirect URI
    //   }
      
    // };
    const msalInstance = new PublicClientApplication(msalConfig);
    try {
      const response = await msalInstance.loginPopup();
      console.log('Login response:', response);
      if (response) {
        const userDetails = {
          "imageUrl": '',
          "token_id" : response?.accessToken,
          "type": "MSSSO"
        };
        // console.log('user', userDetails)
        loginAction(userDetails);
      }
      // User has been logged in successfully
    } catch (error) {
      console.log('Login error:', error);
    }
  };

    const loginAction = (userData) => {
      ServiceUtils.postRequest("ssoLogin", userData).then(
        (response) => {
          if (response.status === 200 && response?.data.status === true) {
            let session = setEncryptUserSession(response?.data?.data);
            sessionService.setSession(JSON.parse(JSON.stringify((session))));
            props.updateAuth(session);
          } 
          else if(response.status === 401 ){
            alertService.error(response.data.message)
            // navigate('/logout');
          }
          else {
            alertService.error(
              response?.data?.message || "Error while trying to login"
            );
          }
        },
        (error) => {
          alertService.error("Error while trying to login");
          console.log("userLogin Error", error);
        }
      );
    }


  const handleLogin = (action) => {
    // console.log(action);
    if (action?.type === "onChange") {
      let tempConfig = [...formConfig];
      tempConfig = updateFormConfig(tempConfig, "user", { validateStatus: "", help: "" })
      tempConfig = updateFormConfig(tempConfig, "password", { validateStatus: "", help: "" })
      setFormConfig(tempConfig);
      // console.log(action.payload.data);
      setCredential({ ...credential, [action?.key]:action.data });
    }
    if (action?.type === "sign-in") {
      // console.log(credential?.user, credential?.password);
      if(credential?.user && credential?.password){
        const key = 'ThisIsASecureKey1234567890123456'; // Same key as used in the backend
        const iv = 'ThisIsAnIV123456'; // Same IV as used in the backend
        let pass = credential?.password;
        const cipherText = CryptoJS.AES.encrypt(pass, CryptoJS.enc.Utf8.parse(key), {
          iv: CryptoJS.enc.Utf8.parse(iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        // console.log(cipherText.toString());
  
        // const bytess = CryptoJS.AES.decrypt(cipherText.toString(), CryptoJS.enc.Utf8.parse(key), {
        //   iv: CryptoJS.enc.Utf8.parse(iv),
        //   mode: CryptoJS.mode.CBC,
        //   padding: CryptoJS.pad.Pkcs7,
        // });
        // Convert decrypted password to UTF-8 string
        // console.log(bytess.toString(CryptoJS.enc.Utf8));
  
          const payloadData = JSON.parse(JSON.stringify(credential));
          payloadData.password = cipherText.toString();
          ServiceUtils.postRequest("login", payloadData).then(
            (response) => {
              if (response.status === 200 && response?.data.status === true) {
                let session = setEncryptUserSession(response?.data.data);
                sessionService.setSession(JSON.parse(JSON.stringify((session))));
                props.updateAuth(session);
              } 
              else if(response.status === 401 ){
                // navigate('/logout');
                alertService.error(
                  response?.data?.message
                );
              }
              else {
                alertService.error(
                  response?.data?.message || "Error while trying to login"
                );
              }
            },
            (error) => {
              alertService.error("Error while trying to login");
              console.log("userLogin Error", error);
            }
          );
      }
      else{
        if( !credential?.user && !credential?.password){
          alertService.error("Enter Email ID and Password");
        }
        else if(!credential?.user){
          alertService.error("Enter Email ID");
        }
        else if(!credential?.password){
          alertService.error("Enter password");
        }
      }
    }
  };
  
  const envTitle =()=>{
    let icon;
    let title='';
    if (window.location?.origin.includes('test')){
      icon = <ExperimentOutlined />
      title= 'Test Env'
    }
    return {icon,title}
  } 


  return (
    <div className="login-root">
      <div >
        <div className="login-image image-field">

        </div>
        {/* <Image preview={false} className="login-image image-field" src={loginBackgroundImage}/> */}

      </div>
      {/* <div className={classNames('', {
            "mob-tab-header": (windowSize$ === "lg" || windowSize$ === "xl" || windowSize$ === "xxl"),
            "mob-tab-header-small": (windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md"),
          })}>
            <p 
              className={classNames('', {
              "header-cnt": (windowSize$ === "lg" || windowSize$ === "xl" || windowSize$ === "xxl"),
              "header-cnt-small": (windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md"),
            })}>
                KOL Tracking System
              </p>
          </div> */}
      <div class="card-container">
      <Row className="main-container" style={{width:isMobile?'90%':"55%"}}>
        {/* <Col   
          className={classNames('', {
            "left-content": (windowSize$ === "lg" || windowSize$ === "xl" || windowSize$ === "xxl"),
            "left-content-small": (windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md"),
          })}>
          <Image
            rootClassName="bg-image"
            height="102%"
            src={LoginBg}
            preview={false}
            alt="Login"
            className="image-tab"
          />
          <div className="top-left">KOL Tracking System</div>
        </Col> */}

        {/* <Col  className={classNames('', {
            "right-content": (windowSize$ === "lg" || windowSize$ === "xl" || windowSize$ === "xxl"),
            "right-content-small": (windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md"),
          })}>
          <Row className="login-content">
            <div className="formCenter">
              <h1 className={classNames('', {
            "header": (windowSize$ === "lg" || windowSize$ === "xl" || windowSize$ === "xxl"),
            "header-small": (windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md"),
          })}>KOL Tracking System</h1>
              <div className="subHeader">Sign In to your account</div>
              {checkUserNameLogin &&
                <>
                <div style={{marginBottom:'15px'}}>
                  <AntBuilder
                    config={formConfig}
                    onAction={handleLogin}
                    data={credential}
                  /> 
                </div>
                <div className="social">
                  <span>
                    or
                  </span>
                </div>
                </>
               } */}
              {/* <Button className="right-btn" onClick={showForgotPassword} type="link">Forgot Password?</Button>  */}
              {/* <MicrosoftLoginButton className="microsoft-login-button" style={{ width: '235px' }} onClick={checkDomain} />
            <div style={{paddingTop:'10px'}} className="login-username">
              <a onClick={userNameLogin}>{checkUserNameLogin ? <>Login with out</> : <>Login with</> } Username</a>
            </div>
            </div> */}
            {/* { <a href="https://teams.microsoft.com/l/call/0/0?users=firstname.name@company.com"> (Name of the person) </a> } */}

          {/* </Row>
        </Col> */}
        <Card styles={{body:{padding:0}}} 
        style={{width:"100%",borderRadius:"15px"}}>
       <div>
     
       <Row className="login-content">
      {!isMobile && <Col span={12} >
        <div className="leftCardSection">
        <h3 className="kolHeader">KOL Tracking System 
          <span>  
          <Tooltip title={envTitle()?.title}>
              {envTitle()?.icon}
            </Tooltip>
            </span>
            </h3>
          <Carousel dotPosition="bottom" autoplay autoplaySpeed={3000} adaptiveHeight={true}>
        <div>
          {/* <h3 className="login-carousal">Image</h3> */}
          <Image preview={false}  className="login-carousal" src={carousalImage1}/>
          <h5 className="schedule">Schedule and conduct meetings effortlessly</h5>
        </div>
        <div>
        <Image preview={false} className="login-carousal" src={carousalImage2}/>
        <h5 className="schedule">Schedule and conduct meetings effortlessly</h5>
        </div>
        <div>
        <Image preview={false} className="login-carousal" src={carousalImage3}/>
        <h5 className="schedule">Schedule and conduct meetings effortlessly</h5>
        </div>
      </Carousel>
      </div>
      </Col>}
      <Col span={isMobile?24:12}>
            <div className="formCenter">
              <h4 className={classNames('', {
            "header": (windowSize$ === "lg" || windowSize$ === "xl" || windowSize$ === "xxl"),
            "header-small": (windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md"),
          })}>Welcome!</h4>
              <div className="subHeader">Please enter your Login Credentials</div>
              {checkUserNameLogin &&
                <>
                {/* <div style={{marginBottom:'15px'}}>
                  <AntBuilder
                    config={formConfig}
                    onAction={handleLogin}
                    data={credential}
                  /> 
                </div>
                <div className="social">
                  <span>
                    or
                  </span>
                </div> */}
                <Col style={{padding:0}}  className={classNames('', {
            "content": (windowSize$ === "lg" || windowSize$ === "xl" || windowSize$ === "xxl"),
            "content-small": (windowSize$ === "xs" || windowSize$ === "sm" || windowSize$ === "md"),
          })}>
            <Row>
            <label className="field-label" >
              Email
            </label>
            <Input style={{marginBottom:"20px"}} onChange={(event)=>{handleLogin({ type: "onChange",key:"user", data:event?.target?.value })}} placeholder="Email" size="large" />
            <label className="field-label" >
              Password
            </label>
            <Input.Password
            placeholder="Password"
            style={{marginBottom:"20px"}} 
            onChange={(event)=>{handleLogin({ type: "onChange",key:"password", data:event?.target?.value })}}
            size="large"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
          </Row>
          </Col>

            <Button className="login-button" onClick={()=>{handleLogin({ type: "sign-in"})}}>Login</Button>
            <Divider>Or</Divider>
            </>
               } 
              {/* <Button className="right-btn" onClick={showForgotPassword} type="link">Forgot Password?</Button>  */}
              <MicrosoftLoginButton className={classNames('',{'microsoft-login-button':!checkUserNameLogin},{'microsoft-login-button-white':checkUserNameLogin})} onClick={checkDomain} />
              {checkUserNameLogin &&  <p className="version" style={{marginBottom:0}}>Version - <b>{Version}</b></p>}
            {/* <div style={{paddingTop:'10px'}} className="login-username">
              <a onClick={userNameLogin}>{checkUserNameLogin ? <>Login with out</> : <>Login with</> } Username</a>
            </div> */}
            {!checkUserNameLogin && <> 
            <Divider>Or</Divider>
            <Button className="user-login-button" onClick={userNameLogin}>{checkUserNameLogin ? <>Login with out </> : <>Login with </> } &nbsp;Username</Button>
            <p className="version">Version - <b>{Version}</b></p>
            </>}
            </div>
            </Col>
       </Row>
       </div>
      </Card>
      </Row>
      </div>
    </div>
  );
}



