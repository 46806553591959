import React, { useState, useCallback, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  Typography,
  Card,
  Avatar,
  Space,
  Empty,
  Image,
  Table,
  Tooltip,
  Dropdown,
  message,
  Divider,
  theme,
  Badge,
  Layout,
  Collapse,
  Checkbox
} from "antd";
import classNames from 'classnames';
import {SearchOutlined,CopyOutlined,CloseOutlined,FilterOutlined,CaretRightOutlined} from '@ant-design/icons';
import "../../UserLogin/UserLogin.scss";
import { useSharedState } from "../../../Shared/Services/WindowSizeService";
import { useNavigate } from "react-router-dom";
import { ServiceUtils } from "../../../Shared/Utils/ServiceUtils";
import { alertService } from "../../../Shared/Utils/ToasterUtils";
import "./ArcutisAttendee.scss";
import AppIcon from '../../../Assets/images/app_icon.png'
import Sider from "antd/es/layout/Sider";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { debounce } from "../../../Shared/Utils/CommonUtils";

export default function ArcutisAttendee(props) {
  const windowSize$ = useSharedState();
  const navigate = useNavigate();
  const { Title, Text } = Typography;
  const Search = Input.Search;
  const userDetails = props?.userDetails;

  const [data, setData] = useState();
  const [tableFilterData, setTableFilter] = useState({
    page: 1,
    size: 16,
    // sortOrder: "asc",
    // sortBy: "name",
  });
  const [statusData, setStatusData] = useState({
    data: null,
    loadedPages: [],
    loading: false,
    disable: false,
    end:false
  });

  const [searchData, setSearchData] = useState();
  const [searchTableFilterData, setSearchTableFilter] = useState({
    page: 1,
    size: 16,
    // sortOrder: "asc",
    // sortBy: "name",
  });
  const [searchStatusData, setSearchStatusData] = useState({
    data: null,
    loadedPages: [],
    loading: false,
    disable: false,
    end:false
  });

  const [searchText, setSearchText] = useState("");
  const [searchFlag, setSearchFlag] = useState(false);
  const [showFilter,setShowFilter] = useState(false);
  const [filterSelectedData, setFilterSelectedData] = useState({department:[],designation:[]});
  const [filterConfig,setFilterConfig]=useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(()=>{
    fetchData();
  },[filterSelectedData])

  const setBg = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return "#" + randomColor;
  };

  const handleSearchInputChange = (event) => {
    setSearchText(event.target.value);
    let tempPayload ={
    page:tableFilterData?.page,
    size:tableFilterData?.size,
    filters_json:{...filterSelectedData, searchText:event.target.value}
    }
    funcDebounce(tempPayload);
  };
  const funcDebounce = useCallback(debounce(
    (payload) => fetchData(payload), 1000), []);

  const addNew = () => {
    navigate('profile/addAttendee')
  }

  const onSelectCard = (data, startDate) => {
    localStorage.setItem(
      "meetingListFilter",
      JSON.stringify({
        meetingFilter: [],
        attendeeFilter: [],
        calenderDate: startDate ? new Date(startDate) : new Date(),
        view: "day",
        conferenceId: null,
      })
    );
    navigate(data);
  };
  

  const handleScroll = (e,seaflag) => {
    if (!statusData?.disable || !searchStatusData?.disable) {
      //   console.log(e.target?.scrollTop);
      const scrollPercent =
        (e.target?.scrollTop /
          (e.target?.scrollHeight - e.target?.offsetHeight)) *
        100;
      // console.log(statusData?.loadedPages);
      if(seaflag){
        // console.log("hello");
        const page = searchTableFilterData?.page + 1;
        // console.log(
        //   scrollPercent,
        //   searchStatusData?.loading,
        //   searchStatusData?.loadedPages?.includes(page),
        //   searchStatusData?.end
        // );
        if (
          scrollPercent > 70 &&
          !searchStatusData?.loading &&
          !searchStatusData?.loadedPages?.includes(page) &&
          !searchStatusData?.end
        ) {
          const tempFilter = {
            ...searchTableFilterData,
            page: page,
            sort_by: searchTableFilterData?.sortBy,
            sort_order: searchTableFilterData?.sortOrder,
          };
          fetchData({
            page: page,
            sort_by: searchTableFilterData?.sortBy,
            sort_order: searchTableFilterData?.sortOrder,
          },"null",seaflag);
          setSearchTableFilter(tempFilter);
        }
      }
      else{
        // console.log("hello");
        const page = tableFilterData?.page + 1;
        // console.log(statusData?.end);
        if (
          scrollPercent > 70 &&
          !statusData?.loading &&
          !statusData?.loadedPages?.includes(page) &&
          !statusData?.end
        ) {
          const tempFilter = {
            ...tableFilterData,
            page: page,
            sort_by: tableFilterData?.sortBy,
            sort_order: tableFilterData?.sortOrder,
          };
          fetchData({
            page: page,
            sort_by: tableFilterData?.sortBy,
            sort_order: tableFilterData?.sortOrder,
          },"null",seaflag);
          setTableFilter(tempFilter);
        }
      }
    }
  };

  useEffect(()=>{
    if(searchFlag && searchText){
      fetchData({
        page: searchTableFilterData?.page,
        sort_by: searchTableFilterData?.sortBy,
        sort_order: searchTableFilterData?.sortOrder,
      },"",true);
    }
  },[searchFlag])

  const submitSearch = () => {
    if(searchText?.length){
      let temp = {
        data: null,
        loadedPages: [],
        loading: false,
        disable: false,
        end:false
      }
      setSearchStatusData(JSON.parse(JSON.stringify(temp)))
      if(searchFlag){
        fetchData({
          page: searchTableFilterData?.page,
          sort_by: searchTableFilterData?.sortBy,
          sort_order: searchTableFilterData?.sortOrder,
        },"",true);
      }
      else{
        setSearchFlag(true);
      }
    }
    else{
      setSearchFlag(false);
    }
  }

  const handleKeypress = (e) => {
    // console.log(e);
    // It triggers by pressing the Enter key
    if (e.key === "Enter") {
        submitSearch();
    }
  };

  const fetchData = (input, type, where,payloadData) => {
    if(where){
      setSearchStatusData({ ...searchStatusData, loading: true })
    }
    else{
      setStatusData({ ...statusData, loading: true });
    }
    let payload={};
    if(payloadData){
      payload={...payloadData}
    }else{
     payload = {
      page: where ? searchTableFilterData?.page : tableFilterData?.page,
      size: where ? searchTableFilterData?.size : tableFilterData?.size,
      filters_json:{...filterSelectedData, searchText:searchText},
      //   emp_id: userDetails.emp_id,
      ...input,
    };
  }

    // const showLoader = payload?.page === 1;
    ServiceUtils.postRequest("listOfAttendees", payload).then(
      (_response) => {
        if (_response.status === 200) {
          if (_response?.data) {
            // const response =  _response?.data?.data ? _response?.data?.data : _response?.data?.data?.body;
            const response = _response?.data?.data?.attendeeData;
            const endFlag = _response?.data?.end;
            if(endFlag){
              alertService.warning("fetched all the profiles");
            }
            if(where){
              let result = payload?.page === 1 ? [] : searchStatusData?.data || [];
              if(payload?.page === 1){
                result=[...[], ...response]
              }
              else{
                result = [...result, ...response];
              }
              if (type && type === "sort") {
                setSearchData({
                  data: result,
                  loadedPages: [payload?.page],
                  loading: false,
                  disable: response.length === 0,
                });
              } else {
                setSearchStatusData({
                  data: result,
                  loadedPages: [...searchStatusData?.loadedPages, payload?.page],
                  loading: false,
                  disable: response.length === 0,
                  end:endFlag
                });
              }
              setSearchData({
                data: result,
                filterData: result,
                header: _response?.data?.data?.header,
                total_count: _response?.data?.meta_data?.total_records,
              });
              // console.log(result);
            }
            else{
              let result = payload?.page === 1 ? [] : statusData?.data || [];
              result = [...result, ...response];
              if (type && type === "sort") {
                setStatusData({
                  data: result,
                  loadedPages: [payload?.page],
                  loading: false,
                  disable: response.length === 0,
                });
              } else {
                setStatusData({
                  data: result,
                  loadedPages: [...statusData?.loadedPages, payload?.page],
                  loading: false,
                  disable: response.length === 0,
                  end:endFlag
                });
              }
              setData({
                data: result,
                filterData: result,
                header: _response?.data?.data?.header,
                total_count: _response?.data?.meta_data?.total_records,
              });
            }
            if(_response.data?.data?.filters?.filterData){
              let filterData= JSON.parse(JSON.stringify(_response.data?.data?.filters?.filterData))
              let filterKeys = JSON.parse(JSON.stringify(_response.data?.data?.filters?.filterKeys))
              getSubmittedFilterStructure(filterData, filterKeys)
            }
            // setTableData({ data: result, filterData: result, header:_response?.data?.data?.header, total_count: _response?.data?.meta_data?.total_records});
          } else {
            alertService.success("Error while getting dashboard data");
          }
        }
      },
      (error) => {
        console.log("Error: dashboard data", error);
      }
    );
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success('Email copied to clipboard!');
    }).catch((err) => {
      message.error('Failed to copy email');
      console.error('Could not copy text: ', err);
    });
  };


  const getSubmittedFilterStructure = (data, reqObj) => {
    let filterDataConfig = data;
    let reqObject = reqObj;
    const onChangeFilters = (data, key) => {
      reqObject={...reqObject,...filterSelectedData, [key]: data}
      setFilterSelectedData({...reqObject})
    }

    filterDataConfig?.map((item)=>{
      if(item.type==="checkBox") {
        const getData = () => {

            return(
              [
                <Checkbox.Group onChange={(e)=>{onChangeFilters(e,item?.key)}}>
                    <Space direction="column">
                      <Row gutter={16}>
                        {item?.options?.map((option) => (
                          <Col span={24}>
                            <Checkbox  value={option?.value} style={{marginBottom:'3px'}}>{option?.label}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                  </Space>
                </Checkbox.Group>
              ]
            )
        }
        item["children"] =getData();
      }
    })
    setFilterConfig(filterDataConfig);
  }
  
  return (
    <div className="login-test">
     
      <div
        className="login-root"
        style={{ marginTop:'10px', backgroundColor:'transparent'  }}
      >
         <Layout>
          {showFilter ? 
              <Sider
                width={250}
                style={{
                  overflow: 'visible'
                }}
              > 
                  <Layout style={{height: '-webkit-fill-available'}}>
                    <Header className="filter-header" style={{"padding": "0px !important"}}>
                      <div className="demo-logo-vertical" />
                      <Row gutter={16} style={{padding:'0px', margin:'0px'}}>
                        <Col span={12}>
                          <b style={{padding:"0 10px"}}><FilterOutlined /> &nbsp; Filters</b>
                        </Col>
                        <Col span={12} style={{alignSelf: 'center'}}>
                          <span style={{opacity:'0.5',display: 'flex', justifyContent: 'end'}} onClick={()=>setShowFilter(!showFilter)} className="pointer">
                            <CloseOutlined />
                          </span>
                        </Col>
                      </Row>
                      <Divider/>
                    </Header>
                  <Layout>
                    <Content className="filter-content" style={{backgroundColor:"white"}}>
                        <Collapse
                              defaultActiveKey={['1']}
                              // onChange={onChangeCollapse}
                              expandIconPosition='end'
                              items={filterConfig}
                              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        />   
                    </Content>
                  </Layout>
                </Layout>
                  
              </Sider>
              :
              <div>
                <Tooltip title="Filters">
                  <Button style={{margin:'15px'}} onClick={()=>{setShowFilter(!showFilter)}}>
                      
                          <FilterOutlined />
                  </Button>
                </Tooltip>
                <Sider width={'5%'}/>
              </div>
            }
          <Content>
        <div style={{padding:showFilter?'0 2%':'0 2% 0 0'}} >
          {/* <Row> */}
          <div className="kol-search">
        <Row style={{ width: "100%" }}>
          <Col
            xs={{ flex: "100%" }}
            sm={{ flex: "100%" }}
            md={{ flex: "100%" }}
            lg={{ flex: "50%" }}
            xl={{ flex: "50%" }}
          >
            <div
              className={classNames("", {
                "search-kol-mobile":
                  windowSize$ === "lg" ||
                  windowSize$ === "xl" ||
                  windowSize$ === "xxl",
                "search-kol-mobile":
                  windowSize$ === "xs" ||
                  windowSize$ === "sm" ||
                  windowSize$ === "md",
              })}
            >
              <Space.Compact style={{width:'100%'}}>
                <Input
                  style={{ width: "100%" }}
                  size="large"
                  placeholder="Search by Name,Attendee ID,Department..."
                  value={searchText}
                  onChange={handleSearchInputChange}
                  // onSearch={console.log('hello')}
                  // defaultValue="Combine input and button"
                  onKeyDown={handleKeypress}
                />
                <Button onClick={submitSearch} style={{height: 'auto'}}><SearchOutlined/></Button>
              </Space.Compact>
            </div>
          </Col>
          <Col
            xs={{ flex: "100%" }}
            sm={{ flex: "100%" }}
            md={{ flex: "100%" }}
            lg={{ flex: "50%" }}
            xl={{ flex: "50%" }}
          >
            <div style={{ display: "flex", justifyContent: "end" }}>
              {/* <Title
                className="total-count"
                style={{ marginRight: "18px" }}
                level={4}
              >
                {" "}
                Total{" "}
                {searchFlag ? searchData?.filterData?.length : data?.filterData?.length}
                  {" "}
                {searchData?.filterData?.length===1 ? <>Attendee </>: <>Attendees</> }
              </Title> */}
              {userDetails?.role_access?.arcutisAttendee?.edit &&
                <Button
                style={{
                  background: "#000000",
                  marginTop: "5px",
                  marginLeft: "5px",
                  padding:"0 20px"
                }}
                type="primary"
                onClick={() => addNew()}
              >
                + Add
              </Button>}
            </div>
          </Col>
        </Row>
      </div>
          <Row
            className="list-container"
            onScroll={(e) => handleScroll(e,searchFlag)}
            style={{ padding: "0px" }}

          >
            {searchFlag ? 
            <>
              {searchStatusData?.data?.length > 0 ? (
                searchData?.filterData?.map((el, i) => (
                  <Col
                    className="tile"
                    style={{ padding: "0px 1% 0px 0px" }}
                    xs={{ flex: "100%" }}
                    sm={{ flex: "50%" }}
                    md={{ flex: "50%" }}
                    lg={{ flex: "25%" }}
                    xl={{ flex: "25%" }}
                  >
                        <div className="attendee-body">
                    <Badge.Ribbon text={<> <img className="pointer" style={{width:'90%'}} src={AppIcon} /></>} style={{backgroundColor:"#fdea68"}}>
                    <Card className="pointer" style={{ width: '100%', height: 'fit-content', borderRadius: '4px' }} onClick={() => onSelectCard('attendeeDetails' + '/' + el.attendee_id)}>
                    <div style={{textAlign:"center",margin:"10px 0"}}>
                          <Avatar
                          style={{
                              backgroundColor: setBg(),
                              verticalAlign: 'middle',
                          }}
                          size={100}
                          >
                          <span className='avatar-Text'>
                              {el.attendee_name.split(" ")[0][0]}{el.attendee_name.split(" ")[1][0]}
                          </span>
                          </Avatar>
                      </div>
                      <div style={{width:"100%",textAlign:"center"}}>
                          <Title level={4} style={{ color: '#3D3D3D' ,textAlign:"center",marginBottom:"5px"}}>{el.attendee_name}</Title>
                          <Text className='text-ellipsis-one-line ' style={{fontSize:"14px",color:"black",fontWeight:"500"}}>{el.designation}</Text>
                      </div>
                      <div className="userData" >
                      <div className="details">
                          <Text style={{ color: '#B9B9B9' }}>Attendee ID</Text>
                          <Text className='text-center fontWeight-500'>{el.attendee_id}</Text>
                      </div>
                      <div className="details">
                          <Text style={{ color: '#B9B9B9' }}>Email</Text>
                          <div style={{display:"flex",alignItems:"center"}}>
                          <Text className="email">{el.attendee_email || '-'}</Text>
                          <span onClick={(e)=>{
                              e.stopPropagation();
                              copyToClipboard(el.attendee_email);
                              }}><CopyOutlined /></span>
                          </div>
                      </div>
                      <div  className="details">
                          <Text style={{ color: '#B9B9B9' }}>Mobile</Text>
                          <Text className='text-center fontWeight-500'>{el.mobile || '-'}</Text>
                      </div>
                      <div className="details">
                          <Text style={{ color: '#B9B9B9' }}>Department</Text>
                          <Text className='text-center fontWeight-500'>{el.department || '-'}</Text>
                      </div>
                      </div>

                      </Card>
                      </Badge.Ribbon>
                    </div>
                  </Col>
                ))
              ) : (
                <>{searchStatusData?.loading ? <>
                    <Col className="center-all" xs={{ flex: "100%" }}
                    sm={{ flex: "100%" }}
                    md={{ flex: "100%" }}
                    lg={{ flex: "100%" }}
                    xl={{ flex: "100%" }}>
                      <Empty
                      description={
                        <Typography.Text>
                          Loading...
                        </Typography.Text>
                        }
                        /> 
                  </Col>
                </>:
                  <Col className="center-all" xs={{ flex: "100%" }}
                  sm={{ flex: "100%" }}
                  md={{ flex: "100%" }}
                  lg={{ flex: "100%" }}
                  xl={{ flex: "100%" }}>
                    <Empty
                    description={
                      <Typography.Text>
                        No profiles found
                      </Typography.Text>
                      }
                    /> 
                  </Col>
                }
                </>
              )}
            </> 
            :
            <>
              {statusData?.data?.length > 0 ? (
                data?.filterData?.map((el, i) => (
                  <Col
                    className="tile"
                    style={{ padding: "0px 1% 0px 0px" }}
                    xs={{ flex: "100%" }}
                    sm={{ flex: "50%" }}
                    md={{ flex: "50%" }}
                    lg={{ flex: "20%" }}
                    xl={{ flex: "20%" }}
                  >
                    <div className="attendee-body">
                    <Badge.Ribbon text={<> <img className="pointer" style={{width:'90%'}} src={AppIcon} /></>} style={{backgroundColor:"#fdea68"}}>
                    <Card className="pointer" style={{ width: '100%', height: 'fit-content', borderRadius: '4px' }} onClick={() => onSelectCard('attendeeDetails' + '/' + el.attendee_id)}>
                    <div style={{textAlign:"center",margin:"10px 0"}}>
                          <Avatar
                          style={{
                              backgroundColor: setBg(),
                              verticalAlign: 'middle',
                          }}
                          size={100}
                          >
                          <span className='avatar-Text'>
                              {el.attendee_name.split(" ")[0][0]}{el.attendee_name.split(" ")[1][0]}
                          </span>
                          </Avatar>
                      </div>
                      <div style={{width:"100%",textAlign:"center"}}>
                          <Title level={4} style={{ color: '#3D3D3D' ,textAlign:"center",marginBottom:"5px"}}>{el.attendee_name}</Title>
                          <Text className='text-ellipsis-one-line ' style={{fontSize:"14px",color:"black",fontWeight:"500"}}>{el.designation}</Text>
                      </div>
                      <div className="userData" >
                      <div className="details">
                          <Text style={{ color: '#B9B9B9' }}>Attendee ID</Text>
                          <Text className='text-center fontWeight-500'>{el.attendee_id}</Text>
                      </div>
                      <div className="details">
                          <Text style={{ color: '#B9B9B9' }}>Email</Text>
                          <div style={{display:"flex",alignItems:"center"}}>
                          <Text className="email">{el.attendee_email|| '-'}</Text>
                          <span onClick={(e)=>{
                              e.stopPropagation();
                              copyToClipboard(el.attendee_email);
                              }}><CopyOutlined /></span>
                          </div>
                      </div>
                      <div  className="details">
                          <Text style={{ color: '#B9B9B9' }}>Mobile</Text>
                          <Text className='text-center fontWeight-500'>{el.mobile|| '-'}</Text>
                      </div>
                      <div className="details">
                          <Text style={{ color: '#B9B9B9' }}>Department</Text>
                          <Text className='text-center fontWeight-500'>{el.department|| '-'}</Text>
                      </div>
                      </div>

                      </Card>
                      </Badge.Ribbon>
                    </div>
                  </Col>
                ))
              ) : (
                <>{statusData?.loading ? 
                  <Col className="center-all" xs={{ flex: "100%" }}
                  sm={{ flex: "100%" }}
                  md={{ flex: "100%" }}
                  lg={{ flex: "100%" }}
                  xl={{ flex: "100%" }}>
                    <Empty
                    description={
                      <Typography.Text>
                        Loading...
                      </Typography.Text>
                      }
                    /> 
                  </Col>
                :
                <> <Col className="center-all" xs={{ flex: "100%" }}
                sm={{ flex: "100%" }}
                md={{ flex: "100%" }}
                lg={{ flex: "100%" }}
                xl={{ flex: "100%" }}>
                  <Empty description={
                    <Typography.Text>
                      Currenly no profiles available, please create a KOL profile
                    </Typography.Text>
                    }/> 
                </Col></>
                }</>
              )}
            </>
            }
          </Row>
          {/* </Row> */}
        </div>
          </Content>
          </Layout>
      </div>
    </div>
  );
}
