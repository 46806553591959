import React from 'react';
import { Subject } from 'rxjs';
import { message as mess } from 'antd';

const subject = new Subject();

const parseMessage = message => {
  if (typeof message === 'string') return { message: message };
  else if (typeof message === 'object') return message
}
export const alertService = {
  success: message => subject.next({ ...parseMessage(message), type: 'success' }),
  error: message => subject.next({ ...parseMessage(message), type: 'error' }),
  warning: message => subject.next({ ...parseMessage(message), type: 'warning' }),
  info: message => subject.next({ ...parseMessage(message), type: 'info' }),
  message: message => subject.next({ ...message }),
};
// const clearMessages = () => subject.next(); // clear all messages
const getMessage = () => subject.asObservable();


export default class ToasterAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: [],
      alertTimeout: ''
    };
  }

  componentDidMount() {
    mess.config({ maxCount: 2, duration: 2 });
    this.subscription = getMessage().subscribe(message => {
      if (message) {
        mess[message.type](message.message);
        // message.id = uuidV4();
        // let tempTimeout = setTimeout(() => {
        //   this.removeMessage(message)
        // }, message.delay || TOASTER_TIMEOUT);
        // this.setState({ messages: [...this.state.messages, message], alertTimeout: tempTimeout });
      }
    });
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  removeMessage(delMsg) {
    if (delMsg) {
      const messages = this.state.messages.filter(el => el.id !== delMsg?.id);
      this.setState({ messages: messages });
    }
  }

  toastHover(hover, el) {
    if (hover) {
      clearTimeout(this.state.alertTimeout);
    } else {
      setTimeout(() => {
        this.removeMessage(el);
      }, 1000);
    }
  }

  render() {
    const { messages } = this.state;
    return (
      messages.map((el) => {
        return ((el.type === 'success' && mess.success(el.message))
          || (el.type === 'warning' && mess.warning(el.message))
          || (el.type === 'error' && mess.error(el.message))
          || (mess.info(el.message)))
      })
    );
  }
}