import { API_ENDPOINTS, API_ENVIRONMENT } from "../../Environments/config";
import { MESSAGES } from "../../Environments/constants";
import {
  BASE_PATH,
  DEPLOYED_ENVIRONMENT,
  ENVIRONMENTS,
} from "../../Environments/environments";
import axios from "axios";
// import { sessionService } from './SessionService';
import { loaderService } from "./Loader/Loader";
import { alertService } from "./ToasterUtils";
import { BehaviorSubject } from "rxjs";

const loaderSubject = new BehaviorSubject();

axios.interceptors.request.use((request) => {
  const loader = loaderSubject.getValue();
  if (loader) loaderService.showLoader();
  return request;
});
axios.interceptors.response.use(
  (response) => {
    const loader = loaderSubject.getValue();
    if (loader) loaderService.hideLoader();
    return response || {};
  },
  ({ response }) => {
    // Error
    const loader = loaderSubject.getValue();
    if (loader) loaderService.hideLoader();
    // if (response?.status === 500) {
    //   alertService.error({ title: 'Internal Server Error', message: response?.data?.Error || '' });
    //   console.log('Error 500', response?.config?.url?.split('klapps')?.[1], response?.data);
    // } else
    if (response?.status === 401 || !response) {
      // alertService.error(MESSAGES.invalidToken);
      const path = window.location.href.split("#");
      // sessionService.setSession({ssoAutoLoad: true, redirectUrl: path[1]}, 'sso-redirect');
      // window.location.href = path[0] + "#/logout";
    } else {
      console.log(
        "ERROR " + response?.status + ":",
        response?.config?.url?.split("klapps")?.[1],
        response?.data
      );
    }
    return response || {};
  }
);

const get = (urlKey, showLoader = true) => {
  let url;
  const environment = API_ENVIRONMENT[urlKey] || DEPLOYED_ENVIRONMENT;
  if (environment === "local") {
    url = BASE_PATH + `/Assets/json/${urlKey}.json`;
  } else {
    url = ENVIRONMENTS[environment].baseurl + API_ENDPOINTS[urlKey];
  }
  loaderSubject.next(showLoader);

  // const userSession = sessionService.getSession();
  const config = {
    headers: {
      // token: userSession?.[VARIABLES.accessToken],
      "Access-Control-Allow-Origin": "*",
      "Accept" : "application/json",
      "Content-type":"application/json",
      "ngrok-skip-browser-warning": true
      
    },
  };
  return axios.get(url, config);
};

const post = (urlKey, params, showLoader = true, cancelToken = undefined) => {
  // const navigate = useNavigate();
  const environment = API_ENVIRONMENT[urlKey] || DEPLOYED_ENVIRONMENT;
  if (environment === "local") return get(urlKey);
  let url = ENVIRONMENTS[environment].baseurl + API_ENDPOINTS[urlKey];
  loaderSubject.next(showLoader);

  // const userSession = sessionService.getSession();
  const config = {
    headers: {
      // token: userSession?.[VARIABLES.accessToken],
      "Access-Control-Allow-Origin": "*",
    },
  };

  if (cancelToken !== undefined) {
    config.cancelToken = cancelToken;
  }

  return axios.post(url, params, config);
};

const getBlob = (urlKey, showLoader = true) => {
  let url;
  const environment = API_ENVIRONMENT[urlKey] || DEPLOYED_ENVIRONMENT;
  if (environment === "local") {
    url = BASE_PATH + `/Assets/json/${urlKey}.json`;
  } else {
    url = ENVIRONMENTS[environment].baseurl + API_ENDPOINTS[urlKey];
  }
  loaderSubject.next(showLoader);

  // const userSession = sessionService.getSession();
  const config = {
    headers: {
      // token: userSession?.[VARIABLES.accessToken],
      "Access-Control-Allow-Origin": "*",
      "Accept" : "application/json",
      "Content-type":"application/json",
      "ngrok-skip-browser-warning": true
      
    },
    responseType: "blob"
  };
  return axios.get(url, config);
};
// const postBlob = (urlKey, params, showLoader = true, cancelToken = undefined) => {
//   // const navigate = useNavigate();
//   const environment = API_ENVIRONMENT[urlKey] || DEPLOYED_ENVIRONMENT;
//   if (environment === "local") return get(urlKey);
//   let url = ENVIRONMENTS[environment].baseurl + API_ENDPOINTS[urlKey];
//   loaderSubject.next(showLoader);

//   // const userSession = sessionService.getSession();
//   const config = {
//     headers: {
//       // token: userSession?.[VARIABLES.accessToken],
//       "Access-Control-Allow-Origin": "*",
//     },
//     responseType: "blob",
//   };

//   if (cancelToken !== undefined) {
//     config.cancelToken = cancelToken;
//   }

//   return axios.post(url, params, config);
// };

const ServiceUtils = {
  getRequest: get,
  postRequest: post,
  getBlobRequest: getBlob,
};
export { ServiceUtils };
