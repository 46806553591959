export const MESSAGES = {
    server_error: 'The system is currently down due to maintenance. Please try again later.',
    download_url_fail: 'The download action is experiencing some technical difficulties. Please try again in some time',
    filter_no_data: 'No records present for the selected filters',
    table_no_data: 'No records found',
    invalidToken: 'Invalid token. Please login to continue',
    error_500: 'Internal Server Error',
  };
  
  export const VARIABLES = {
    userSession: 'arcutis-user',
    accessToken: 'tokenId',
    userName: 'name',
    fullName: 'full_name',
    firstName: 'first_name',
    lastName: 'last_name',
    photo: 'photo',
    empId: 'emp_id',
    email: 'email',
    userRole: 'userRole',
    menuToggle: 'menu-toggle',
  };
  export const CONSTANTS = {
    roleAdmin: 'admin',
    roleAuditor: 'auditor',
    roleAudited:'auditee',
    roleSuperAdmin: "superadmin",
    timeZones: ["IST", "PST", "EST"],
    organization: ['Knowledge Lens', 'GLens', 'Unify Twin'],
    allocation: [100, 90, 80, 70, 60, 50, 40, 30, 20, 10],
    tablePageSize: [10, 25, 50, 100, 200, 500],
  };
  export const TOASTER_TIMEOUT = 3000;