import React, { createContext, useContext, useState } from "react";
import { Outlet, Navigate, useNavigate, useLocation } from "react-router-dom";
import { VARIABLES } from "../../Environments/constants";
import UserLogin from "../../Pages/UserLogin/UserLogin";
import { sessionService } from "./SessionService";
import menuConfig from '../../Assets/json/menu-top-nav.json';
import { checkAccess, getDecryptSession } from "./CommonUtils";
const authContext = createContext();

export function ProvideAuth({ children }) {
  const authProvider = useProvideAuth();
  return (
    <authContext.Provider value={authProvider}>
      {children}
    </authContext.Provider>
  );
}

export function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signIn = (data, callback) => {
    setUser(data);
    if (typeof callback === 'function') callback();
  };

  const signOut = callback => {
    setUser(null);
    sessionService.clearSession(VARIABLES.userSession);
    if (typeof callback === 'function') callback();
  };

  return {
    user,
    signIn,
    signOut
  };
}

function useAuth() {
  return useContext(authContext);
}

const userAccess = (route,auth) => {
  const role = sessionService.getSession()?.[VARIABLES.userRole]; // ['admin', 'lead']
  const routes = route.split('/');
  if (routes[1] === 'admin' && auth?.user?.admin_access) {
    return auth?.user?.admin_access;
  } else if (routes[1] === 'home') { // dashboard
    if (routes[2] === undefined) return true; // /dashboard
    const menu = menuConfig.filter(el => el?.key === routes[2])?.[0];
    if (menu === undefined) return false;
    const access = menu?.userAccess;
    return access === undefined || checkAccess(access);
  } else return false;
}

/** A wrapper for <Route> that redirects to the login screen if you're not yet authenticated. */
export function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <>{auth?.user
      ?userAccess(rest?.path,auth)
        ? <Outlet />
        : <Navigate
          to={{
            pathname: "/home",
            state: { from: rest?.path }
          }}
        />
      : <Navigate
        to={{
          pathname: "/login",
          state: { from: rest?.path }
        }}
      />
    }
    </>
  )
}


export function SecureLogin(props) {
  const navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  const [sessionDetails, setSessionDetails] = useState();
  const [from, setFrom] = useState(location.state?.from || { pathname: "/" });

  useState(() => {
    /** Check user-details in session */
    const userDetailss = localStorage.getItem('arcutis-user');
    if(userDetailss){
      const userDetails = getDecryptSession(userDetailss);
      if (userDetails && userDetails?.user_id) {
        setSessionDetails(userDetails)
        auth.user = userDetails;
        if (typeof props.onLogin === 'function') props.onLogin(userDetails);
      }
    }
  })

  let login = data => {
    // console.log(data);
    // console.log(decryptSession(data))
    if (data?.redirect) {
      setFrom({ pathname: data?.redirect })
      navigate({ pathname: data?.redirect }, { replace: true });
    } else {
      navigate(from, { replace: true });
    }
    auth.signIn(data);
  };

  return (
    auth.user?.user_id ? <Navigate to={from} /> : <UserLogin updateAuth={login} />
  );
}

export function SecureLogout() {
  localStorage.clear();
  const navigate = useNavigate();
  let auth = useAuth();
  auth.signOut(() => {
    setTimeout(() => {
      navigate('/login', { replace: true })
      window.location.reload();
    }, 500);
  });

  return (
    <></>
  )
}
