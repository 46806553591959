import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './../src/App/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/reset.css';

// Dynamically update the title based on the environment
const titleBase = 'KOL Tracking System';
const envTitle = window.location?.origin.includes('local')
  ? `- Local Environment`
  : window.location?.origin.includes('test')
  ? ' - Test Environment'
  : '';
document.title = `${titleBase}${envTitle}`;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: registration => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    if (window.confirm(`A new version of the app is available. Please click 'OK' to reload with the latest version.`)) {
      document.location.reload();
    }
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
